import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import TableContainer from "../../components/Common/TableContainer";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    UncontrolledTooltip,
    Input,
    Form,
    Button,
    FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";

import {
    getOrders, getSettledOrder, updateOrder
} from "../../store/order/action";
import { isEmpty } from "lodash";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


const SettledPayment = props => {

    //meta title
    document.title = "Mythreya - Table Payments";

    const dispatch = useDispatch();
    const [order, setOrder] = useState();
    const [selectedDate, setSelectedDate] = useState(new Date());
    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            tip: (order && order.tip) || 0,
            settled_amount: (order && order.settled_amount) || 0,
            paid_amount: (order && order.paid_amount) || 0,
        },
        validationSchema: Yup.object({
            tip: Yup.string(),
            settled_amount: Yup.string(),
            paid_amount: Yup.string().required("Please Enter Name"),
        }),
        onSubmit: values => {
            if ((values.paid_amount - values.tip) == order.total_amount && values.paid_amount != "") {
                const updateStoreCategory = {
                    id: order.id,
                    tip: values.tip,
                    settled_amount: values.settled_amount,
                    paid_amount: values.paid_amount,
                    payment_mode: paymentMode
                };
                dispatch(updateOrder(updateStoreCategory));
                setIsEdit(false);
                validation.resetForm();

                toggle();
            } else {
                toast.success("Invalid Paid Amount or Settled Amount", { autoClose: 2000 });
            }

        },
    });

    const selectStoreCategoryState = (state) => state.orders;
    const StoreCategoryProperties = createSelector(
        selectStoreCategoryState,
        (Order) => ({
            orders: Order.settledOrders,
            loading: Order.loading
        })
    );

    const {
        orders, loading
    } = useSelector(StoreCategoryProperties);



    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setLoading] = useState(loading)
    const [title, setTitle] = useState("All")
    const [orderList, setStoreCategoryList] = useState([]);
    const [paymentMode, setPaymentMode] = useState("CASH");
    const columns = useMemo(
        () => [

            {
                Header: "Order Date",
                accessor: "order_date",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{moment(cellProps.row.original.order_date).format('MMM D, YYYY hh:mm A')}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Order ID",
                accessor: "order_id",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.order_id}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Outlet",
                accessor: "tag",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.tag + ((cellProps.row.original.tableNo != "") ? (" / Table -" + cellProps.row.original.tableNo) : "")}</Link>
                            </h5>
                        </>
                    )
                },
            },

            {
                Header: "Settled Amount",
                accessor: "settled_amount",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.settled_amount?.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Action",
                Cell: cellProps => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-success btn btn-info text-white"
                                onClick={() => {
                                    const item = cellProps.row.original;
                                    handleStoreCategoryClick(item);
                                }}
                            >
                                View More
                            </Link>

                        </div>
                    );
                },
            },
        ],
        []
    );

    useEffect(() => {
        if (orders && !orders.length) {
            dispatch(getSettledOrder({ counter: "All", settlement_status: "SUCCESS" }));
            setIsEdit(false);
        }
    }, [dispatch]);

    useEffect(() => {
        setOrder(orders);
        setIsEdit(false);
    }, [orders]);

    useEffect(() => {
        if (!isEmpty(orders) && !!isEdit) {
            setOrder(orders);
            setIsEdit(false);
        }
    }, [orders]);

    const toggle = () => {
        setModal(!modal);
    };

    const handleStoreCategoryClick = arg => {
        setOrder(arg);
        setIsEdit(true);

        toggle();
    };

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    //delete customer
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = orders => {
        setOrder(orders);
        setDeleteModal(true);
    };

    const handleStoreCategoryClicks = () => {
        // setStoreCategoryList("");
        // setIsEdit(false);
        // toggle();
        dispatch(getSettledOrder({ selectedDate, counter: title, settlement_status: "SUCCESS" }));
    };

    const handleDateChange = (date) => {
        setSelectedDate(date)
        dispatch(getSettledOrder({ selectedDate: date, counter: title, settlement_status: "SUCCESS" }));
    }

    const changeTab = (type) => {
        setTitle(type)
        dispatch(getSettledOrder({selectedDate, counter: type, settlement_status: "SUCCESS" }));
    }
    const keyField = "id";

    return (
        loading ? <Spinners setLoading={setLoading} />
            :
            <React.Fragment>
                <DeleteModal
                    show={deleteModal}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <div className="page-content">
                    <Container fluid>

                        <Row>
                            {
                                isLoading ? <Spinners setLoading={setLoading} />
                                    :

                                    <Col lg="12">
                                        <Card>
                                            <CardBody className="d-flex flex-row justify-content-between align-items-center p-0">
                                                <div>
                                                    <Label>
                                                        Select Date
                                                    </Label>
                                                    <DatePicker
                                                        selected={selectedDate}
                                                        onChange={handleDateChange}
                                                        dateFormat="dd/MM/yyyy"
                                                        def
                                                        placeholderText="Select a date"
                                                    />
                                                </div>
                                                <div className="d-flex flex-row">
                                                <Button size="sm" onClick={() => changeTab('All')} type="button" color="btn btn-success mx-2">All</Button>
                                                <Button size="sm" onClick={() => changeTab('DINEIN')} type="button" color="btn btn-primary mx-2">INDINE</Button>
                                                <Button size="sm" onClick={() => changeTab('DINEOUT')} type="button" color="btn btn-warning mx-2">DINEOUT</Button>
                                                <Button size="sm" onClick={() => changeTab('TAKEWAY')} type="button" color="btn btn-success mx-2">TAKEAWAY</Button>
                                                </div>
                                            </CardBody>
                                        </Card>
                                        <Card>
                                            <CardBody>
                                                <Col lg="12" className="h3 text-center justify-content-center">
                                                    {title + " ORDERS"}
                                                    <h3 className="py-2">
                                                        Total Amount : {orders.length && (orders.reduce((acc, order) => acc + order.total_amount, 0)).toLocaleString('en-IN', { minimumFractionDigits: 2 })}
                                                    </h3>
                                                    <div className="d-flex flex-row col-6 offset-3 my-2 justify-content-between">
                                                        <div className="">
                                                            Cash : {orders.length && (orders.reduce((acc, order) => (order.cash)?(acc + (order.cash - ((order.tip_amount)?order.tip_amount:0))):(acc + 0), 0)).toLocaleString('en-IN', { minimumFractionDigits: 2 })}
                                                        </div>
                                                        <div>
                                                            UPI : {orders.length && (orders.reduce((acc, order) => (order.upi)?(acc + (order.upi- ((order.tip_amount)?order.tip_amount:0))):(acc + 0), 0)).toLocaleString('en-IN', { minimumFractionDigits: 2 })}
                                                        </div>
                                                        <div>
                                                            card : {orders.length && (orders.reduce((acc, order) => (order.card)?(acc + (order.card- ((order.tip_amount)?order.tip_amount:0))):(acc + 0), 0)).toLocaleString('en-IN', { minimumFractionDigits: 2 })}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <TableContainer
                                                    isPagination={true}
                                                    columns={columns}
                                                    data={orders}
                                                    isGlobalFilter={true}
                                                    isShowingPageLength={false}
                                                    buttonText={"Refresh Orders"}
                                                    isAddUserList={false}
                                                    iscustomPageSizeOptions={false}
                                                    handleUserClick={handleStoreCategoryClicks}
                                                    customPageSize={80}
                                                    tableClass="table align-middle table-nowrap table-hover"
                                                    theadClass="table-light"
                                                    paginationDiv="col-sm-12 col-md-7"
                                                    pagination="pagination pagination-rounded justify-content-end mt-4"
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                            }

                            <Modal size="xl" isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? "Edit Order" : "Add Order"}
                                </ModalHeader>
                                <ModalBody>
                                    <Form
                                        onSubmit={e => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row>
                                            <Col size={6}>
                                                <Row>
                                                    <Col className="fw-bold fs-5">ITEM</Col><Col className="fw-bold fs-5">QTY</Col><Col className="fw-bold fs-5">PRICE</Col><Col className="fw-bold fs-5">Net Amount</Col>
                                                </Row>

                                                {
                                                    order && order.items && order.items.map((item, index) => {

                                                        return (
                                                            <Row key={index} className={"py-2" + (item.basic != 'REGULAR' ? "border border-2 border-orange" : "")}>
                                                                <Col className="d-flex flex-column">
                                                                    {item.fooditem.name}
                                                                    {item.basic != 'REGULAR' &&
                                                                        <span className="text-primary">{item.basic}</span>}
                                                                </Col><Col>{item.qty}</Col><Col>{item.price.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</Col><Col>{item.netprice.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</Col>
                                                            </Row>
                                                        )
                                                    })
                                                }

                                            </Col>
                                            <Col size={6}>

                                                <Row className="row border-top border-2 border-dark py-2">
                                                    <Col xs={{ offset: 5, size: 4 }} className="h4 text-right">
                                                        Total Amount:
                                                    </Col>
                                                    <Col xs={{ size: 3 }} className="h4">{order?.total_amount?.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</Col>
                                                </Row>
                                                <Row className="row py-2">
                                                    <Col xs={{ offset: 5, size: 4 }} className="h4 text-right">
                                                        Paid Amount:
                                                    </Col>
                                                    <Col xs={{ size: 3 }} className="h4">{order?.paid_amount?.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</Col>
                                                </Row>
                                                <Row className="row py-2">
                                                    <Col xs={{ offset: 5, size: 4 }} className="h4 text-right">
                                                        Tip Amount:
                                                    </Col>
                                                    <Col xs={{ size: 3 }} className="h4">{order?.tip_amount?.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</Col>
                                                </Row>
                                                <Row className="row border-top border-2 border-dark py-2">
                                                    <Col xs={{ offset: 5, size: 4 }} className="h4 text-right">
                                                        Settled Amount:
                                                    </Col>
                                                    <Col xs={{ size: 3 }} className="h4">{order?.settled_amount?.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</Col>
                                                </Row>
                                                <Row className="row bpy-2">
                                                    <Col xs={{ offset: 5, size: 4 }} className="h4 text-right">
                                                        Payment Mode:
                                                    </Col>
                                                    <Col xs={{ size: 3 }} className="h4"><b>{order?.payment_mode}</b></Col>
                                                </Row>
                                            </Col>
                                        </Row>




                                    </Form>
                                </ModalBody>
                            </Modal>
                        </Row>
                    </Container>
                </div>
                <ToastContainer />
            </React.Fragment>
    );
};

export default withRouter(SettledPayment);
