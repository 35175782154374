import React, { useState, useEffect } from "react";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from 'react-player'
import { FormFeedback } from "reactstrap";

const VideoUploadBox = ({ selectedFile, setselectedFile, onFileChange, onRemoveChange, error }) => {
    const [src, setSrc] = useState(selectedFile);

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setselectedFile(e.target.files[0]);
            setSrc(URL.createObjectURL(e.target.files[0]))
            if (onFileChange)
                onFileChange(e.target.files[0]);
        }
    }
    const isBlob = (obj) => {
        return obj instanceof Blob;
    }
    useEffect(() => {
        if (selectedFile) {
            if (!isBlob(selectedFile))
                setSrc(selectedFile)
            else
                setSrc(URL.createObjectURL(selectedFile))
            // setselectedFile(selectedFile);
        } else {
            setSrc(null)
        }
    }, [selectedFile]);
    const DeleteFile = () => {
        if (onRemoveChange)
            onRemoveChange();
        setSrc(null)
    }
    return (
        <React.Fragment>
            {!src &&
                <div className="mb-3">
                    <input onChange={onSelectFile} accept="video/*" className="form-control" type="file" id="formFile" />
                </div>
            }
            {src &&
                <div className="mb-3 d-flex flex-column align-items-center justify-content-center">
                    <ReactPlayer width="300px" height="200px" url={src} controls controlsList='play' />
                    <button type="button" onClick={x => DeleteFile()} className="mt-2 btn btn-sm btn-outline-danger">Remove</button>
                </div>
            }
            {error &&
                <FormFeedback type="invalid" style={{ display: 'block' }}>
                    {error}
                </FormFeedback>
            }
        </React.Fragment>
    )
}
export default VideoUploadBox;