import React from "react"
import PropTypes from 'prop-types';
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";

const ApexPlan = ({ dataColors, periodData, subscriptionGraph }) => {
  const stackedColumnChartColors = getChartColorsArray(dataColors);
  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: !0
      }
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "30%"
        // endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: !1
    },
    xaxis: {
      show: true,
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      labels: {
        show: true
      }
    },
    colors: stackedColumnChartColors,
    legend: {
      position: "bottom"
    },
    fill: {
      opacity: 1
    }
  }

  const series = [
    {
      name: "Free",
      data: [42, 85, 101, 56, 37, 105, 38, 58, 92, 82, 72, 32],
    },
    {
        name: "VIP",
        data: [42, 85, 101, 56, 37, 105, 38, 58, 92, 82, 72, 32],
      },
      {
        name: "Premium",
        data: [42, 85, 101, 56, 37, 105, 38, 58, 92, 82, 72, 32],
      },
  ]
  return (
    <React.Fragment>
      {subscriptionGraph && 
      <ReactApexChart
        options={options}
        series={subscriptionGraph}
        type="bar"
        height="359"
        className="apex-charts"
      />}
    </React.Fragment>
  );
}

ApexPlan.propTypes = {
  periodData: PropTypes.any
}
export default ApexPlan;
