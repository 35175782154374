import {
  GET_CATEGORYS,
  GET_CATEGORYS_SUCCESS,
  GET_CATEGORYS_FAIL,
  ADD_NEW_CATEGORY,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAIL,
  GET_CATEGORYSCOMBO_SUCCESS,
  GET_CATEGORYSCOMBO_FAIL,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,
  UPDATE_STATUS_CATEGORY,
  UPDATE_STATUS_CATEGORY_SUCCESS,
  UPDATE_STATUS_CATEGORY_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  category: [],
  categoryCombo: [],
  error: {},
  loading: false
}

const categories = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_CATEGORYSCOMBO_SUCCESS:
      return {
        ...state,
        categoryCombo: action.payload,
        loading: true
      }
      break
    case GET_CATEGORYSCOMBO_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      break
    case GET_CATEGORYS:
      return {
        ...state,
        loading: true
      }
      break
    case GET_CATEGORYS_SUCCESS:
      return {
        ...state,
        category: action.payload,
        loading: false
      }
      break
    case GET_CATEGORYS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case ADD_NEW_CATEGORY:
      return {
        ...state,
        loading: true
      }
      break
    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        category: [...state.category, action.payload],
        loading: false
      }
      break
    case ADD_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        category: action.payload,
      }
      break
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        category: state.category.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
      }
      break
    case UPDATE_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      break
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        category: state.category.filter(
          user => user.id.toString() !== action.payload.toString()
        ),
        loading: false
      }
      break
    case DELETE_CATEGORY: {
      return {
        ...state,
        loading: true
      }
    }
      break
    case DELETE_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case GET_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      break
    case UPDATE_STATUS_CATEGORY:
      return {
        ...state,
        loading: true
      }
      break
    case UPDATE_STATUS_CATEGORY_SUCCESS:
      return {
        ...state,
        category: state.category.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
        loading: false
      }
      break
    case UPDATE_STATUS_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    default:
      return state
  }
}

export default categories
