/* USERS */
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS"
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS"
export const GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL"

/* CATEGORS PROFILE */
export const GET_NOTIFICATION = "GET_NOTIFICATION"
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS"
export const GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL"

/**
 * add user
 */
export const ADD_NEW_NOTIFICATION = "ADD_NEW_NOTIFICATION"
export const ADD_NOTIFICATION_SUCCESS = "ADD_NOTIFICATION_SUCCESS"
export const ADD_NOTIFICATION_FAIL = "ADD_NOTIFICATION_FAIL"

/**
 * Edit user
 */
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION"
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS"
export const UPDATE_NOTIFICATION_FAIL = "UPDATE_NOTIFICATION_FAIL"

/**
 * Delete user
 */
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION"
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS"
export const DELETE_NOTIFICATION_FAIL = "DELETE_NOTIFICATION_FAIL"

export const GET_NOTIFICATIONSCOMBO = "GET_NOTIFICATIONSCOMBO"
export const GET_NOTIFICATIONSCOMBO_FAIL = "GET_NOTIFICATIONSCOMBO_FAIL"
export const GET_NOTIFICATIONSCOMBO_SUCCESS = "GET_NOTIFICATIONSCOMBO_SUCCESS"
