/* USERS */
export const GET_STORE_METRICS = "GET_STORE_METRICS"
export const GET_STORE_METRICS_SUCCESS = "GET_STORE_METRICS_SUCCESS"
export const GET_STORE_METRICS_FAIL = "GET_STORE_METRICS_FAIL"

/* CATEGORS PROFILE */
export const GET_STORE_METRIC = "GET_STORE_METRIC"
export const GET_STORE_METRIC_SUCCESS = "GET_STORE_METRIC_SUCCESS"
export const GET_STORE_METRIC_FAIL = "GET_STORE_METRIC_FAIL"

/**
 * add user
 */
export const NEW_STORE_METRIC = "NEW_STORE_METRIC"
export const NEW_STORE_METRIC_SUCCESS = "NEW_STORE_METRIC_SUCCESS"
export const NEW_STORE_METRIC_FAIL = "NEW_STORE_METRIC_FAIL"

/**
 * Edit user
 */
export const UPDATE_STORE_METRIC = "UPDATE_STORE_METRIC"
export const UPDATE_STORE_METRIC_SUCCESS = "UPDATE_STORE_METRIC_SUCCESS"
export const UPDATE_STORE_METRIC_FAIL = "UPDATE_STORE_METRIC_FAIL"

/**
 * Delete user
 */
export const DELETE_STORE_METRIC = "DELETE_STORE_METRIC"
export const DELETE_STORE_METRIC_SUCCESS = "DELETE_STORE_METRIC_SUCCESS"
export const DELETE_STORE_METRIC_FAIL = "DELETE_STORE_METRIC_FAIL"

export const GET_STORE_METRICSCOMBO = "GET_STORE_METRICSCOMBO"
export const GET_STORE_METRICSCOMBO_FAIL = "GET_STORE_METRICSCOMBO_FAIL"
export const GET_STORE_METRICSCOMBO_SUCCESS = "GET_STORE_METRICSCOMBO_SUCCESS"
