import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../components/Common/withRouter";
import TableContainer from "../components/Common/TableContainer";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    UncontrolledTooltip,
    Input,
    Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteModal from "../components/Common/DeleteModal";

import {
    getStoreItems as onGetStoreItems,
    addNewStoreItem as onAddNewStoreItem,
    updateStoreItem as onUpdateStoreItem,
    deleteStoreItem as onDeleteStoreItem,
} from "../store/storeitem/actions";
import { isEmpty } from "lodash";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import moment from 'moment';
import CategorySelect from "components/Common/CategorySelect";
import { CustomBadge } from "components/Common/ColumnStyle";
import MetricSelect from "components/Common/MetricSelect";
import StoreCategorySelect from "components/Common/StoreCategorySelect";
const StoreItems = props => {

    //meta title
    document.title = "Mythreya - StoreItems";

    const dispatch = useDispatch();
    const [storeItem, setStoreItem] = useState();
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [selectCategory, setSelectCategory] = useState(null);
    const [selectedMetric, setSelectedMetric] = useState(null)
    const [selectMetric, setSelectMetric] = useState(null);

    const [isSubmitted, setIsSubmitted] = useState(false);

    const changeCategory = (val) => {
        validation.setFieldValue("category", val.value)
        setSelectedCategory(val.value)
    }

    const changeMetric = (val) => {
        validation.setFieldValue("metric", val.value)
        setSelectedMetric(val.value)
    }

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: (storeItem && storeItem.name) || "",
            category: (storeItem && storeItem.category?.id) || "",
            metric: (storeItem && storeItem.metric?.id) || "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
            category: Yup.string().required("Please Select Category"),
            metric: Yup.string().required("Please Select Metric")
        }),
        onSubmit: values => {
            if (isEdit) {
                const updateStoreItem = {
                    id: storeItem.id,
                    name: values.name,
                    store_category:selectedCategory,
                    store_metric:selectedMetric
                };
                dispatch(onUpdateStoreItem(updateStoreItem));
                setIsEdit(false);
                validation.resetForm();
            } else {
                const newStoreItem = {
                    name: values["name"],
                    store_category:selectedCategory,
                    store_metric:selectedMetric
                };
                // save new user
                dispatch(onAddNewStoreItem(newStoreItem));
                validation.resetForm();
            }
            toggle();
        },
    });

    const selectStoreItemsState = (state) => state.storeitems;
    const StoreItemsProperties = createSelector(
        selectStoreItemsState,
        (StoreItem) => ({
            storeItems: StoreItem.storeitem,
            loading: StoreItem.loading
        })
    );

    const {
        storeItems, loading
    } = useSelector(StoreItemsProperties);

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setLoading] = useState(loading)
    const [storeItemList, setStoreItemList] = useState([]);
    const columns = useMemo(
        () => [
            {
                Header: "#",
                // accessor: "name",
                disableFilters: true,
                filterable: true,
                accessor: (cellProps) => (
                    <>
                        {!cellProps.img ? (
                            <div className="avatar-xs">
                                <span className="avatar-title rounded-circle">
                                    {cellProps.name.charAt(0)}
                                </span>
                            </div>
                        ) : (
                            <div>
                                <img
                                    className="rounded-circle avatar-xs"
                                    src={cellProps.img}
                                    alt=""
                                />
                            </div>
                        )}
                    </>
                ),
            },
            {
                Header: "Name",
                accessor: "name",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.name}</Link>
                            </h5>
                            <p className="text-muted mb-0">{cellProps.row.original.designation}</p>
                        </>
                    )
                },
            },
            {
                Header: "Category",
                accessor: "category",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.store_category?.name}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Metric",
                accessor: "metric",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.store_metric?.name}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Created By",
                accessor: "createdBy",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.createdBy?.name}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Created Date",
                accessor: "createdAt",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{moment(cellProps.row.original.createdAt).format('MMM D, YYYY')}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Action",
                Cell: cellProps => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-success"
                                onClick={() => {
                                    const userData = cellProps.row.original;
                                    handleStoreItemClick(userData);
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const userData = cellProps.row.original;
                                    onClickDelete(userData);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                },
            },
        ],
        []
    );

    useEffect(() => {
        if (storeItems && !storeItems.length) {
            dispatch(onGetStoreItems());
            setIsEdit(false);
        }
    }, [dispatch]);

    useEffect(() => {
        setStoreItem(storeItems);
        setIsEdit(false);
    }, [storeItems]);

    useEffect(() => {
        if (!isEmpty(storeItems) && !!isEdit) {
            setStoreItem(storeItems);
            setIsEdit(false);
        }
    }, [storeItems]);

    const toggle = () => {
        setModal(!modal);
    };

    const handleStoreItemClick = arg => {
        const storeItem = arg;
        setSelectedCategory(storeItem.store_category._id)
        setSelectCategory(storeItem.store_category._id)

        setSelectedMetric(storeItem.store_metric._id)
        setSelectMetric(storeItem.store_metric._id)
        
        setStoreItem({
            id: storeItem.id,
            name: storeItem.name,
        });

        validation.initialValues = {
            name: (storeItem && storeItem.name) || "",
            category: (storeItem && storeItem.store_category?._id) || "",
            metric: (storeItem && storeItem.store_metric?._id) || "",
        }
        setIsEdit(true);

        toggle();
    };

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    //delete customer
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = storeItems => {
        setStoreItem(storeItems);
        setDeleteModal(true);
    };

    const handleDeleteStoreItem = () => {
        if (storeItem && storeItem.id) {
            dispatch(onDeleteStoreItem(storeItem.id));
        }
        onPaginationPageChange(1);
        setDeleteModal(false);
    };

    const handleStoreItemClicks = () => {
        setStoreItem({})
        setStoreItemList("");
        setIsEdit(false);
        setSelectCategory(null)
        setSelectedCategory(null)
        setSelectMetric(null)
        setSelectedMetric(null)
        validation.resetForm();
        toggle();
    };

    const keyField = "id";

    return (
        loading ? <Spinners setLoading={setLoading} />
            :
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteStoreItem}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs breadcrumbItem="StoreItems" />
                    <Row>
                        {
                            isLoading ? <Spinners setLoading={setLoading} />
                                :
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            <TableContainer
                                                isPagination={true}
                                                columns={columns}
                                                data={storeItems}
                                                isGlobalFilter={true}
                                                isShowingPageLength={true}
                                                buttonText={"Create StoreItem"}
                                                isAddUserList={true}
                                                iscustomPageSizeOptions={true}
                                                handleUserClick={handleStoreItemClicks}
                                                customPageSize={10}
                                                tableClass="table align-middle table-nowrap table-hover"
                                                theadClass="table-light"
                                                paginationDiv="col-sm-12 col-md-7"
                                                pagination="pagination pagination-rounded justify-content-end mt-4"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                        }

                        <Modal isOpen={modal} toggle={toggle}>
                            <ModalHeader toggle={toggle} tag="h4">
                                {!!isEdit ? "Edit StoreItem" : "Add StoreItem"}
                            </ModalHeader>
                            <ModalBody>
                                <Form
                                    onSubmit={e => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}
                                >
                                    <Row>
                                        <Col xs={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">Name</Label>
                                                <Input
                                                    name="name"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.name || ""}
                                                    invalid={
                                                        validation.touched.name &&
                                                            validation.errors.name
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.touched.name &&
                                                    validation.errors.name ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.name}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Label>Select Category</Label>
                                                <StoreCategorySelect isSubmitted={isSubmitted} error={validation.errors.category} defaultValue={selectCategory} OnSelect={changeCategory}></StoreCategorySelect>
                                            </div>
                                            <div className="mb-3">
                                                <Label>Select Metric</Label>
                                                <MetricSelect isSubmitted={isSubmitted} error={validation.errors.metric} defaultValue={selectMetric} OnSelect={changeMetric}></MetricSelect>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="text-end">
                                                <button
                                                    type="submit"
                                                    className="btn btn-success save-user"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </ModalBody>
                        </Modal>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default withRouter(StoreItems);
