import React, { useEffect, useState, useRef, useMemo } from "react";
import withRouter from "../../components/Common/withRouter";
import { Card, CardBody, Col, Row } from "reactstrap";
import VideoUploadBox from "components/Common/VideoUploadBox";
import {
    addNewHome as onAddNewHome,
} from "../../store/home/actions";
import { useSelector, useDispatch } from "react-redux";

const VideoUpload = ({ home }) => {
    const dispatch = useDispatch();
    const [videoFile, setVideoFile] = useState(null)

    useEffect(() => {
        if (home && home.videoUrl) {
            setVideoFile(home.videoUrl);
        } else {
            setVideoFile(null);
        }
    }, [home]);

    const onFilChange = (videoFile) => {
        const formData = new FormData();
        formData.append('isEnable', true);
        if (videoFile) {
            formData.append('videoFile', videoFile);
        }
        dispatch(onAddNewHome({ data: formData, url: "/uploadVideo" }));
    }
    const onRemoveChange = () => {
        const formData = new FormData();
        formData.append('isEnable', true);
        formData.append('videoFile', '');
        dispatch(onAddNewHome({ data: formData, url: "/uploadVideo" }));
        setVideoFile(null)
    }

    return (
        <Card>
            <CardBody className="pt-0">
                <Row>
                    <Col sm={12} className="mb-1">
                        <h3 className="text-center">Upload Home Video</h3>
                    </Col>
                    <Col sm={12}>
                        <VideoUploadBox onFileChange={onFilChange} onRemoveChange={onRemoveChange} selectedFile={videoFile} setselectedFile={setVideoFile} />
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default withRouter(VideoUpload);