import {
  GET_STORE_METRICS,
  GET_STORE_METRICS_SUCCESS,
  GET_STORE_METRICS_FAIL,
  NEW_STORE_METRIC,
  NEW_STORE_METRIC_SUCCESS,
  NEW_STORE_METRIC_FAIL,
  UPDATE_STORE_METRIC,
  UPDATE_STORE_METRIC_SUCCESS,
  UPDATE_STORE_METRIC_FAIL,
  DELETE_STORE_METRIC,
  DELETE_STORE_METRIC_SUCCESS,
  DELETE_STORE_METRIC_FAIL,
  GET_STORE_METRIC,
  GET_STORE_METRIC_SUCCESS,
  GET_STORE_METRIC_FAIL,
  GET_STORE_METRICSCOMBO_SUCCESS,
  GET_STORE_METRICSCOMBO_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  storeMetric: [],
  storeMetricCombo: [],
  error: {},
  loading: false
}

const storeMetrics = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STORE_METRICSCOMBO_SUCCESS:
      return {
        ...state,
        storeMetricCombo: action.payload,
        loading: true
      }
      break
    case GET_STORE_METRICSCOMBO_FAIL:
      return {
        ...state,
        error: action.payload,
        
      }
      break
    case GET_STORE_METRICS:
      return {
        ...state,
        loading: true
      }
      break
    case GET_STORE_METRICS_SUCCESS:
      return {
        ...state,
        storeMetric: action.payload,
        loading: false
      }
      break
    case GET_STORE_METRICS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case NEW_STORE_METRIC:
      return {
        ...state,
        loading: true
      }
      break
    case NEW_STORE_METRIC_SUCCESS:

      return {
        ...state,
        storeMetric: [...state.storeMetric, action.payload],
        loading: false
      }
      break
    case NEW_STORE_METRIC_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case UPDATE_STORE_METRIC:
      return {
        ...state,
        loading: true
      }
      break
    case GET_STORE_METRIC_SUCCESS:
      return {
        ...state,
        storeMetric: action.payload,
        loading: false
      }
      break
    case UPDATE_STORE_METRIC_SUCCESS:
      return {
        ...state,
        storeMetric: state.storeMetric.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
        loading: false
      }
      break
    case UPDATE_STORE_METRIC_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case DELETE_STORE_METRIC:
      return {
        ...state,
        loading: true
      }
      break
    case DELETE_STORE_METRIC_SUCCESS:
      return {
        ...state,
        storeMetric: state.storeMetric.filter(
          user => user.id.toString() !== action.payload.toString()
        ),
        loading: false
      }
      break
    case DELETE_STORE_METRIC_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case GET_STORE_METRIC_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    default:
      return state
  }
}

export default storeMetrics
