import {
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAIL,
  ADD_NEW_TAG,
  ADD_TAG_SUCCESS,
  ADD_TAG_FAIL,
  UPDATE_TAG,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_FAIL,
  DELETE_TAG,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAIL,
  GET_TAG,
  GET_TAG_SUCCESS,
  GET_TAG_FAIL,
  GET_TAGSCOMBO_SUCCESS,
  GET_TAGSCOMBO_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  tag: [],
  tagCombo: [],
  error: {},
  loading: false
}

const tags = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TAGSCOMBO_SUCCESS:
      return {
        ...state,
        tagCombo: action.payload,
        loading: true
      }
      break
    case GET_TAGSCOMBO_FAIL:
      return {
        ...state,
        error: action.payload,
        
      }
      break
    case GET_TAGS:
      return {
        ...state,
        loading: true
      }
      break
    case GET_TAGS_SUCCESS:
      return {
        ...state,
        tag: action.payload,
        loading: false
      }
      break
    case GET_TAGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case ADD_NEW_TAG:
      return {
        ...state,
        loading: true
      }
      break
    case ADD_TAG_SUCCESS:

      return {
        ...state,
        tag: [...state.tag, action.payload],
        loading: false
      }
      break
    case ADD_TAG_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case UPDATE_TAG:
      return {
        ...state,
        loading: true
      }
      break
    case GET_TAG_SUCCESS:
      return {
        ...state,
        tag: action.payload,
        loading: false
      }
      break
    case UPDATE_TAG_SUCCESS:
      return {
        ...state,
        tag: state.tag.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
        loading: false
      }
      break
    case UPDATE_TAG_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case DELETE_TAG:
      return {
        ...state,
        loading: true
      }
      break
    case DELETE_TAG_SUCCESS:
      return {
        ...state,
        tag: state.tag.filter(
          user => user.id.toString() !== action.payload.toString()
        ),
        loading: false
      }
      break
    case DELETE_TAG_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case GET_TAG_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    default:
      return state
  }
}

export default tags
