import {
  GET_STORE_CATEGORYS,
  GET_STORE_CATEGORYS_FAIL,
  GET_STORE_CATEGORYS_SUCCESS,
  GET_STORE_CATEGORYSCOMBO,
  GET_STORE_CATEGORYSCOMBO_FAIL,
  GET_STORE_CATEGORYSCOMBO_SUCCESS,
  NEW_STORE_CATEGORY,
  NEW_STORE_CATEGORY_SUCCESS,
  NEW_STORE_CATEGORY_FAIL,
  UPDATE_STORE_CATEGORY,
  UPDATE_STORE_CATEGORY_SUCCESS,
  UPDATE_STORE_CATEGORY_FAIL,
  DELETE_STORE_CATEGORY,
  DELETE_STORE_CATEGORY_SUCCESS,
  DELETE_STORE_CATEGORY_FAIL,
} from "./actionTypes"

export const getStoreCategory = () => ({
  type: GET_STORE_CATEGORYS,
})

export const getStoreCategorySuccess = storecategory => ({
  type: GET_STORE_CATEGORYS_SUCCESS,
  payload: storecategory,
})

export const addNewStoreCategory = storecategory => ({
  type: NEW_STORE_CATEGORY,
  payload: storecategory,
})

export const addStoreCategorySuccess = storecategory => ({
  type: NEW_STORE_CATEGORY_SUCCESS,
  payload: storecategory,
})

export const addStoreCategoryFail = error => ({
  type: NEW_STORE_CATEGORY_FAIL,
  payload: error,
})

export const getStoreCategoryFail = error => ({
  type: GET_STORE_CATEGORYS_FAIL,
  payload: error,
})

export const updateStoreCategory = storecategory => ({
  type: UPDATE_STORE_CATEGORY,
  payload: storecategory,
})

export const updateStoreCategorySuccess = storecategory => ({
  type: UPDATE_STORE_CATEGORY_SUCCESS,
  payload: storecategory,
})

export const updateStoreCategoryFail = error => ({
  type: UPDATE_STORE_CATEGORY_FAIL,
  payload: error,
})

export const deleteStoreCategory = storecategory => ({
  type: DELETE_STORE_CATEGORY,
  payload: storecategory,
})

export const deleteStoreCategorySuccess = storecategory => ({
  type: DELETE_STORE_CATEGORY_SUCCESS,
  payload: storecategory,
})

export const deleteStoreCategoryFail = error => ({
  type: DELETE_STORE_CATEGORY_FAIL,
  payload: error,
})
export const getStoreCategoryCombo = () => ({
  type: GET_STORE_CATEGORYSCOMBO,
})

export const getStoreCategoryComboSuccess = category => ({
  type: GET_STORE_CATEGORYSCOMBO_SUCCESS,
  payload: category,
})

export const getStoreCategoryComboFail = error => ({
  type: GET_STORE_CATEGORYSCOMBO_FAIL,
  payload: error,
})