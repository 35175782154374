import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../components/Common/withRouter";
import TableContainer from "../components/Common/TableContainer";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    UncontrolledTooltip,
    Input,
    Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteModal from "../components/Common/DeleteModal";

import {
    getNotifications as onGetUsers,
    addNewNotification as onAddNewUser,
    updateNotification as onUpdateUser,
    deleteNotification as onDeleteUser,
} from "../store/notification/actions";
import { isEmpty } from "lodash";
import moment from 'moment';

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";

import { getImage } from '../helpers/common_helper'
import ImageUploadWithCrop from "components/Common/ImageUploadWithCrop";

const Notification = props => {
    document.title = "KIDS STORY TELLING (KST) - Notification";

    const dispatch = useDispatch();
    const [notification, setNotification] = useState();
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: (notification && notification.title) || "",
            msg: (notification && notification.msg) || "",
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Please Enter Title"),
            msg: Yup.string().required("Please Enter Message")
        }),
        onSubmit: values => {

            if (isEdit) {
                const formData = new FormData();
                formData.append('name', validation.values.name);
                setCroppedImageData('')
                setIsEdit(false);
                validation.resetForm();
            } else {
                let _notification = {
                    title : validation.values.title,
                    msg : validation.values.msg,
                    type : "Regular"
                }
                dispatch(onAddNewUser(_notification));
                validation.resetForm();
            }
            dispatch(onGetUsers());
            toggle();
        },
    });


    const selectContactsState = (state) => state.notifications;
    const ContactsProperties = createSelector(
        selectContactsState,
        (Notification) => ({
            categories: Notification.notification,
            loading: Notification.loading
        })
    );

    const {
        categories, loading
    } = useSelector(ContactsProperties);

    const [notificationList, setNotificationList] = useState([]);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setLoading] = useState(loading)

    const columns = useMemo(
        () => [
            {
                Header: "#",
                // accessor: "name",
                disableFilters: true,
                filterable: true,
                accessor: (cellProps) => (
                    <>
                        {!cellProps.image ? (
                            <div className="avatar-xs">
                                <span className="avatar-title rounded-circle">
                                    {cellProps.title.charAt(0)}
                                </span>
                            </div>
                        ) : (
                            <div>
                                <img
                                    className="rounded-circle avatar-xs"
                                    src={getImage(cellProps.image)}
                                    alt=""
                                />
                            </div>
                        )}
                    </>
                ),
            },
            {
                Header: "Title",
                accessor: "title",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.title}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Created By",
                accessor: "createdBy",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.createdBy?.name}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Created Date",
                accessor: "createdAt",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{moment(cellProps.row.original.createdAt).format('MMM D, YYYY')}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Action",
                Cell: cellProps => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const userData = cellProps.row.original;
                                    onClickDelete(userData);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                },
            },
        ],
        []
    );

    useEffect(() => {
        if (categories && !categories.length) {
            dispatch(onGetUsers());
            setIsEdit(false);
        }
    }, [dispatch]);

    useEffect(() => {
        setNotification(categories);
        setIsEdit(false);
    }, [categories]);

    useEffect(() => {
        if (!isEmpty(categories) && !!isEdit) {
            setNotification(categories);
            setIsEdit(false);
        }
    }, [categories]);

    const toggle = () => {
        setModal(!modal);
    };

    const handleUserClick = arg => {
        const user = arg;

        setNotification({
            id: user.id,
            name: user.name,
            designation: user.designation,
        });
        setIsEdit(true);

        toggle();
    };

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    //delete customer
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = users => {
        setNotification(users);
        setDeleteModal(true);
    };

    const handleDeleteUser = () => {
        if (notification && notification.id) {
            dispatch(onDeleteUser(notification.id));
        }
        onPaginationPageChange(1);
        setDeleteModal(false);
    };

    const handleUserClicks = () => {
        setNotificationList("");
        setIsEdit(false);
        toggle();
    };

    const keyField = "id";

    const [croppedImageData, setCroppedImageData] = useState('');

    const handleImageUpload = (croppedImage) => {
        setCroppedImageData(croppedImage);
    };

    return (
        loading ? <Spinners setLoading={setLoading} />
            :
            <React.Fragment>

                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteUser}
                    onCloseClick={() => setDeleteModal(false)}
                />

                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs breadcrumbItem="Notification" />
                        <Row>
                            {
                                isLoading ? <Spinners setLoading={setLoading} />
                                    :
                                    <Col lg="12">
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    isPagination={true}
                                                    columns={columns}
                                                    data={categories}
                                                    isGlobalFilter={true}
                                                    isShowingPageLength={true}
                                                    isAddUserList={true}
                                                    buttonText={"Create Notification"}
                                                    iscustomPageSizeOptions={true}
                                                    handleUserClick={handleUserClicks}
                                                    customPageSize={10}
                                                    tableClass="table align-middle table-nowrap table-hover"
                                                    theadClass="table-light"
                                                    paginationDiv="col-sm-12 col-md-7"
                                                    pagination="pagination pagination-rounded justify-content-end mt-4"
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                            }

                            <Modal isOpen={modal} size="md" toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? "Edit Notification" : "Add Notification"}
                                </ModalHeader>
                                <ModalBody>
                                    <Form
                                        onSubmit={e => {
                                            e.preventDefault();
                                            console.log("hi")
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row>
                                            <Col xs={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Title</Label>
                                                    <Input
                                                        name="title"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.title || ""}
                                                        invalid={
                                                            validation.touched.title &&
                                                                validation.errors.title
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.title &&
                                                        validation.errors.title ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.title}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label">Message</Label>
                                                    <Input
                                                        name="msg"
                                                        type="textarea"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.msg || ""}
                                                        invalid={
                                                            validation.touched.msg &&
                                                                validation.errors.msg
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.msg &&
                                                        validation.errors.msg ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.msg}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                               
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="text-end">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-success save-user"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </ModalBody>
                            </Modal>
                        </Row>
                    </Container>
                </div>
                <ToastContainer />
            </React.Fragment>
    );
};

export default withRouter(Notification);
