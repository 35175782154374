import {
  GET_SLIDES,
  GET_SLIDES_SUCCESS,
  GET_SLIDES_FAIL,
  ADD_NEW_SLIDE,
  ADD_SLIDE_SUCCESS,
  ADD_SLIDE_FAIL,
  GET_SLIDESCOMBO_SUCCESS,
  GET_SLIDESCOMBO_FAIL,
  UPDATE_SLIDE_SUCCESS,
  UPDATE_SLIDE_FAIL,
  DELETE_SLIDE,
  DELETE_SLIDE_SUCCESS,
  DELETE_SLIDE_FAIL,
  GET_SLIDE_SUCCESS,
  GET_SLIDE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  slide: [],
  slideCombo: [],
  error: {},
  loading: false
}

const slides = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_SLIDESCOMBO_SUCCESS:
      return {
        ...state,
        slideCombo: action.payload,
        loading: true
      }
      break
    case GET_SLIDESCOMBO_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      break
    case GET_SLIDES:
      return {
        ...state,
        loading: true
      }
      break
    case GET_SLIDES_SUCCESS:
      return {
        ...state,
        slide: action.payload,
        loading: false
      }
      break
    case GET_SLIDES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case ADD_NEW_SLIDE:
      return {
        ...state,
        loading: true
      }
      break
    case ADD_SLIDE_SUCCESS:
      return {
        ...state,
        slide: [...state.slide, action.payload],
        loading: false
      }
      break
    case ADD_SLIDE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case GET_SLIDE_SUCCESS:
      return {
        ...state,
        slide: action.payload,
      }
      break
    case UPDATE_SLIDE_SUCCESS:
      return {
        ...state,
        slide: state.slide.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
      }
      break
    case UPDATE_SLIDE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      break
    case DELETE_SLIDE_SUCCESS:
      return {
        ...state,
        slide: state.slide.filter(
          user => user.id.toString() !== action.payload.toString()
        ),
        loading: false
      }
      break
    case DELETE_SLIDE: {
      return {
        ...state,
        loading: true
      }
    }
      break
    case DELETE_SLIDE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case GET_SLIDE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      break
    default:
      return state
  }
}

export default slides
