import {
  GET_SUBSCRIBER,
  GET_SUBSCRIBER_SUCCESS,
  GET_SUBSCRIBER_FAIL,
  ADD_NEW_SUBSCRIBER,
  ADD_SUBSCRIBER_SUCCESS,
  ADD_SUBSCRIBER_FAIL,
  UPDATE_SUBSCRIBER_SUCCESS,
  UPDATE_SUBSCRIBER_FAIL,
  DELETE_SUBSCRIBER,
  DELETE_SUBSCRIBER_SUCCESS,
  DELETE_SUBSCRIBER_FAIL
} from "./actionTypes"

const INIT_STATE = {
  subscriber: [],
  error: {},
  loading: false
}

const subscribers = (state = INIT_STATE, action) => {

  switch (action.type) {
    case GET_SUBSCRIBER:
      return {
        ...state,
        loading: true
      }
      break
    case GET_SUBSCRIBER_SUCCESS:
      return {
        ...state,
        subscriber: action.payload,
        loading: false
      }
      break
    case GET_SUBSCRIBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case ADD_NEW_SUBSCRIBER:
      return {
        ...state,
        loading: true
      }
      break
    case ADD_SUBSCRIBER_SUCCESS:
      return {
        ...state,
        subscriber: [...state.subscriber, action.payload],
        loading: false
      }
      break
    case ADD_SUBSCRIBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break

    case UPDATE_SUBSCRIBER_SUCCESS:
      return {
        ...state,
        subscriber: state.subscriber.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
      }
      break
    case UPDATE_SUBSCRIBER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      break
    case DELETE_SUBSCRIBER_SUCCESS:
      return {
        ...state,
        subscriber: state.subscriber.filter(
          user => user.id.toString() !== action.payload.toString()
        ),
        loading: false
      }
      break
    case DELETE_SUBSCRIBER: {
      return {
        ...state,
        loading: true
      }
    }
      break
    case DELETE_SUBSCRIBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break

    default:
      return state
  }
}

export default subscribers
