import { call, put, takeEvery } from "redux-saga/effects"
import { NEW_STORE_METRIC, DELETE_STORE_METRIC, GET_STORE_METRICS, UPDATE_STORE_METRIC, GET_STORE_METRICSCOMBO } from "./actionTypes"
import {
  getStoreMetricsSuccess,
  getStoreMetricsFail,
  addStoreMetricFail,
  addStoreMetricSuccess,
  updateStoreMetricSuccess,
  updateStoreMetricFail,
  deleteStoreMetricSuccess,
  deleteStoreMetricFail,
  getStoreMetricsComboSuccess,
  getStoreMetricsComboFail
} from "./actions"
import { getStoreMetric, addStoreMetric, updateStoreMetric, deleteStoreMetric,getStoreMetricCombo } from "../../helpers/store_metric_helper"
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchStoreMetric() {
  try {
    const response = yield call(getStoreMetric)
    yield put(getStoreMetricsSuccess(response.storeMetrics))
  } catch (error) {
    yield put(getStoreMetricsFail(error))
  }
}

function* fetchStoreMetricsCombo() {
  try {
    const response = yield call(getStoreMetricCombo)
    yield put(getStoreMetricsComboSuccess(response.StoreMetrics))
  } catch (error) {
    yield put(getStoreMetricsComboFail(error))
  }
}

function* onUpdateStoreMetric({ payload: store_metric }) {
  try {
    const response = yield call(updateStoreMetric, store_metric)
    yield put(updateStoreMetricSuccess(response.StoreMetric))
    toast.success("Updated Successfully", { autoClose: 2000 });
  } catch (error) {
    console.log(error)
    yield put(updateStoreMetricFail(error))
    toast.error("Updated Failed", { autoClose: 2000 });
  }
}

function* onDeleteStoreMetric({ payload: store_metric }) {
  try {
    const response = yield call(deleteStoreMetric, store_metric)
    yield put(deleteStoreMetricSuccess(response.StoreMetric.id))
    toast.success("Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteStoreMetricFail(error))
    toast.error("Deleted Failed", { autoClose: 2000 });
  }
}

function* onAddStoreMetric({ payload: store_metric }) {
  try {
    const response = yield call(addStoreMetric, store_metric)
    yield put(addStoreMetricSuccess(response.StoreMetric))
    toast.success("Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addStoreMetricFail(error))
    toast.error("Added Failed", { autoClose: 2000 });
  }
}

function* store_metricSaga() {
  yield takeEvery(GET_STORE_METRICS, fetchStoreMetric)
  yield takeEvery(NEW_STORE_METRIC, onAddStoreMetric)
  yield takeEvery(UPDATE_STORE_METRIC, onUpdateStoreMetric)
  yield takeEvery(DELETE_STORE_METRIC, onDeleteStoreMetric)
  yield takeEvery(GET_STORE_METRICSCOMBO, fetchStoreMetricsCombo)
}

export default store_metricSaga;
