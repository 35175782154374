import {
  GET_SUBSCRIPTIONCOMBO,
  GET_SUBSCRIPTIONCOMBO_FAIL,
  GET_SUBSCRIPTIONCOMBO_SUCCESS,
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_FAIL,
  GET_SUBSCRIPTIONS_SUCCESS,
  ADD_NEW_SUBSCRIPTION,
  ADD_SUBSCRIPTION_SUCCESS,
  ADD_SUBSCRIPTION_FAIL,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAIL,
  DELETE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_FAIL,
} from "./actionTypes"

export const getSubscriptions = () => ({
  type: GET_SUBSCRIPTIONS,
})

export const getSubscriptionsSuccess = subscription => ({
  type: GET_SUBSCRIPTIONS_SUCCESS,
  payload: subscription,
})

export const addNewSubscription = subscription => ({
  type: ADD_NEW_SUBSCRIPTION,
  payload: subscription,
})

export const addSubscriptionSuccess = subscription => ({
  type: ADD_SUBSCRIPTION_SUCCESS,
  payload: subscription,
})

export const addSubscriptionFail = error => ({
  type: ADD_SUBSCRIPTION_FAIL,
  payload: error,
})

export const getSubscriptionsFail = error => ({
  type: GET_SUBSCRIPTIONS_FAIL,
  payload: error,
})

export const updateSubscription = subscription => ({
  type: UPDATE_SUBSCRIPTION,
  payload: subscription,
})

export const updateSubscriptionSuccess = subscription => ({
  type: UPDATE_SUBSCRIPTION_SUCCESS,
  payload: subscription,
})

export const updateSubscriptionFail = error => ({
  type: UPDATE_SUBSCRIPTION_FAIL,
  payload: error,
})

export const deleteSubscription = subscription => ({
  type: DELETE_SUBSCRIPTION,
  payload: subscription,
})

export const deleteSubscriptionSuccess = subscription => ({
  type: DELETE_SUBSCRIPTION_SUCCESS,
  payload: subscription,
})

export const deleteSubscriptionFail = error => ({
  type: DELETE_SUBSCRIPTION_FAIL,
  payload: error,
})

export const getSubscriptionCombo = () => ({
  type: GET_SUBSCRIPTIONCOMBO,
})

export const getSubscriptionComboSuccess = category => ({
  type: GET_SUBSCRIPTIONCOMBO_SUCCESS,
  payload: category,
})

export const getSubscriptionComboFail = error => ({
  type: GET_SUBSCRIPTIONCOMBO_FAIL,
  payload: error,
})