import {
    GET_ORDERS,
    GET_ORDERS_FAIL,
    GET_ORDERS_SUCCESS,
    GET_ORDER_UPDATE,
    GET_ORDER_UPDATE_SUCCESS,
    GET_ORDER_UPDATE_FAIL,
    GET_SETTLED_ORDER_UPDATE,
    GET_SETTLED_ORDER_UPDATE_SUCCESS,
    GET_SETTLED_ORDER_UPDATE_FAIL,
    GET_ORDER_ITEMS_UPDATE,
    GET_ORDER_UPDATE_ITEMS_FAIL,
    GET_ORDER_UPDATE_ITEMS_SUCCESS,
    GET_CANCELLED_ORDER_ITMES,
    GET_CANCELLED_ORDER_ITMES_FAIL,
    GET_CANCELLED_ORDER_ITMES_SUCCESS
  } from "./actionTypes"
  
  export const getOrders = (filterdata) => ({
    type: GET_ORDERS,
    payload: filterdata
  })
  
  export const getOrdersSuccess = category => ({
    type: GET_ORDERS_SUCCESS,
    payload: category,
  })

  export const getOrdersFail = error => ({
    type: GET_ORDERS_FAIL,
    payload: error,
  })
  
  export const updateOrder = order => ({
    type: GET_ORDER_UPDATE,
    payload: order,
  })
  
  export const updateOrderSuccess = order => ({
    type: GET_ORDER_UPDATE_SUCCESS,
    payload: order,
  })
  
  export const updateOrderFail = error => ({
    type: GET_ORDER_UPDATE_FAIL,
    payload: error,
  })

  export const updateOrderItems = order => ({
    type: GET_ORDER_ITEMS_UPDATE,
    payload: order,
  })
  
  export const updateOrderItemsSuccess = order => ({
    type: GET_ORDER_UPDATE_ITEMS_SUCCESS,
    payload: order,
  })
  
  export const updateOrderItemsFail = error => ({
    type: GET_ORDER_UPDATE_ITEMS_FAIL,
    payload: error,
  })

  
  export const getSettledOrder = order => ({
    type: GET_SETTLED_ORDER_UPDATE,
    payload: order,
  })
  
  export const getSettledOrderSuccess = order => ({
    type: GET_SETTLED_ORDER_UPDATE_SUCCESS,
    payload: order,
  })
  
  export const getSettledOrderFail = error => ({
    type: GET_SETTLED_ORDER_UPDATE_FAIL,
    payload: error,
  })

  export const getCancelledOrderItems = order => ({
    type: GET_CANCELLED_ORDER_ITMES,
    payload: order,
  })
  
  export const getCancelledOrderItemsSuccess = order => ({
    type: GET_CANCELLED_ORDER_ITMES_SUCCESS,
    payload: order,
  })
  
  export const getCancelledOrderItemsFail = error => ({
    type: GET_CANCELLED_ORDER_ITMES_FAIL,
    payload: error,
  })
  
  