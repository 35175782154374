import React, { useEffect, useState, useRef, useMemo } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    UncontrolledTooltip,
    Input,
    Form,
} from "reactstrap";
import ReactApexChart from "react-apexcharts";
import withRouter from "components/Common/withRouter";
import { useDispatch, useSelector } from "react-redux";
import { getChartData } from "../store/fooditem/actions";
import Spinners from "components/Common/Spinner";
import { createSelector } from "reselect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { getCategoryCombo } from "../store/category/actions";
import { getFoodItemCombo } from "../store/fooditem/actions";

const FoodStatistics = props => {

    document.title = "Mythreya - Table Payments";

    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectMonth, setSelectMonth] = useState(null);
    const [monthData, setMonthData] = useState([]);
    const [selectDataType, setSelectDataType] = useState(null);
    const [selectReport, setSelectReport] = useState({
        label: "Today",
        value: "today"
    });
    const [selectItems, setSelectItems] = useState([]);
    const [selectItemsOption, setSelectItemsOption] = useState([]);
    const [chartOptions, setChartOptions] = useState({
        chart: {
            id: "basic-bar",
            zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: false,
            },
            toolbar: {
                tools: {
                    pan: true,
                    reset: true
                }
            },
        },
        
        xaxis: {
            categories: []
        },
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8'],
        dataLabels: {
            enabled: false,
        },
        animations: {
            enabled: true
            },
        yaxis: {
            min: 0,
            max: 500,
            forceNiceScale: true,
            labels: {
                style: {
                    fontSize: '12px'
                }
            }
        },
        barHeight: '100%'
    });
    const [chartSeries, setChartSeries] = useState([
        {
            name: "series-1",
            data: []
        }
    ]);

    const selectStoreChartDataState = (state) => state.fooditems;
    const StoreCategoryProperties = createSelector(
        selectStoreChartDataState,
        (fooditem) => ({
            chartData: fooditem.chartData,
            loading: fooditem.loading
        })
    );
    const { chartData, loading } = useSelector(StoreCategoryProperties);
    const [isLoading, setLoading] = useState(loading);

    const selectContactsState = (state) => state.categories;
    const ContactsProperties = createSelector(
        selectContactsState,
        (Category) => ({
            categories: Category.categoryCombo,
            loading: Category.loading
        })
    );
    const { categories } = useSelector(ContactsProperties);

    const selectFoodItemState = (state) => state.fooditems;
    const FoodItemProperties = createSelector(
        selectFoodItemState,
        (FoodItem) => ({
            fooditems: FoodItem.fooditemCombo,
            loading: FoodItem.loading
        })
    );
    const { fooditems } = useSelector(FoodItemProperties);


    useEffect(() => {
        if (chartData && !chartData.length) {
            dispatch(getChartData({ from: "", to: "", type: "today" }));

        }
    }, [dispatch]);

    useEffect(() => {
        changeChartData("");
        filterData();
    }, [chartData]);

    useEffect(() => {
        if (categories && !categories.length) {
            dispatch(getCategoryCombo());
        }
    }, [dispatch, categories]);

    useEffect(() => {
        if (fooditems && !fooditems.length) {
            dispatch(getFoodItemCombo());
        }
    }, [dispatch, fooditems]);
    

    const handleStartDateChange = (date) => {
        setStartDate(date)
        setEndDate(date)
    }

    const handleEndDateChange = (date) => {
        setEndDate(date);

    }
    const handleReport = (val) => {
        setSelectReport(val)
        setSelectDataType(null)
        setSelectItems(null)
        setMonthData([])
        setEndDate("")
        setStartDate("")
    }

    const handleData = (data) => {
        setSelectDataType(data)
        // const result = filterData();
        if (data.value == "category") {
            const uniqueData = monthData.filter((obj, index, self) =>
                index === self.findIndex((t) => (
                    t.categoryName === obj.categoryName
                ))
            ).map(obj => obj.categoryName);
            setChartOptions(prevOptions => ({
                ...prevOptions,
                xaxis: {
                    ...prevOptions.xaxis,
                    categories: uniqueData
                }
            }));
            let series = [];
            uniqueData.forEach(x => {
                const index = monthData.findIndex(item => item.categoryName == x);
                if (index != -1)
                    series.push(monthData[index].totalQty);
                else
                    series.push(0)
            })
            setChartSeries([{
                name: "Total ",
                data: series
            }]);
        } else if (data.value == "fooditem") {
            const items = monthData.reduce((acc, x) => acc.concat(x.items), []);
            const uniqueData = items.filter((obj, index, self) =>
                index === self.findIndex((t) => (
                    t.foodItemName === obj.foodItemName
                ))
            ).map(obj => obj.foodItemName);
            setChartOptions(prevOptions => ({
                ...prevOptions,
                xaxis: {
                    ...prevOptions.xaxis,
                    categories: uniqueData
                }
            }));
            let series = [];
            uniqueData.forEach(x => {
                const index = items.findIndex(item => item.foodItemName == x);
                if (index != -1)
                    series.push(items[index].sumQty);
                else
                    series.push(0)
            })
            setChartSeries([{
                name: "Total ",
                data: series
            }]);
        } else {
            const mappedResult = Object.values(monthNames).map(x => {
                return {
                    value: x,
                    label: x
                };
            });
            setSelectItemsOption(mappedResult)
        }

    }
    const handleItems = (data) => {
        setSelectItems(data)
        if(data.length != 0){
            const _data = data.map(x => x.label)
            if (selectDataType.value == "category") {
                const uniqueData = monthData.filter((obj, index, self) =>
                    index === self.findIndex((t) => (
                        t.categoryName === obj.categoryName
                    ))
                ).filter(obj => _data.includes(obj.categoryName)).map(x => x.categoryName);
                setChartOptions(prevOptions => ({
                    ...prevOptions,
                    xaxis: {
                        ...prevOptions.xaxis,
                        categories: uniqueData
                    }
                }));
                let series = [];
                uniqueData.forEach(x => {
                    const index = monthData.findIndex(item => item.categoryName == x);
                    if (index != -1)
                        series.push(monthData[index].totalQty);
                    else
                        series.push(0)
                })
                setChartSeries([{
                    name: "Total ",
                    data: series
                }]);
            }else{
                const items = monthData.reduce((acc, x) => acc.concat(x.items), []);
                const uniqueData = items.filter((obj, index, self) =>
                    index === self.findIndex((t) => (
                        t.foodItemName === obj.foodItemName
                    ))
                ).filter(obj => _data.includes(obj.foodItemName)).map(obj => obj.foodItemName);
                setChartOptions(prevOptions => ({
                    ...prevOptions,
                    xaxis: {
                        ...prevOptions.xaxis,
                        categories: uniqueData
                    }
                }));
                let series = [];
                uniqueData.forEach(x => {
                    const index = items.findIndex(item => item.foodItemName == x);
                    if (index != -1)
                        series.push(items[index].sumQty);
                    else
                        series.push(0)
                })
                setChartSeries([{
                    name: "Total ",
                    data: series
                }]);
            }
        }else{
            handleData(selectDataType)
        }
        
        // if (selectDataType.value == "category") {
        //     setChartOptions(prevOptions => ({
        //         ...prevOptions,
        //         xaxis: {
        //             ...prevOptions.xaxis,
        //             categories: Object.values(monthNames)
        //         }
        //     }));
        //     let series = [];
        //     Object.values(monthNames).forEach(x => {
        //         const index = monthData.findIndex(item => item.month === x && item.categoryName == data.value);
        //         if (index != -1)
        //             series.push(monthData[index].totalQty);
        //         else
        //             series.push(0)
        //     })
        //     setChartSeries([{
        //         name: "Total ",
        //         data: series
        //     }]);
    }

    const handleSearch = () => {
        setSelectDataType(null)
        setSelectItems(null)
        dispatch(getChartData({ from: startDate, to: endDate, type: selectReport.value }));
    }

    const filterData = () => {
        const groupedByCategoryAndMonth = chartData.reduce((acc, item) => {
            const { categoryName, foodItemName, sumQty, month } = item;
            if (!acc[categoryName]) {
                acc[categoryName] = {};
            }
            if (!acc[categoryName][month]) {
                acc[categoryName][month] = {
                    categoryName: categoryName,
                    month: monthNames[month],
                    items: [],
                    totalQty: 0
                };
            }
            acc[categoryName][month].items.push({ foodItemName, sumQty });
            acc[categoryName][month].totalQty += sumQty;
            return acc;
        }, {});

        // Step 2: Group items by foodItemName and sum the quantities within each category and month
        Object.keys(groupedByCategoryAndMonth).forEach(category => {
            const months = groupedByCategoryAndMonth[category];
            Object.keys(months).forEach(month => {
                const items = months[month].items;
                const groupedItems = items.reduce((acc, item) => {
                    const { foodItemName, sumQty } = item;
                    if (!acc[foodItemName]) {
                        acc[foodItemName] = {
                            foodItemName: foodItemName,
                            sumQty: 0
                        };
                    }
                    acc[foodItemName].sumQty += sumQty;
                    return acc;
                }, {});
                months[month].items = Object.values(groupedItems);
            });
        });

        // Convert the result to an array
        const result = Object.values(groupedByCategoryAndMonth).reduce((acc, category) => {
            const categoryData = Object.values(category);
            return acc.concat(categoryData);
        }, []);
        setMonthData(result)
        return result;
    }

    const changeChartData = () => {
        if (selectReport.value == "today") {
            setChartOptions(prevOptions => ({
                ...prevOptions,
                xaxis: {
                    ...prevOptions.xaxis,
                    categories: chartData.map(item => item.foodItemName)
                }
            }));

            setChartSeries([{
                name: "Total ",
                data: chartData.map(item => item.sumQty)
            }]);
        }

    }

    const reports = [
        {
            label: "Today",
            value: "today"
        },
        {
            label: "Dates Wise",
            value: "daterange"
        },
        {
            label: "Monthly",
            value: "monthly"
        },
        {
            label: "Weekly",
            value: "weekly"
        }
    ];

    const datatypes = [
        {
            label: "Category",
            value: "category"
        },
        {
            label: "Food Item",
            value: "fooditem"
        },
        {
            label: "Category By Month",
            value: "categorymonthly"
        },
        {
            label: "Food Item By Month",
            value: "fooditemmonthly"
        }
    ];

    const monthOptions = [
        { label: "Jan", value: "January" },
        { label: "Feb", value: "February" },
        { label: "Mar", value: "March" },
        { label: "Apr", value: "April" },
        { label: "May", value: "May" },
        { label: "Jun", value: "June" },
        { label: "Jul", value: "July" },
        { label: "Aug", value: "August" },
        { label: "Sep", value: "September" },
        { label: "Oct", value: "October" },
        { label: "Nov", value: "November" },
        { label: "Dec", value: "December" }
    ]

    const monthNames = {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December"
    };


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {
                        isLoading ? <Spinners setLoading={setLoading} />
                            :
                            <React.Fragment>
                                <Row>
                                    <Col lg="2">
                                        <div className="d-flex flex-column align-items-start">
                                            <Label className="my-1 m-0">
                                                Report Type
                                            </Label>
                                            <Select
                                                className="w-100"
                                                classNamePrefix="select2-selection"
                                                placeholder="Choose..."
                                                title="Duration"

                                                options={reports}
                                                value={selectReport}
                                                onChange={(val) => handleReport(val)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="2">
                                        <div className="d-flex flex-column align-items-start">
                                            <Label className="">
                                                From
                                            </Label>
                                            <DatePicker
                                                readOnly={selectReport.value == "daterange" ? false : true}
                                                selected={startDate}
                                                onChange={handleStartDateChange}
                                                selectsStart
                                                startDate={startDate}
                                                endDate={endDate}
                                                dateFormat="dd-MM-yyyy"
                                                placeholderText="Start Date"
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="2">
                                        <div className="d-flex flex-column align-items-start">
                                            <Label className="">
                                                To
                                            </Label>
                                            <DatePicker
                                                selected={endDate}
                                                readOnly={selectReport.value == "daterange" ? false : true}
                                                onChange={handleEndDateChange}
                                                selectsEnd
                                                startDate={startDate}
                                                endDate={endDate}
                                                minDate={startDate}
                                                dateFormat="dd-MM-yyyy"
                                                placeholderText="End Date"
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="1" className="d-flex justify-content-end align-items-end">
                                        <button onClick={() => handleSearch()} className="btn btn-primary">Search</button>
                                    </Col>

                                </Row>
                                <Row className="mt-4">
                                    <Col lg="2">
                                        <div className="d-flex flex-column align-items-start">
                                            <Label className="my-1 m-0">
                                                Data Type
                                            </Label>
                                            <Select
                                                className="w-100"
                                                classNamePrefix="select2-selection"
                                                placeholder="Choose..."
                                                title="Duration"
                                                isMulti={false}
                                                isDisabled={monthData.length != 0?false:true}
                                                isSearchable={false}
                                                options={datatypes}
                                                value={selectDataType}
                                                onChange={(val) => handleData(val)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="10">
                                        <div className="d-flex flex-column align-items-start">
                                            <Label className="my-1 m-0">
                                                Select {(selectDataType?.value == "categorymonthly" || selectDataType?.value == "fooditemmonthly") ? "Month" : selectDataType?.label}
                                            </Label>
                                            {selectDataType == undefined &&
                                                <Select
                                                    className="w-100"
                                                    classNamePrefix="select2-selection"
                                                    placeholder="Choose..."
                                                    isDisabled={monthData.length != 0?false:true}
                                                />
                                            }
                                            {selectDataType?.value == "category" &&
                                                <Select
                                                    className="w-100"
                                                    classNamePrefix="select2-selection"
                                                    placeholder="Choose..."
                                                    isMulti={true}
                                                    options={categories}
                                                    onChange={(val) => handleItems(val)}
                                                />
                                            }
                                            {selectDataType?.value == "fooditem" &&
                                                <Select
                                                    className="w-100"
                                                    classNamePrefix="select2-selection"
                                                    placeholder="Choose..."
                                                    isMulti={true}
                                                    options={fooditems}
                                                    onChange={(val) => handleItems(val)}
                                                />
                                            }
                                            {(selectDataType?.value == "categorymonthly" || selectDataType?.value == "fooditemmonthly") &&
                                                <Select
                                                    className="w-100"
                                                    classNamePrefix="select2-selection"
                                                    placeholder="Choose..."
                                                    isMulti={true}
                                                    options={monthOptions}
                                                    onChange={(val) => handleItems(val)}
                                                />
                                            }
                                        </div>
                                    </Col>
                                    <Col lg="12" className="mt-2">
                                        <div className="chart-container">
                                            <div className="chart-wrapper">
                                                <ReactApexChart type="bar" width={"100%"} height={"500"} series={chartSeries}
                                                    options={chartOptions} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>

                                </Row>
                            </React.Fragment>
                    }

                </Container>
            </div>
        </React.Fragment>
    );
}

export default withRouter(FoodStatistics);