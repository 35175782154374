// @flow
import {
  CHANGE_SIDEBAR_TYPE,
  CHANGE_PRELOADER,
  TOGGLE_LEFTMENU,
} from "./actionTypes";

//constants
import {
  leftSidebarTypes,
} from "../../constants/layout";

const INIT_STATE = {
  leftSideBarType: leftSidebarTypes.DEFAULT,
  isPreloader: false,
  isMobile: false,
  leftMenu: false,
}
const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case CHANGE_PRELOADER:
      return {
        ...state,
        isPreloader: action.payload,
      }

    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      }
    case TOGGLE_LEFTMENU:
      return {
        ...state,
        leftMenu: action.payload,
      }

    default:
      return state
  }
}

export default Layout
