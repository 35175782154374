import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../components/Common/withRouter";
import TableContainer from "../components/Common/TableContainer";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    UncontrolledTooltip,
    Input,
    Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteModal from "../components/Common/DeleteModal";

import {
    getUser as onGetUsers,
    addNewUser as onAddNewUser,
    updateUser as onUpdateUser,
    deleteUser as onDeleteUser,
} from "../store/user/actions";
import { countBy, isEmpty } from "lodash";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import moment from 'moment';
import CategorySelect from "components/Common/CategorySelect";
import { CustomBadge } from "components/Common/ColumnStyle";
import CounterSelect from "components/Common/CounterSelect";
import RoleSelect from "components/Common/RoleSelect";

const User = props => {

    //meta title
    document.title = "Mythreya - Users";

    const dispatch = useDispatch();
    const [user, setUser] = useState();
    const [selectedRole, setSelectedRole] = useState(null)
    const [selectRole, setSelectRole] = useState(null);
    const [selectedCounter, setSelectedCounter] = useState(null)
    const [selectCounter, setSelectCounter] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const changeRole = (val) => {
        validation.setFieldValue("role", val)
        setSelectedRole(val)
    }
    const changeCounter = (val) => {
        validation.setFieldValue("counter", val)
        setSelectedCounter(val)
    }
    // validation
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: (user && user.name) || "",
            role: (user && user.role) || null,
            counter: (user && user.counter) || null,
            username:(user && user.username) || "",
            password:(user && user.password) || "",
            tableNoFrom:(user && user.tableNoFrom) || "",
            tableNoTo:(user && user.tableNoTo) || "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
            role: Yup.object().nullable().required("Please select a role"),
            counter: Yup.object().nullable().required("Please select a counter"),
            username: Yup.string().required("Please Enter User Name"),
            password: Yup.string().required("Please Enter Password"),
        }),
        onSubmit: values => {
            if (isEdit) {
                
                const updateUser = {
                    id: user.id,
                    name: values.name,
                    username:values.username,
                    password:values.password,
                    role: selectedRole.value,
                    counter: selectedCounter.value,
                    tableNoFrom:values.tableNoFrom,
                    tableNoTo:values.tableNoTo,
                };
                dispatch(onUpdateUser(updateUser));
                setIsEdit(false);
                validation.resetForm();
            } else {
                const newUser = {
                    name: values["name"],
                    username: values["username"],
                    password: values["password"],
                    role: selectedRole.value,
                    counter: selectedCounter.value,
                    tableNoFrom:values.tableNoFrom,
                    tableNoTo:values.tableNoTo
                };
                
                dispatch(onAddNewUser(newUser));
                setIsEdit(false);
                validation.resetForm();
            }
            toggle();
        },
    });

    const selectUsersState = (state) => state.users;
    const UsersProperties = createSelector(
        selectUsersState,
        (User) => ({
            users: User.users,
            loading: User.loading
        })
    );

    const {
        users, loading
    } = useSelector(UsersProperties);

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setLoading] = useState(loading)
    
    const columns = useMemo(
        () => [
            {
                Header: "#",
                // accessor: "name",
                disableFilters: true,
                filterable: true,
                accessor: (cellProps) => (
                    <>
                        {!cellProps.img ? (
                            <div className="avatar-xs">
                                <span className="avatar-title rounded-circle">
                                    {cellProps.name.charAt(0)}
                                </span>
                            </div>
                        ) : (
                            <div>
                                <img
                                    className="rounded-circle avatar-xs"
                                    src={cellProps.img}
                                    alt=""
                                />
                            </div>
                        )}
                    </>
                ),
            },
            {
                Header: "User Name",
                accessor: "username",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.username}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Name",
                accessor: "name",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.name}</Link>
                            </h5>
                        </>
                    )
                },
            },
           
            {
                Header: "Role",
                accessor: "role",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <span className="badge badge-soft-danger font-size-11 m-1" >{cellProps.row.original.role?.name}</span>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Counter",
                accessor: "counter",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <span className="badge badge-soft-danger font-size-11 m-1" >{cellProps.row.original.counter?.name}</span>
                            </h5>
                        </>
                    )
                },
            },
            
            
            {
                Header: "Created Date",
                accessor: "createdAt",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{moment(cellProps.row.original.createdAt).format('MMM D, YYYY')}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Action",
                Cell: cellProps => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-success"
                                onClick={() => {
                                    const userData = cellProps.row.original;
                                    handleUserClick(userData);
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const userData = cellProps.row.original;
                                    onClickDelete(userData);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                },
            },
        ],
        []
    );

    useEffect(() => {
        if (users && !users.length) {
            dispatch(onGetUsers());
            setIsEdit(false);
        }
    }, [dispatch, users]);

    useEffect(() => {
        setUser(users);
        setIsEdit(false);
    }, [users]);

    useEffect(() => {
        if (!isEmpty(users) && !!isEdit) {
            setUser(users);
            setIsEdit(false);
        }
    }, [users]);

    const toggle = () => {
        setModal(!modal);
    };

    const toggleReceipt = () => {
        setIsTable(prevReceipt => !prevReceipt)
    }

    const handleUserClick = arg => {
        const user = arg;
        setSelectRole(user.role)
        setSelectCounter(user.counter)
        let role = { label: user.role.name, value: user.role._id }
        let counter = { label: user.counter.name, value: user.counter._id }
        setSelectedRole(role)
        setSelectedCounter(counter)
        setUser({
            id: user.id,
            name: user.name,
            username:user.username,
            password:user.passwordtext,
            role: role,
            counter:counter,
            tableNoFrom:user.tableNoFrom,
            tableNoTo:user.tableNoTo,
        });

        validation.initialValues = {
            name: (user && user.name) || "",
            username: (user && user.username) || "",
            password: (user && user.password) || "",
            role: (user && user.role) || null,
            counter: (user && user.counter) || null,
            tableNoFrom: (user && user.tableNoFrom) || null,
            tableNoTo: (user && user.tableNoTo) || null,
        }
        setIsEdit(true);

        toggle();
    };

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    //delete customer
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = users => {
        setUser(users);
        setDeleteModal(true);
    };

    const handleDeleteUser = () => {
        if (user && user.id) {
            dispatch(onDeleteUser(user.id));
        }
        onPaginationPageChange(1);
        setDeleteModal(false);
    };

    const handleUserClicks = () => {
        setIsEdit(false);
        setSelectRole(null)
        setSelectedRole(null)
        setSelectCounter(null)
        setSelectedCounter(null)
        validation.resetForm();
        toggle();
    };

    const onClose = () => {
        setUser({})
        validation.resetForm();
        setIsEdit(false);
        setSelectRole(null)
        setSelectedRole(null)
        setSelectCounter(null)
        setSelectedCounter(null)
    }

    const keyField = "id";
    
    return (
        loading ? <Spinners setLoading={setLoading} />
            :
            <React.Fragment>
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteUser}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs breadcrumbItem="User" />
                        <Row>
                            {
                                isLoading ? <Spinners setLoading={setLoading} />
                                    :
                                    <Col lg="12">
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    isPagination={true}
                                                    columns={columns}
                                                    data={users}
                                                    isGlobalFilter={true}
                                                    isShowingPageLength={true}
                                                    buttonText={"Create User"}
                                                    isAddUserList={true}
                                                    iscustomPageSizeOptions={true}
                                                    handleUserClick={handleUserClicks}
                                                    customPageSize={10}
                                                    tableClass="table align-middle table-nowrap table-hover"
                                                    theadClass="table-light"
                                                    paginationDiv="col-sm-12 col-md-7"
                                                    pagination="pagination pagination-rounded justify-content-end mt-4"
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                            }

                            <Modal isOpen={modal} onClosed={onClose} toggle={toggle}>
                                <ModalHeader toggle={toggle} user="h4">
                                    {!!isEdit ? "Edit User" : "Add User"}
                                </ModalHeader>
                                <ModalBody>
                                    <Form
                                        onSubmit={e => {
                                            e.preventDefault();
                                            setIsSubmitted(true)
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row>
                                            <Col xs={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Name</Label>
                                                    <Input
                                                        name="name"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.name || ""}
                                                        invalid={
                                                            validation.touched.name &&
                                                                validation.errors.name
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.name &&
                                                        validation.errors.name ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.name}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label">User Name</Label>
                                                    <Input
                                                        name="username"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.username || ""}
                                                        invalid={
                                                            validation.touched.username &&
                                                                validation.errors.username
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.username &&
                                                        validation.errors.username ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.username}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label">Password</Label>
                                                    <Input
                                                        name="password"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.password || ""}
                                                        invalid={
                                                            validation.touched.password &&
                                                                validation.errors.password
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.password &&
                                                        validation.errors.password ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.password}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="row">
                                                <div className="mb-3 col-6">
                                                    <Label className="form-label">Table No From</Label>
                                                    <Input
                                                        name="Table No From"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.tableNoFrom || ""}
                                                        invalid={
                                                            validation.touched.tableNoFrom &&
                                                                validation.errors.tableNoFrom
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.tableNoFrom &&
                                                        validation.errors.tableNoFrom ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.tableNoFrom}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3 col-6">
                                                    <Label className="form-label">Table No To</Label>
                                                    <Input
                                                        name="Table No To"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.tableNoTo || ""}
                                                        invalid={
                                                            validation.touched.tableNoTo &&
                                                                validation.errors.tableNoTo
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.tableNoTo &&
                                                        validation.errors.tableNoTo ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.tableNoTo}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                                </div>
                                                <div className="mb-3">
                                                    <Label>Select Role</Label>
                                                    <RoleSelect isSubmitted={isSubmitted} error={validation.errors.role} defaultValue={selectRole?._id} OnSelect={changeRole}></RoleSelect>
                                                </div>
                                                <div className="mb-3">
                                                    <Label>Select Counter</Label>
                                                    <CounterSelect isMulti={false} isSubmitted={isSubmitted} error={validation.errors.counter} defaultValue={selectCounter} OnSelect={changeCounter}></CounterSelect>
                                                </div>
                                                
                                            </Col>
                                            
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="text-end">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-success save-user"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </ModalBody>
                            </Modal>
                        </Row>
                    </Container>
                </div>
                <ToastContainer />
            </React.Fragment>
    );
};

export default withRouter(User);
