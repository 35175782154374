import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { ADD_NEW_SLIDE, DELETE_SLIDE, GET_SLIDES, UPDATE_SLIDE, GET_SLIDESCOMBO  } from "./actionTypes"

import {
  getSlidesSuccess,
  getSlidesFail,
  addSlideFail,
  addSlideSuccess,
  updateSlideSuccess,
  updateSlideFail,
  deleteSlideSuccess,
  deleteSlideFail,
  getSlideComboSuccess,
  getSlideComboFail
} from "./actions"

//Include Both Helper File with needed methods
import { getSlide, addSlide, updateSlide, deleteSlide, getSlideCombo } from "../../helpers/slide_helper"

// toast
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';



function* fetchSlide() {
  try {
    const response = yield call(getSlide)
    yield put(getSlidesSuccess(response.slides))
  } catch (error) {
    yield put(getSlidesFail(error))
  }
}

function* fetchSlideCombo() {
  try {
    const response = yield call(getSlideCombo)
    yield put(getSlideComboSuccess(response.slides))
  } catch (error) {
    yield put(getSlideComboFail(error))
  }
}

function* onUpdateSlide({ payload: slide }) {
  try {
    const response = yield call(updateSlide, slide)
    response.slide.image = response.slide.image.path;
    response.slide.id = response.slide._id;
    yield put(updateSlideSuccess(response.slide))
    toast.success("Updated Successfully", { autoClose: 2000 });
  } catch (error) {
    console.log(error)
    yield put(updateSlideFail(error))
    toast.error("Updated Failed", { autoClose: 2000 });
  }
}

function* onDeleteSlide({ payload: slide }) {
  try {
    const response = yield call(deleteSlide, slide)
    
    yield put(deleteSlideSuccess(response.slide._id))
    toast.success("Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteSlideFail(error))
    toast.error("Deleted Failed", { autoClose: 2000 });
  }
}

function* onAddSlide({ payload: user }) {
  try {
    const response = yield call(addSlide, user)
    response.slide.image = response.slide.image.path;
    response.slide.id = response.slide._id;
    yield put(addSlideSuccess(response.slide))
    toast.success("Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addSlideFail(error))
    toast.error("Added Failed", { autoClose: 2000 });
  }
}

function* slideSaga() {
  yield takeEvery(GET_SLIDES, fetchSlide)
  yield takeEvery(ADD_NEW_SLIDE, onAddSlide)
  yield takeEvery(UPDATE_SLIDE, onUpdateSlide)
  yield takeEvery(DELETE_SLIDE, onDeleteSlide)
  yield takeEvery(GET_SLIDESCOMBO, fetchSlideCombo)
}

export default slideSaga;
