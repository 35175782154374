/* USERS */
export const GET_SLIDES = "GET_SLIDES"
export const GET_SLIDES_SUCCESS = "GET_SLIDES_SUCCESS"
export const GET_SLIDES_FAIL = "GET_SLIDES_FAIL"

/* CATEGORS PROFILE */
export const GET_SLIDE = "GET_SLIDE"
export const GET_SLIDE_SUCCESS = "GET_SLIDE_SUCCESS"
export const GET_SLIDE_FAIL = "GET_SLIDE_FAIL"

/**
 * add user
 */
export const ADD_NEW_SLIDE = "ADD_NEW_SLIDE"
export const ADD_SLIDE_SUCCESS = "ADD_SLIDE_SUCCESS"
export const ADD_SLIDE_FAIL = "ADD_SLIDE_FAIL"

/**
 * Edit user
 */
export const UPDATE_SLIDE = "UPDATE_SLIDE"
export const UPDATE_SLIDE_SUCCESS = "UPDATE_SLIDE_SUCCESS"
export const UPDATE_SLIDE_FAIL = "UPDATE_SLIDE_FAIL"

/**
 * Delete user
 */
export const DELETE_SLIDE = "DELETE_SLIDE"
export const DELETE_SLIDE_SUCCESS = "DELETE_SLIDE_SUCCESS"
export const DELETE_SLIDE_FAIL = "DELETE_SLIDE_FAIL"

export const GET_SLIDESCOMBO = "GET_SLIDESCOMBO"
export const GET_SLIDESCOMBO_FAIL = "GET_SLIDESCOMBO_FAIL"
export const GET_SLIDESCOMBO_SUCCESS = "GET_SLIDESCOMBO_SUCCESS"
