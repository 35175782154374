import {
  GET_HOME,
  GET_HOME_SUCCESS,
  GET_HOME_FAIL,
  ADD_NEW_HOME,
  ADD_HOME_SUCCESS,
  ADD_HOME_FAIL,
  UPDATE_HOME_SUCCESS,
  UPDATE_HOME_FAIL,
  DELETE_HOME,
  DELETE_HOME_SUCCESS,
  DELETE_HOME_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  home: {},
  error: {},
  loading: false
}

const homes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_HOME:
      return {
        ...state,
        loading: true
      }
      break
    case GET_HOME_SUCCESS:
      return {
        ...state,
        home: action.payload,
        loading: false
      }
      break
    case GET_HOME_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case ADD_NEW_HOME:
      return {
        ...state,
        loading: true
      }
      break
    case ADD_HOME_SUCCESS:
      return {
        ...state,
        home: action.payload,
        loading: false
      }
      break
    case ADD_HOME_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case GET_HOME_SUCCESS:
      return {
        ...state,
        home: action.payload,
      }
      break
    case UPDATE_HOME_SUCCESS:
      return {
        ...state,
        home: state.home.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
      }
      break
    case UPDATE_HOME_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      break
    case DELETE_HOME_SUCCESS:
      return {
        ...state,
        home: state.home.filter(
          user => user.id.toString() !== action.payload.toString()
        ),
        loading: false
      }
      break
    case DELETE_HOME: {
      return {
        ...state,
        loading: true
      }
    }
      break
    case DELETE_HOME_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case GET_HOME_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      break
    default:
      return state
  }
}

export default homes
