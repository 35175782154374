import {
  GET_STORE_CATEGORYS,
  GET_STORE_CATEGORYS_SUCCESS,
  GET_STORE_CATEGORYS_FAIL,
  NEW_STORE_CATEGORY,
  NEW_STORE_CATEGORY_SUCCESS,
  NEW_STORE_CATEGORY_FAIL,
  UPDATE_STORE_CATEGORY,
  UPDATE_STORE_CATEGORY_SUCCESS,
  UPDATE_STORE_CATEGORY_FAIL,
  DELETE_STORE_CATEGORY,
  DELETE_STORE_CATEGORY_SUCCESS,
  DELETE_STORE_CATEGORY_FAIL,
  GET_STORE_CATEGORY,
  GET_STORE_CATEGORY_SUCCESS,
  GET_STORE_CATEGORY_FAIL,
  GET_STORE_CATEGORYSCOMBO_SUCCESS,
  GET_STORE_CATEGORYSCOMBO_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  storeCategory: [],
  storeCategoryCombo: [],
  error: {},
  loading: false
}

const storeCategorys = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STORE_CATEGORYSCOMBO_SUCCESS:
      return {
        ...state,
        storeCategoryCombo: action.payload,
        loading: true
      }
      break
    case GET_STORE_CATEGORYSCOMBO_FAIL:
      return {
        ...state,
        error: action.payload,
        
      }
      break
    case GET_STORE_CATEGORYS:
      return {
        ...state,
        loading: true
      }
      break
    case GET_STORE_CATEGORYS_SUCCESS:
      return {
        ...state,
        storeCategory: action.payload,
        loading: false
      }
      break
    case GET_STORE_CATEGORYS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case NEW_STORE_CATEGORY:
      return {
        ...state,
        loading: true
      }
      break
    case NEW_STORE_CATEGORY_SUCCESS:

      return {
        ...state,
        storeCategory: [...state.storeCategory, action.payload],
        loading: false
      }
      break
    case NEW_STORE_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case UPDATE_STORE_CATEGORY:
      return {
        ...state,
        loading: true
      }
      break
    case GET_STORE_CATEGORY_SUCCESS:
      return {
        ...state,
        storeCategory: action.payload,
        loading: false
      }
      break
    case UPDATE_STORE_CATEGORY_SUCCESS:
      return {
        ...state,
        storeCategory: state.storeCategory.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
        loading: false
      }
      break
    case UPDATE_STORE_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case DELETE_STORE_CATEGORY:
      return {
        ...state,
        loading: true
      }
      break
    case DELETE_STORE_CATEGORY_SUCCESS:
      return {
        ...state,
        storeCategory: state.storeCategory.filter(
          user => user.id.toString() !== action.payload.toString()
        ),
        loading: false
      }
      break
    case DELETE_STORE_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case GET_STORE_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    default:
      return state
  }
}

export default storeCategorys
