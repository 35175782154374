/* USERS */
export const GET_HOME = "GET_HOME"
export const GET_HOME_SUCCESS = "GET_HOME_SUCCESS"
export const GET_HOME_FAIL = "GET_HOME_FAIL"

/**
 * add user
 */
export const ADD_NEW_HOME = "ADD_NEW_HOME"
export const ADD_HOME_SUCCESS = "ADD_HOME_SUCCESS"
export const ADD_HOME_FAIL = "ADD_HOME_FAIL"

/**
 * Edit user
 */
export const UPDATE_HOME = "UPDATE_HOME"
export const UPDATE_HOME_SUCCESS = "UPDATE_HOME_SUCCESS"
export const UPDATE_HOME_FAIL = "UPDATE_HOME_FAIL"

/**
 * Delete user
 */
export const DELETE_HOME = "DELETE_HOME"
export const DELETE_HOME_SUCCESS = "DELETE_HOME_SUCCESS"
export const DELETE_HOME_FAIL = "DELETE_HOME_FAIL"


