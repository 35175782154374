import React, { useEffect, useState } from "react";
import { Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Select from 'react-select';
import { useFormik } from "formik";
import * as Yup from "yup";
import { createCoupanCode, getCoupanCode } from "helpers/orders_helper";
import axios from "axios";


const CoupanPopup = ({ modal, setModal, order, setLoading, loading }) => {

    const [selectMinimum, setselectMinimum] = useState(null);
    const [selectDiscount, setSelectDiscount] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [printText, setPrintText] = useState("");
    const [ip, setIp] = useState("");
    const [selectedType, setSelectedType] = useState("Food");

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            amount: "",
            discount: ""
        },
        validationSchema: Yup.object({
            amount: Yup.string().required("Please select minimum amount"),
            discount: Yup.string().required("Please select discount %"),
        }),
        onSubmit: async (values) => {
            setLoading(true)
            await createCoupanCode({ id: order.id, type:selectedType, minimum: selectMinimum.value, discount: selectDiscount.value }).then((data) => {
                setLoading(false)
                setselectMinimum(null)
                setSelectDiscount(null)
                setSelectedType("Food")
                validation.resetForm();
                setSelectedOrder(data.coupan)
                setIp(data.ip)
                setPrintText(data.printString)
                // printCoupan(data.printString, "Discount Coupan", data.ip)
            }).catch((error) => {
                setLoading(false)
            })

        }
    });

    useEffect(() => {
        const fetchCoupan = async () => {
            if (order?.id) {
                setLoading(true)
                await getCoupanCode(order.id).then((data) => {
                    setLoading(false)
                    if (data) {
                        setSelectedOrder(data.coupan)
                        setIp(data.ip)
                        setPrintText(data.printString)
                    }

                }).catch((error) => {
                    setLoading(false)
                })
            }
            console.log(order?.id)

        }
        if (modal) {
            fetchCoupan();
        }
    }, [modal, order])

    const toggle = () => {
        setModal(!modal);
    };

    const printCoupan = () => {
        console.log(printText)
        let text = printText.replace("[C]<img>https://api.mythreyarestaurant.com/print/printlogo.bmp</img>\n[C]<font size='big'>Mythreya</font>\n[C]<font size='big'>Multicuisine Restuarent</font>\n[C]Mallaya Doddi,Opp. HP Petrol Bunk, Ravulapadu (V)\n[C] Ravulapalem - 533238. Ph : +91 6300585325 \n", "");
        axios.post("http://localhost:8080/printer-service/print", { data: text, copy: "Discount Coupan", ip: ip }).then((response) => {
            console.log(response)
        }).catch(error => {
            console.log(error)
        })
    }

    const amounts = [
        { label: "500", value: "500" },
        { label: "1000", value: "1000" },
        { label: "1500", value: "1500" },
    ]
    const discount = [
        { label: "5%", value: "5" },
        { label: "10%", value: "10" },
        { label: "15%", value: "15" },
        { label: "20%", value: "20" },
        { label: "25%", value: "25" },
    ]

    const times = [
        { label: "1 Time", value: "1" },
        { label: "2 Time's", value: "2" },
        { label: "5 Time's", value: "5" },
        { label: "10 Time's", value: "10" },
        { label: "12 Time's", value: "12" },
    ]
    const kids = [
        { label: "1 Kid", value: "1" },
        { label: "2 Kids", value: "2" },
        { label: "3 Kids", value: "3" },
        { label: "4 Kids", value: "4" },
        { label: "5 Kids", value: "5" },
    ]
    const handleSelect = (val) => {
        validation.setFieldValue("amount", val.value)
        setselectMinimum(val)
    }
    const handleDiscount = (val) => {
        validation.setFieldValue("discount", val.value)
        setSelectDiscount(val)
    }

    const handleRadioChange = (option) => {
        setSelectedType(option);
    }
    return (
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
                Get Offer Coupan
            </ModalHeader>
            <ModalBody className="pt-0">
                {(!selectedOrder ?
                    <React.Fragment>
                        <Row>
                            <Col sm={12} className="d-flex flex-row justify-content-center mb-3">
                                <FormGroup check className="me-4">
                                    <Label className="h5">
                                        <Input
                                            type="radio"
                                            name="radioGroup"
                                            checked={selectedType === "Food"}
                                            onChange={() => handleRadioChange("Food")}
                                        />
                                        Food Coupan
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label className="h5">
                                        <Input
                                            type="radio"
                                            name="radioGroup"
                                            checked={selectedType === "PlayZone"}
                                            onChange={() => handleRadioChange("PlayZone")}
                                        />
                                        PlayZone Coupan
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        {selectedType === "Food" ?
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}>
                                <Row>
                                    <Col sm={6}>
                                        <div className="mb-3">
                                            <Label>Minimum Amount</Label>
                                            <Select
                                                classNamePrefix="select2-selection"
                                                className="w-100"
                                                placeholder="Choose..."
                                                title="Duration"
                                                options={amounts}
                                                isSearchable={false}
                                                value={selectMinimum}
                                                onChange={(val) => handleSelect(val)}
                                            />
                                            {validation.touched.amount &&
                                                validation.errors.amount ? (
                                                <FormFeedback type="invalid" className="d-block">
                                                    {validation.errors.amount}
                                                </FormFeedback>
                                            ) : null}
                                        </div>

                                    </Col>
                                    <Col sm={6}>
                                        <div className="mb-3">
                                            <Label>Coupan Disount %</Label>
                                            <Select
                                                classNamePrefix="select2-selection"
                                                className="w-100"
                                                placeholder="Choose..."
                                                title="Duration"
                                                options={discount}
                                                isSearchable={false}
                                                value={selectDiscount}
                                                onChange={(val) => handleDiscount(val)}
                                            />
                                            {validation.touched.discount &&
                                                validation.errors.discount ? (
                                                <FormFeedback type="invalid" className="d-block">
                                                    {validation.errors.discount}
                                                </FormFeedback>
                                            ) : null}
                                        </div>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="d-flex justify-content-center">
                                            <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                            >
                                                GET COUPAN
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form> :
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}>
                                <Row>
                                    <Col sm={6}>
                                        <div className="mb-3">
                                            <Label>Minimum Kids Allowed</Label>
                                            <Select
                                                classNamePrefix="select2-selection"
                                                className="w-100"
                                                placeholder="Choose..."
                                                title="Duration"
                                                options={kids}
                                                isSearchable={false}
                                                value={selectMinimum}
                                                onChange={(val) => handleSelect(val)}
                                            />
                                            {validation.touched.amount &&
                                                validation.errors.amount ? (
                                                <FormFeedback type="invalid" className="d-block">
                                                    {validation.errors.amount}
                                                </FormFeedback>
                                            ) : null}
                                        </div>

                                    </Col>
                                    <Col sm={6}>
                                        <div className="mb-3">
                                            <Label>No. of Times</Label>
                                            <Select
                                                classNamePrefix="select2-selection"
                                                className="w-100"
                                                placeholder="Choose..."
                                                title="Duration"
                                                options={times}
                                                isSearchable={false}
                                                value={selectDiscount}
                                                onChange={(val) => handleDiscount(val)}
                                            />
                                            {validation.touched.discount &&
                                                validation.errors.discount ? (
                                                <FormFeedback type="invalid" className="d-block">
                                                    {validation.errors.discount}
                                                </FormFeedback>
                                            ) : null}
                                        </div>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="d-flex justify-content-center">
                                            <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                            >
                                                GET COUPAN
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        }
                    </React.Fragment>
                    : <Row>
                        <Col sm={12} className="d-flex flex-column align-items-center">
                            <p className="m-0">Coupan Code</p>
                            <h1 className="m-0">{selectedOrder.code}</h1>
                            {selectedOrder.type == "Food"?
                                <p className="mt-2 h5 text-center">Offer : {selectedOrder.discount}% Discount<br />Minimum order amount Rs.{selectedOrder.minimum.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</p>
                                :<p className="mt-2 h5 text-center">Playzone For {selectedOrder.discount} Times <br />Only {selectedOrder.minimum} kids allowed </p>
                            }
                            <button onClick={() => printCoupan()}
                                className="btn btn-primary save-user"
                            >
                                Print Coupan
                            </button>
                        </Col>
                    </Row>)}
            </ModalBody>
        </Modal>
    )
}


export default CoupanPopup;