import {
  GET_ROLES,
  GET_ROLES_FAIL,
  GET_ROLES_SUCCESS,
  GET_ROLESCOMBO,
  GET_ROLESCOMBO_FAIL,
  GET_ROLESCOMBO_SUCCESS,
  ADD_NEW_ROLE,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAIL,
  UPDATE_ROLE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAIL,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
} from "./actionTypes"

export const getRoles = () => ({
  type: GET_ROLES,
})

export const getRolesSuccess = tag => ({
  type: GET_ROLES_SUCCESS,
  payload: tag,
})

export const addNewRole = tag => ({
  type: ADD_NEW_ROLE,
  payload: tag,
})

export const addRoleSuccess = tag => ({
  type: ADD_ROLE_SUCCESS,
  payload: tag,
})

export const addRoleFail = error => ({
  type: ADD_ROLE_FAIL,
  payload: error,
})

export const getRolesFail = error => ({
  type: GET_ROLES_FAIL,
  payload: error,
})

export const updateRole = tag => ({
  type: UPDATE_ROLE,
  payload: tag,
})

export const updateRoleSuccess = tag => ({
  type: UPDATE_ROLE_SUCCESS,
  payload: tag,
})

export const updateRoleFail = error => ({
  type: UPDATE_ROLE_FAIL,
  payload: error,
})

export const deleteRole = tag => ({
  type: DELETE_ROLE,
  payload: tag,
})

export const deleteRoleSuccess = tag => ({
  type: DELETE_ROLE_SUCCESS,
  payload: tag,
})

export const deleteRoleFail = error => ({
  type: DELETE_ROLE_FAIL,
  payload: error,
})
export const getRoleCombo = () => ({
  type: GET_ROLESCOMBO,
})

export const getRolesComboSuccess = category => ({
  type: GET_ROLESCOMBO_SUCCESS,
  payload: category,
})

export const getRolesComboFail = error => ({
  type: GET_ROLESCOMBO_FAIL,
  payload: error,
})