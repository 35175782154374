/* USERS */
export const GET_ROLES = "GET_ROLES"
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS"
export const GET_ROLES_FAIL = "GET_ROLES_FAIL"

/* CATEGORS PROFILE */
export const GET_ROLE = "GET_ROLE"
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS"
export const GET_ROLE_FAIL = "GET_ROLE_FAIL"

/**
 * add user
 */
export const ADD_NEW_ROLE = "ADD_NEW_ROLE"
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS"
export const ADD_ROLE_FAIL = "ADD_ROLE_FAIL"

/**
 * Edit user
 */
export const UPDATE_ROLE = "UPDATE_ROLE"
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS"
export const UPDATE_ROLE_FAIL = "UPDATE_ROLE_FAIL"

/**
 * Delete user
 */
export const DELETE_ROLE = "DELETE_ROLE"
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS"
export const DELETE_ROLE_FAIL = "DELETE_ROLE_FAIL"

export const GET_ROLESCOMBO = "GET_ROLESCOMBO"
export const GET_ROLESCOMBO_FAIL = "GET_ROLESCOMBO_FAIL"
export const GET_ROLESCOMBO_SUCCESS = "GET_ROLESCOMBO_SUCCESS"
