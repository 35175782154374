import {
    GET_ORDERS,
    GET_ORDERS_SUCCESS,
    GET_ORDERS_FAIL,
    GET_ORDER_UPDATE,
    GET_ORDER_UPDATE_SUCCESS,
    GET_ORDER_UPDATE_FAIL,
    GET_SETTLED_ORDER_UPDATE,
    GET_SETTLED_ORDER_UPDATE_SUCCESS,
    GET_SETTLED_ORDER_UPDATE_FAIL,
    GET_ORDER_ITEMS_UPDATE,
    GET_ORDER_UPDATE_ITEMS_FAIL,
    GET_ORDER_UPDATE_ITEMS_SUCCESS,
    GET_CANCELLED_ORDER_ITMES_FAIL,
    GET_CANCELLED_ORDER_ITMES_SUCCESS,
    GET_CANCELLED_ORDER_ITMES
} from "./actionTypes"

const INIT_STATE = {
    orders: [],
    settledOrders: [],
    cancelldOrders: [],
    error: {},
    loading: false
}

const orders = (state = INIT_STATE, action) => {
    switch (action.type) {

        case GET_ORDERS:
            return {
                ...state,
                loading: true
            }
            break
        case GET_ORDERS_SUCCESS:
            return {
                ...state,
                orders: action.payload,
                loading: false
            }
            break
        case GET_ORDERS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
            break
        case GET_ORDER_UPDATE:
            return {
                ...state,
                loading: true
            }
            break
        case GET_ORDER_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                orders: state.orders.filter(item => item.id != action.payload.id)
            }
            break
        case GET_ORDER_UPDATE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
            break
        case GET_ORDER_ITEMS_UPDATE:
            return {
                ...state,
                loading: true
            }
            break
        case GET_ORDER_UPDATE_ITEMS_SUCCESS:
            return {
                ...state,
                loading: false,
                orders: state.orders.map((item) => {
                    if (action.payload.id == item.id)
                        return action.payload;
                    else
                        return item;
                })
            }
            break
        case GET_ORDER_UPDATE_ITEMS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
            break
        case GET_SETTLED_ORDER_UPDATE:
            return {
                ...state,
                loading: true
            }
            break
        case GET_SETTLED_ORDER_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                settledOrders: action.payload
            }
            break
        case GET_SETTLED_ORDER_UPDATE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
            break
        case GET_CANCELLED_ORDER_ITMES:
            return {
                ...state,
                loading: true
            }
            break
        case GET_CANCELLED_ORDER_ITMES_SUCCESS:
            return {
                ...state,
                cancelldOrders: action.payload,
                loading: false
            }
            break
        case GET_CANCELLED_ORDER_ITMES_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
            break
        default:
            return state
    }
}

export default orders
