import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { ADD_NEW_USER, DELETE_USER, GET_USER, GET_USER_BY_ID, UPDATE_USER } from "./actionTypes"

import {
  getUserSuccess,
  getUserFail,
  addUserFail,
  addUserSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  getUserByIdSuccess,
  getUserByIdFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getUser, addUser, updateUser, deleteUser, getUserById } from "../../helpers/user_helper"

// toast
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';



function* fetchUser() {
  try {
    const response = yield call(getUser)
    yield put(getUserSuccess(response.users))
  } catch (error) {
    yield put(getUserFail(error))
  }
}

function* fetchUserById({ payload: id }) {
  try {
    if (id) {
      const response = yield call(getUserById, id)
      response.user.id = response.user._id;
      yield put(getUserByIdSuccess(response.user))
    } else {
      yield put(getUserByIdSuccess({}))
    }
  } catch (error) {
    yield put(getUserByIdFail(error))
  }
}

function* onUpdateUser({ payload: user }) {
  try {
    const response = yield call(updateUser, user)
    response.user.id = response.user._id;
    yield put(updateUserSuccess(response.user))
    toast.success("Updated Successfully", { autoClose: 2000 });
  } catch (error) {
    console.log(error)
    yield put(updateUserFail(error))
    toast.error("Updated Failed", { autoClose: 2000 });
  }
}

function* onDeleteUser({ payload: user }) {
  try {
    const response = yield call(deleteUser, user)

    yield put(deleteUserSuccess(response.user.id))
    toast.success("Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    console.log(error)
    yield put(deleteUserFail(error))
    toast.error("Deleted Failed", { autoClose: 2000 });
  }
}

function* onAddUser({ payload: user }) {
  try {
    const response = yield call(addUser, user)
    response.user.id = response.user._id;
    yield put(addUserSuccess(response.user))
    toast.success("Added Successfully", { autoClose: 2000 });
  } catch (error) {
    console.log(error)
    yield put(addUserFail(error))
    toast.error("Added Failed", { autoClose: 2000 });
  }
}

function* userSaga() {
  yield takeEvery(GET_USER, fetchUser)
  yield takeEvery(ADD_NEW_USER, onAddUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
  yield takeEvery(GET_USER_BY_ID, fetchUserById)
}

export default userSaga;
