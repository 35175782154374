import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from "moment";
import { Badge } from 'reactstrap';
import { size, map } from "lodash";

const CustomBadge = (cell) => {
    return (
        <>
            {map(
                cell.value,
                (tag, index) =>
                    index < 2 && (
                        <Link
                            to="#"
                            className="badge badge-soft-danger font-size-11 m-1"
                            key={"_skill_" + cell.value + index}
                        >
                            {tag.name}
                        </Link>

                    )
            )}
        </>
    );
};
export { CustomBadge }