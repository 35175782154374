import axios from "axios";
import { del, get, post, put,postWithMulti, putWithMulti } from "./api_helper";
import * as url from "./url_helper";

export const getStory = () => get(url.GET_STORY + "/StoriesWithChapters");
export const getStoryById = story => get(url.GET_STORY+ "/" + story);
export const addStory = story => postWithMulti(url.GET_STORY, story);
export const updateStory = story => putWithMulti(url.GET_STORY + "/" + story.data.id, story.data.formData);
export const deleteStory = story => del(url.GET_STORY + "/" + story);
export const deleteStoryImage = story => post(url.GET_STORY + "/deleteImage/", story);
