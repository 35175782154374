import {
  GET_FOOD_ITEMS,
  GET_FOOD_ITEMS_FAIL,
  GET_FOOD_ITEMS_SUCCESS,
  GET_FOOD_ITEMSCOMBO,
  GET_FOOD_ITEMSCOMBO_FAIL,
  GET_FOOD_ITEMSCOMBO_SUCCESS,
  ADD_NEW_FOOD_ITEM,
  ADD_FOOD_ITEM_SUCCESS,
  ADD_FOOD_ITEM_FAIL,
  UPDATE_FOOD_ITEM,
  UPDATE_FOOD_ITEM_SUCCESS,
  UPDATE_FOOD_ITEM_FAIL,
  DELETE_FOOD_ITEM,
  DELETE_FOOD_ITEM_SUCCESS,
  DELETE_FOOD_ITEM_FAIL,
  UPDATE_STATUS_FOOD_ITEM,
  UPDATE_STATUS_FOOD_ITEM_SUCCESS,
  UPDATE_STATUS_FOOD_ITEM_FAIL,
  GET_CHART_DATA,
  GET_CHART_DATA_SUCCESS,
  GET_CHART_DATA_FAIL
} from "./actionTypes"

export const getFoodItems = () => ({
  type: GET_FOOD_ITEMS,
})

export const getFoodItemsSuccess = fooditem => ({
  type: GET_FOOD_ITEMS_SUCCESS,
  payload: fooditem,
})

export const addNewFoodItem = fooditem => ({
  type: ADD_NEW_FOOD_ITEM,
  payload: fooditem,
})

export const addFoodItemSuccess = fooditem => ({
  type: ADD_FOOD_ITEM_SUCCESS,
  payload: fooditem,
})

export const addFoodItemFail = error => ({
  type: ADD_FOOD_ITEM_FAIL,
  payload: error,
})

export const getFoodItemsFail = error => ({
  type: GET_FOOD_ITEMS_FAIL,
  payload: error,
})

export const updateFoodItem = fooditem => ({
  type: UPDATE_FOOD_ITEM,
  payload: fooditem,
})

export const updateFoodItemSuccess = fooditem => ({
  type: UPDATE_FOOD_ITEM_SUCCESS,
  payload: fooditem,
})

export const updateFoodItemFail = error => ({
  type: UPDATE_FOOD_ITEM_FAIL,
  payload: error,
})

export const deleteFoodItem = fooditem => ({
  type: DELETE_FOOD_ITEM,
  payload: fooditem,
})

export const deleteFoodItemSuccess = fooditem => ({
  type: DELETE_FOOD_ITEM_SUCCESS,
  payload: fooditem,
})

export const deleteFoodItemFail = error => ({
  type: DELETE_FOOD_ITEM_FAIL,
  payload: error,
})
export const getFoodItemCombo = () => ({
  type: GET_FOOD_ITEMSCOMBO,
})

export const getFoodItemsComboSuccess = fooditem => ({
  type: GET_FOOD_ITEMSCOMBO_SUCCESS,
  payload: fooditem,
})

export const getFoodItemsComboFail = error => ({
  type: GET_FOOD_ITEMSCOMBO_FAIL,
  payload: error,
})

export const updateStatusFoodItem = category => ({
  type: UPDATE_STATUS_FOOD_ITEM,
  payload: category,
})

export const updateStatusFoodItemSuccess = category => ({
  type: UPDATE_STATUS_FOOD_ITEM_SUCCESS,
  payload: category,
})

export const updateStatusFoodItemFail = error => ({
  type: UPDATE_STATUS_FOOD_ITEM_FAIL,
  payload: error,
})

export const getChartData = category => ({
  type: GET_CHART_DATA,
  payload: category,
})

export const getChartDataSuccess = category => ({
  type: GET_CHART_DATA_SUCCESS,
  payload: category,
})

export const getChartDataFail = error => ({
  type: GET_CHART_DATA_FAIL,
  payload: error,
})
