import {
  GET_COUNTERS,
  GET_COUNTERS_FAIL,
  GET_COUNTERS_SUCCESS,
  GET_COUNTERSCOMBO,
  GET_COUNTERSCOMBO_FAIL,
  GET_COUNTERSCOMBO_SUCCESS,
  ADD_NEW_COUNTER,
  ADD_COUNTER_SUCCESS,
  ADD_COUNTER_FAIL,
  UPDATE_COUNTER,
  UPDATE_COUNTER_SUCCESS,
  UPDATE_COUNTER_FAIL,
  DELETE_COUNTER,
  DELETE_COUNTER_SUCCESS,
  DELETE_COUNTER_FAIL,
} from "./actionTypes"

export const getCounters = () => ({
  type: GET_COUNTERS,
})

export const getCountersSuccess = tag => ({
  type: GET_COUNTERS_SUCCESS,
  payload: tag,
})

export const addNewCounter = tag => ({
  type: ADD_NEW_COUNTER,
  payload: tag,
})

export const addCounterSuccess = tag => ({
  type: ADD_COUNTER_SUCCESS,
  payload: tag,
})

export const addCounterFail = error => ({
  type: ADD_COUNTER_FAIL,
  payload: error,
})

export const getCountersFail = error => ({
  type: GET_COUNTERS_FAIL,
  payload: error,
})

export const updateCounter = tag => ({
  type: UPDATE_COUNTER,
  payload: tag,
})

export const updateCounterSuccess = tag => ({
  type: UPDATE_COUNTER_SUCCESS,
  payload: tag,
})

export const updateCounterFail = error => ({
  type: UPDATE_COUNTER_FAIL,
  payload: error,
})

export const deleteCounter = tag => ({
  type: DELETE_COUNTER,
  payload: tag,
})

export const deleteCounterSuccess = tag => ({
  type: DELETE_COUNTER_SUCCESS,
  payload: tag,
})

export const deleteCounterFail = error => ({
  type: DELETE_COUNTER_FAIL,
  payload: error,
})
export const getCounterCombo = () => ({
  type: GET_COUNTERSCOMBO,
})

export const getCountersComboSuccess = category => ({
  type: GET_COUNTERSCOMBO_SUCCESS,
  payload: category,
})

export const getCountersComboFail = error => ({
  type: GET_COUNTERSCOMBO_FAIL,
  payload: error,
})