import React, { useEffect, useState, useMemo } from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getOrders as onGetOrders } from "store/actions";

import SubscriptionModel from "../SubscriptionModel";
import { latestTransaction } from "../../../common/dashboard";
import TableContainer from "../../../components/Common/TableContainer";
import {
    getSubscriber as onGetSubscriber,
} from "../../../store/subscriber/actions";

import {
    OrderId,
    BillingName,
    Date,
    Total,
    PaymentStatus,
    PaymentMethod,
} from "../../Subscription/SubscriptionCol";
import { createSelector } from "reselect";
import { useSelector, useDispatch } from "react-redux";

const SubscriptionList = props => {
    const dispatch = useDispatch();
    const [modal1, setModal1] = useState(false);
    const [subscriber, setSubscriber] = useState(null);

    const toggleViewModal = (subscriber) => {
        setModal1(!modal1);
        setSubscriber(subscriber)
    };
    const selectSubscriberState = (state) => state.subscribers;
    const SubscriberProperties = createSelector(
        selectSubscriberState,
        (Subscriber) => ({
            subscribers: Subscriber.subscriber,
            loading: Subscriber.loading
        })
    );

    const { subscribers, loading } = useSelector(SubscriberProperties);
    const [isLoading, setLoading] = useState(loading)

    const columns = useMemo(
        () => [

            {
                Header: "Order ID",
                accessor: "orderId",
                filterable: false,
                disableFilters: true,
                Cell: cellProps => {
                    return <OrderId {...cellProps} />;
                },
            },
            {
                Header: "User Name",
                disableFilters: true,
                filterable: false,
                Cell: cellProps => {
                    return (
                        cellProps.row.original.user?.name
                    )
                },
            },
            {
                Header: "Plan",
                accessor: "paymentMethod",
                disableFilters: true,
                Cell: cellProps => {
                    return (
                        cellProps.row.original.subscription.name + " (" + cellProps.row.original.subscription.duration + " Month's)"
                    )
                }
            },

            {
                Header: "Price",
                accessor: "price",
                disableFilters: true,
                filterable: false,
                Cell: cellProps => {
                    return (
                        cellProps.row.original.subscription.price?.toLocaleString('en-IN', {
                            style: 'currency',
                            currency: 'INR',
                          })
                    )
                },
            },
            {
                Header: "Payment Status",
                accessor: "paymentStatus",
                disableFilters: true,
                filterable: false,
                Cell: cellProps => {
                    return <PaymentStatus {...cellProps} />;
                },
            },
            {
                Header: "Date",
                accessor: "orderDate",
                disableFilters: true,
                filterable: false,
                Cell: cellProps => {
                    return <Date {...cellProps} />;
                },
            },

            {
                Header: "View Details",
                disableFilters: true,
                accessor: "view",
                Cell: cellProps => {
                    return (
                        <Button
                            type="button"
                            color="primary"
                            className="btn-sm btn-rounded"
                            onClick={() => toggleViewModal(cellProps.row.original)}
                        >
                            View Details
                        </Button>
                    );
                },
            },
        ],
        []
    );
    useEffect(() => {
        if (subscribers && !subscribers.length) {
            dispatch(onGetSubscriber());
        }
    }, [dispatch]);

    return (
        <React.Fragment>
            <SubscriptionModel subscriber={subscriber} isOpen={modal1} toggle={toggleViewModal} />
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns}
                                data={subscribers}
                                isGlobalFilter={true}
                                isAddOptions={false}
                                isPagination={true}
                                iscustomPageSizeOptions={false}
                                customPageSize={20}
                                pagination="pagination pagination-rounded justify-content-end mb-2"
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default SubscriptionList;