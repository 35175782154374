import {
  
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_SUCCESS,
  ADD_NEW_NOTIFICATION,
  ADD_NOTIFICATION_SUCCESS,
  ADD_NOTIFICATION_FAIL,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAIL,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAIL,
  GET_NOTIFICATIONS,
} from "./actionTypes"

export const getNotifications = () => ({
  type: GET_NOTIFICATIONS,
})

export const getNotificationsSuccess = notification => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: notification,
})

export const addNewNotification = notification => ({
  type: ADD_NEW_NOTIFICATION,
  payload: notification,
})

export const addNotificationSuccess = notification => ({
  type: ADD_NOTIFICATION_SUCCESS,
  payload: notification,
})

export const addNotificationFail = error => ({
  type: ADD_NOTIFICATION_FAIL,
  payload: error,
})

export const getNotificationsFail = error => ({
  type: GET_NOTIFICATIONS_FAIL,
  payload: error,
})

export const updateNotification = notification => ({
  type: UPDATE_NOTIFICATION,
  payload: notification,
})

export const updateNotificationSuccess = notification => ({
  type: UPDATE_NOTIFICATION_SUCCESS,
  payload: notification,
})

export const updateNotificationFail = error => ({
  type: UPDATE_NOTIFICATION_FAIL,
  payload: error,
})

export const deleteNotification = notification => ({
  type: DELETE_NOTIFICATION,
  payload: notification,
})

export const deleteNotificationSuccess = notification => ({
  type: DELETE_NOTIFICATION_SUCCESS,
  payload: notification,
})

export const deleteNotificationFail = error => ({
  type: DELETE_NOTIFICATION_FAIL,
  payload: error,
})