import {
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAIL
} from "./actionTypes"

const INIT_STATE = {
  dashboard: {},
  error: {},
  loading: true
}

const dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboard: action.payload,
        loading: true
      }
      break;
    case GET_DASHBOARD_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      break;
    default:
      return state
  }
}

export default dashboard
