import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ApexSubscriptions from './ApexSubscriptions';

//i18n
import { withTranslation } from "react-i18next";
import ApexPlan from "./ApexPlan";
import Earning from "./Earning";
import Statistics from "./Statistics";
import TopStories from "./TopStories";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboard as onGetUser,
} from "../../store/dashboard/actions";
import { createSelector } from "reselect";
import { Link, useNavigate } from "react-router-dom";

const Dashboard = props => {
  //meta title
  document.title = "Mythreya Fun & Food - Dashboard";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [periodData, setPeriodData] = useState([]);
  const [userGraph, setUserGraph] = useState([0,0,0,0,0,0,0,0,0,0,0,0]);
  const [subscriptionGraph, setSubscriptionGraph] = useState([]);
  const [revenue, setRevenue] = useState({
    lastMonth: 0,
    thisMonth: 0
  });
  const [periodType, setPeriodType] = useState("yearly");
  const [reports, setReport] = useState([
    {
      title: "Total Food Items",
      count: 0,
      iconClass: "bx-user"
    },

    {
      title: "Total Orders",
      count: 0,
      iconClass: "bxs-music"
    },
    {
      title: "Today's Revenue",
      count: 0,
      iconClass: "bx-diamond"
    },
    {
      title: "Total Revenue",
      count: 0,
      iconClass: "bx-store-alt"
    },
  ])

  const selectDashboardState = (state) => state.dashboard;
  const dashboardProperties = createSelector(
    selectDashboardState,
    (Dashboard) => ({
      dashboard: Dashboard.dashboard,
      loading: Dashboard.loading
    })
  );

  const { dashboard, loading } = useSelector(dashboardProperties);
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    // if (users && !users.length) {
    dispatch(onGetUser());
    // }
  }, [dispatch]);

  useEffect(() => {
    if (dashboard) {
      setUserGraph(dashboard.userGraph)
      setSubscriptionGraph(dashboard.subscriptionGraph)
      setRevenue({
        lastMonth: dashboard.lastMonth,
        thisMonth: dashboard.thisMonth
      })
      setReport([
        {
          title: "Total Food Items",
          count: dashboard.totalUsers,
          iconClass: "bx-user"
        },

        {
          title: "Total Orders",
          count: dashboard.totalStories,
          iconClass: "bxs-music"
        },
        {
          title: "Today's Revenue",
          count: dashboard.totalSubscription,
          iconClass: "bx-diamond"
        },
        {
          title: "Total Revenue",
          count: dashboard.totalRevenue,
          iconClass: "bx-store-alt"
        },
      ])
    }

  }, [dashboard]);

  const redirect = (text)=>{
    console.log(text)
    if(text == "Total Users"){
      navigate("/users")
    }else if(text == "Total Stories"){
      navigate("/story-list")
    }else{
      navigate("/subscriptions")
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row>
            <Col sm={12}>
              <Row>
                {reports.map((report, key) => (
                  <Col md="3" role="button" key={"_col_" + key} onClick={()=>redirect(report.title)}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.count}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Earning data={revenue} dataColors='["--bs-primary"]' />

            </Col>
            <Col sm={8}>
              <Statistics />

            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Orders</CardTitle>
                  <div id="revenue-chart">
                    <ApexSubscriptions userGraph={userGraph} dataColors='["--bs-primary"]' />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Food Statistics</CardTitle>
                  <div id="revenue-chart">
                    <ApexPlan subscriptionGraph={subscriptionGraph} periodData={periodData} dataColors='["--bs-primary", "--bs-info", "--bs-warning"]' />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Row>
            <Col sm={12}>
              <TopStories />
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
