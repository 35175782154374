import { call, put, takeEvery } from "redux-saga/effects"
import { ADD_NEW_STORY, DELETE_STORY, GET_STORYS, UPDATE_STORY, GET_STORY, DELETE_STORY_IMAGE } from "./actionTypes"
import {
  getStorysSuccess,
  getStorysFail,
  getStorySuccess,
  getStoryFail,
  addStoryFail,
  addStorySuccess,
  updateStorySuccess,
  updateStoryFail,
  deleteStorySuccess,
  deleteStoryFail,
  deleteStoryImageSuccess,
  deleteStoryImageFail,
} from "./actions"
import { getStory, addStory, updateStory, deleteStory, getStoryById, deleteStoryImage } from "../../helpers/story_helper"
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';


function* fetchStory() {
  try {
    const response = yield call(getStory)
    yield put(getStorysSuccess(response.stories))
  } catch (error) {
    yield put(getStorysFail(error))
  }
}

function* fetchStoryById({ payload: storyId }) {
  try {
    if(storyId){
      const response = yield call(getStoryById, storyId)
    yield put(getStorySuccess(response.story))
    }else{
      yield put(getStorySuccess({}))
    }
  } catch (error) {
    yield put(getStoryFail(error))
  }
}

function* onUpdateStory({ payload: story }) {
  try {
    const response = yield call(updateStory, story)
    
    yield put(updateStorySuccess(response.story))
    story.navigate('/create-story/'+response.story.id)
    toast.success("Updated Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(updateStoryFail(error))
    toast.error("Updated Failed", { autoClose: 2000 });
  }
}

function* onDeleteStory({ payload: story }) {
  try {
    const response = yield call(deleteStory, story)
    
    yield put(deleteStorySuccess(response.story._id))
    toast.success("Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteStoryFail(error))
    toast.error("Deleted Failed", { autoClose: 2000 });
  }
}

function* onAddStory({ payload: story }) {
  try {
    const response = yield call(addStory, story.data)
    response.story.id = response.story._id;
    yield put(addStorySuccess(response.story))
    story.navigate('/create-story/'+response.story.id)
    toast.success("Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addStoryFail(error))
    toast.error("Added Failed", { autoClose: 2000 });
  }
}

function* onDeleteStoryImage({ payload: story }) {
  try {
    const response = yield call(deleteStoryImage, story)
    
    yield put(deleteStoryImageSuccess(response.story._id))
    toast.success("Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteStoryImageFail(error))
    toast.error("Deleted Failed", { autoClose: 2000 });
  }
}

function* storySaga() {
  yield takeEvery(GET_STORYS, fetchStory)
  yield takeEvery(GET_STORY, fetchStoryById)
  yield takeEvery(ADD_NEW_STORY, onAddStory)
  yield takeEvery(UPDATE_STORY, onUpdateStory)
  yield takeEvery(DELETE_STORY, onDeleteStory)
  yield takeEvery(DELETE_STORY_IMAGE, onDeleteStoryImage)
}

export default storySaga;
