import {
  GET_TAGS,
  GET_TAGS_FAIL,
  GET_TAGS_SUCCESS,
  GET_TAGSCOMBO,
  GET_TAGSCOMBO_FAIL,
  GET_TAGSCOMBO_SUCCESS,
  ADD_NEW_TAG,
  ADD_TAG_SUCCESS,
  ADD_TAG_FAIL,
  UPDATE_TAG,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_FAIL,
  DELETE_TAG,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAIL,
} from "./actionTypes"

export const getTags = () => ({
  type: GET_TAGS,
})

export const getTagsSuccess = tag => ({
  type: GET_TAGS_SUCCESS,
  payload: tag,
})

export const addNewTag = tag => ({
  type: ADD_NEW_TAG,
  payload: tag,
})

export const addTagSuccess = tag => ({
  type: ADD_TAG_SUCCESS,
  payload: tag,
})

export const addTagFail = error => ({
  type: ADD_TAG_FAIL,
  payload: error,
})

export const getTagsFail = error => ({
  type: GET_TAGS_FAIL,
  payload: error,
})

export const updateTag = tag => ({
  type: UPDATE_TAG,
  payload: tag,
})

export const updateTagSuccess = tag => ({
  type: UPDATE_TAG_SUCCESS,
  payload: tag,
})

export const updateTagFail = error => ({
  type: UPDATE_TAG_FAIL,
  payload: error,
})

export const deleteTag = tag => ({
  type: DELETE_TAG,
  payload: tag,
})

export const deleteTagSuccess = tag => ({
  type: DELETE_TAG_SUCCESS,
  payload: tag,
})

export const deleteTagFail = error => ({
  type: DELETE_TAG_FAIL,
  payload: error,
})
export const getTagCombo = () => ({
  type: GET_TAGSCOMBO,
})

export const getTagsComboSuccess = category => ({
  type: GET_TAGSCOMBO_SUCCESS,
  payload: category,
})

export const getTagsComboFail = error => ({
  type: GET_TAGSCOMBO_FAIL,
  payload: error,
})