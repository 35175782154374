import { call, put, takeEvery } from "redux-saga/effects"
import { ADD_NEW_ROLE, DELETE_ROLE, GET_ROLES, UPDATE_ROLE, GET_ROLESCOMBO } from "./actionTypes"
import {
  getRolesSuccess,
  getRolesFail,
  addRoleFail,
  addRoleSuccess,
  updateRoleSuccess,
  updateRoleFail,
  deleteRoleSuccess,
  deleteRoleFail,
  getRolesComboSuccess,
  getRolesComboFail
} from "./actions"
import { getRole, addRole, updateRole, deleteRole,getRoleCombo } from "../../helpers/role_helper"
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchRole() {
  try {
    const response = yield call(getRole)
    yield put(getRolesSuccess(response.roles))
  } catch (error) {
    yield put(getRolesFail(error))
  }
}

function* fetchRolesCombo() {
  try {
    const response = yield call(getRoleCombo)
    yield put(getRolesComboSuccess(response.roles))
  } catch (error) {
    yield put(getRolesComboFail(error))
  }
}

function* onUpdateRole({ payload: role }) {
  try {
    const response = yield call(updateRole, role)
    response.role.id = response.role._id;
    yield put(updateRoleSuccess(response.role))
    toast.success("Updated Successfully", { autoClose: 2000 });
  } catch (error) {
    console.log(error)
    yield put(updateRoleFail(error))
    toast.error("Updated Failed", { autoClose: 2000 });
  }
}

function* onDeleteRole({ payload: role }) {
  try {
    const response = yield call(deleteRole, role)
    
    yield put(deleteRoleSuccess(response.role._id))
    toast.success("Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteRoleFail(error))
    toast.error("Deleted Failed", { autoClose: 2000 });
  }
}

function* onAddRole({ payload: role }) {
  try {
    const response = yield call(addRole, role)
    response.role.id = response.role._id;
    yield put(addRoleSuccess(response.role))
    toast.success("Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addRoleFail(error))
    toast.error("Added Failed", { autoClose: 2000 });
  }
}

function* roleSaga() {
  yield takeEvery(GET_ROLES, fetchRole)
  yield takeEvery(ADD_NEW_ROLE, onAddRole)
  yield takeEvery(UPDATE_ROLE, onUpdateRole)
  yield takeEvery(DELETE_ROLE, onDeleteRole)
  yield takeEvery(GET_ROLESCOMBO, fetchRolesCombo)
}

export default roleSaga;
