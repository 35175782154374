import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import TableContainer from "../../components/Common/TableContainer";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    UncontrolledTooltip,
    Input,
    Form,
    Button,
    FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";

import {

    getCoupans
} from "../../store/coupan/actions";
import { isEmpty, keys } from "lodash";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import cashLogo from '../../assets/images/cash.png';
import phonepe from '../../assets/images/phonepe.png';
import googlepay from '../../assets/images/googlepay.png';
import upi from '../../assets/images/upi.png';
import creditcard from '../../assets/images/creditcard.jpg';
import debit from '../../assets/images/debit.png';
import { posPrint } from "services/printerservice";
import ReactSelect from "react-select";
import DatePicker from "react-datepicker";
import axios from "axios";
import axiosApi from "helpers/api_helper";
import { validateCoupanCode } from "helpers/orders_helper";
import CoupanPopup from "./components/CoupanPopup";

const PaymentCoupans = props => {

    //meta title
    document.title = "Mythreya - Payment Coupans";

    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [order, setOrder] = useState();
    const [selectedItem, setSelectedItem] = useState(null);
    const [coupanCode, setCoupanCode] = useState("");
    const [setIsCoupan, isCoupan] = useState(false);

    const selectStoreCoupanState = (state) => state.coupans;
    const StoreCoupanProperties = createSelector(
        selectStoreCoupanState,
        (Coupan) => ({
            coupans: Coupan.coupan,
            loading: Coupan.loading
        })
    );

    const {
        coupans, loading
    } = useSelector(StoreCoupanProperties);

    const [modal, setModal] = useState(false);
    const [modalUpdate, setModalUpdate] = useState(false);
    const [modalPrint, setModalPrint] = useState(false);
    const [openCoupan, setOpenCoupan] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setLoading] = useState(loading)
    const [discount, setDiscount] = useState(0)
    const [title, setTitle] = useState("DINEIN")
    const [orderList, setStoreCategoryList] = useState([]);
    const [paymentMode, setPaymentMode] = useState("CASH");
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [errorText, setErrorText] = useState("");

    const columns = useMemo(
        () => [

            {
                Header: "Order Date",
                accessor: "createdBy",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{moment(cellProps.row.original.createdAt).format('MMM D, YYYY')}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Code",
                accessor: "code",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.code}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Order ID",
                accessor: "order.order_id",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.order.order_id}</Link>
                            </h5>
                        </>
                    )
                },
            },

            {
                Header: "Discount",
                accessor: "discount",
                filterable: false,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{(cellProps.row.original.type == "Food") ? cellProps.row.original.discount + "%" : cellProps.row.original.discount + " Times"}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Expiry On",
                accessor: "expiry",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{moment(cellProps.row.original.expiry).format('MMM D, YYYY')}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Reedemed",
                accessor: "isUsed",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.isUsed ? "Reedemed" : "Still Not"}</Link>
                            </h5>
                        </>
                    )
                },
            },

        ],
        []
    );

    useEffect(() => {
        if (coupans && !coupans.length) {
            dispatch(getCoupans({ selectedDate:null }));
            setIsEdit(false);
        }
    }, [dispatch]);

    useEffect(() => {
        setOrder(coupans);
        setIsEdit(false);
    }, [coupans]);

    useEffect(() => {
        if (!isEmpty(coupans) && !!isEdit) {
            setOrder(coupans);
            setIsEdit(false);
        }
    }, [coupans]);

    const toggle = () => {
        setModal(!modal);
    };
    const toggleUpdate = () => {
        setModalUpdate(!modalUpdate);
    };

    const handleStoreCategoryClick = arg => {
        setOrder(arg);
        setIsEdit(true);

        toggle();
    };

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    //delete customer
    const [deleteModal, setDeleteModal] = useState(false);

    // const onClickDelete = orders => {
    //     setOrder(orders);
    //     setDeleteModal(true);
    // };

    // const handleDeleteStoreCategory = () => {
    //     if (order && order.id) {
    //         dispatch(onDeleteStoreCategory(order.id));
    //     }
    //     onPaginationPageChange(1);
    //     setDeleteModal(false);
    // };

    const handleStoreCategoryClicks = () => {
        // setStoreCategoryList("");
        // setIsEdit(false);
        // toggle();
        // dispatch(getOrders({ selectedDate, counter: title, settlement_status: "PENDING" }));
    };

    const handleDateChange = (date) => {
        setSelectedDate(date)
        dispatch(getCoupans({ selectedDate: date }));
    }

    const keyField = "id";

    // const changeTab = (type) => {
    //     setTitle(type)
    //     dispatch(getOrders({ selectedDate, counter: type, settlement_status: "PENDING" }));
    // }

    // const changePayment = (payment) => {
    //     setPaymentMode(payment)
    // }

    // const updateOrder = (item) => {
    //     // posPrint(item.pos, item.text, "R")
    //     setOrder(item);
    //     setIsEdit(true);

    //     toggleUpdate();
    // }

    // const printOrder = (item, copy) => {
    //     setModalPrint(false);
    //     "[C]<font size='big'>Mythreya</font>\n[C]<font size='big'>Multicuisine Restuarent</font>\n[C]Mallaya Doddi,Opp. HP Petrol Bunk, Ravulapadu (V)\n[C] Ravulapalem - 533238. Ph : +91 6300585325 \n[C] GSTIN No.: 37ABFFP0410M1ZA \n[C] FASSAI Code : 10124017000070 \n"
    //     let text = item.text.replace("[C]<img>https://api.mythreyarestaurant.com/print/printlogo.bmp</img>\n[C]<font size='big'>Mythreya</font>\n[C]<font size='big'>Multicuisine Restuarent</font>\n[C]Mallaya Doddi,Opp. HP Petrol Bunk, Ravulapadu (V)\n[C] Ravulapalem - 533238. Ph : +91 6300585325 \n", "");
    //     axios.post("http://localhost:8080/printer-service/print", { data: text, copy: copy, ip: item.ip }).then((response) => {
    //         console.log(response)
    //     }).catch(error => {
    //         console.log(error)
    //     })
    // }

    // const togglePrint = () => {
    //     setModalPrint(!modalPrint);
    // };
    // const printPopup = () => {
    //     // setSelectedItem(item);
    //     setModalPrint(true);
    // }

    // const remove = (item) => {
    //     const order_ = order.items.map((x) => {
    //         if (x._id == item._id) {
    //             x.qty -= 1;
    //             x.netprice = x.price * x.qty;
    //         }
    //         return x;
    //     })
    //     setOrder({ ...order, items: order_, total_amount: order_.reduce((acc, order) => (order.basic == "REGULAR") ? acc + order.netprice : 0, 0) })
    // }

    // const add = (item) => {
    //     const order_ = order.items.map((x) => {
    //         if (x._id == item._id) {
    //             x.qty += 1;
    //             x.netprice = x.price * x.qty;
    //         }
    //         return x;
    //     })
    //     setOrder({ ...order, items: order_, total_amount: order_.reduce((acc, order) => (order.basic == "REGULAR") ? acc + order.netprice : 0, 0) })
    // }

    // const onSelectOption = (item, value) => {
    //     const order_ = order.items.map((x) => {
    //         if (x._id == item._id) {
    //             x.basic = value
    //         }
    //         return x;
    //     })

    //     setOrder({ ...order, items: order_, total_amount: order_.reduce((acc, order) => (order.basic == "REGULAR") ? acc + order.netprice : 0, 0) })
    // }

    // const updateItems = () => {
    //     dispatch(updateOrderItems(order));
    //     toggleUpdate();
    // }

    // const getCoupan = async (order) => {
    //     setSelectedOrder(order)
    //     setOpenCoupan(true)
    // }
    // const removeCoupan = async () => {
    //     setOrder({ ...order, discount: "", code: "", payable_amount: order.total_amount })
    // }

    // const validateCoupan = async () => {
    //     setLoading(true)
    //     await validateCoupanCode(coupanCode, "Food").then((data) => {
    //         setLoading(false)
    //         setErrorText("")
    //         console.log(data)
    //         if (data.error == "") {
    //             if (order.total_amount >= data.minimum) {
    //                 setOrder({ ...order, discount: data.discount, code: coupanCode, payable_amount: Math.round(order.total_amount - ((order.total_amount * data.discount) / 100)) })
    //             } else {
    //                 setErrorText("Coupan minimum order amount Rs." + data.minimum.toLocaleString('en-IN', { minimumFractionDigits: 2 }))
    //             }

    //         } else {
    //             setErrorText(data.error)
    //         }
    //     }).catch((error) => {
    //         setLoading(false)
    //     })
    // }

    return (
        loading ? <Spinners setLoading={setLoading} />
            :
            <React.Fragment>
                {/* <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteStoreCategory}
                    onCloseClick={() => setDeleteModal(false)}
                /> */}
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}

                        <Row>
                            {
                                isLoading ? <Spinners setLoading={setLoading} />
                                    :
                                    <Col lg="12">
                                        <Card>
                                            <CardBody className="d-flex flex-row justify-content-between align-items-center p-0">
                                                <div>
                                                    <Label>
                                                        Select Date
                                                    </Label>
                                                    <DatePicker
                                                        selected={selectedDate}
                                                        onChange={handleDateChange}
                                                        dateFormat="dd/MM/yyyy"
                                                        def
                                                        placeholderText="Select a date"
                                                    />
                                                    
                                                </div>
                                                <Button onClick={()=>{handleDateChange(null)}} color="primary">Clear</Button>
                                            </CardBody>
                                        </Card>
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    isPagination={true}
                                                    columns={columns}
                                                    data={coupans}
                                                    isGlobalFilter={true}
                                                    isShowingPageLength={false}
                                                    buttonText={"Refresh Orders"}
                                                    isAddUserList={true}
                                                    iscustomPageSizeOptions={false}
                                                    handleUserClick={handleStoreCategoryClicks}
                                                    customPageSize={100}
                                                    tableClass="table align-middle table-nowrap table-hover"
                                                    theadClass="table-light"
                                                    paginationDiv="col-sm-12 col-md-7"
                                                    pagination="pagination pagination-rounded justify-content-end mt-4"
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                            }
                        </Row>
                    </Container>
                </div>
                <ToastContainer />
            </React.Fragment>
    );
};

export default withRouter(PaymentCoupans);
