import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_DASHBOARD } from "./actionTypes"

import {
  getDashboardSuccess,
  getDashboardFail
} from "./actions"

import { getDashboard } from "../../helpers/dashboard_helper"

function* fetchCoupan() {
  try {
    const response = yield call(getDashboard)
    yield put(getDashboardSuccess(response.dashboard))
  } catch (error) {
    yield put(getDashboardFail(error))
  }
}

function* dashboardSaga() {
  yield takeEvery(GET_DASHBOARD, fetchCoupan)
}

export default dashboardSaga;
