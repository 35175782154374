/* USERS */
export const GET_COUNTERS = "GET_COUNTERS"
export const GET_COUNTERS_SUCCESS = "GET_COUNTERS_SUCCESS"
export const GET_COUNTERS_FAIL = "GET_COUNTERS_FAIL"

/* CATEGORS PROFILE */
export const GET_COUNTER = "GET_COUNTER"
export const GET_COUNTER_SUCCESS = "GET_COUNTER_SUCCESS"
export const GET_COUNTER_FAIL = "GET_COUNTER_FAIL"

/**
 * add user
 */
export const ADD_NEW_COUNTER = "ADD_NEW_COUNTER"
export const ADD_COUNTER_SUCCESS = "ADD_COUNTER_SUCCESS"
export const ADD_COUNTER_FAIL = "ADD_COUNTER_FAIL"

/**
 * Edit user
 */
export const UPDATE_COUNTER = "UPDATE_COUNTER"
export const UPDATE_COUNTER_SUCCESS = "UPDATE_COUNTER_SUCCESS"
export const UPDATE_COUNTER_FAIL = "UPDATE_COUNTER_FAIL"

/**
 * Delete user
 */
export const DELETE_COUNTER = "DELETE_COUNTER"
export const DELETE_COUNTER_SUCCESS = "DELETE_COUNTER_SUCCESS"
export const DELETE_COUNTER_FAIL = "DELETE_COUNTER_FAIL"

export const GET_COUNTERSCOMBO = "GET_COUNTERSCOMBO"
export const GET_COUNTERSCOMBO_FAIL = "GET_COUNTERSCOMBO_FAIL"
export const GET_COUNTERSCOMBO_SUCCESS = "GET_COUNTERSCOMBO_SUCCESS"
