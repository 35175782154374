/* USERS */
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS"
export const GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCESS"
export const GET_SUBSCRIPTIONS_FAIL = "GET_SUBSCRIPTIONS_FAIL"

/* CATEGORS PROFILE */
export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION"
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS"
export const GET_SUBSCRIPTION_FAIL = "GET_SUBSCRIPTION_FAIL"

/**
 * add user
 */
export const ADD_NEW_SUBSCRIPTION = "ADD_NEW_SUBSCRIPTION"
export const ADD_SUBSCRIPTION_SUCCESS = "ADD_SUBSCRIPTION_SUCCESS"
export const ADD_SUBSCRIPTION_FAIL = "ADD_SUBSCRIPTION_FAIL"

/**
 * Edit user
 */
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION"
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS"
export const UPDATE_SUBSCRIPTION_FAIL = "UPDATE_SUBSCRIPTION_FAIL"

/**
 * Delete user
 */
export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION"
export const DELETE_SUBSCRIPTION_SUCCESS = "DELETE_SUBSCRIPTION_SUCCESS"
export const DELETE_SUBSCRIPTION_FAIL = "DELETE_SUBSCRIPTION_FAIL"


export const GET_SUBSCRIPTIONCOMBO = "GET_SUBSCRIPTIONCOMBO"
export const GET_SUBSCRIPTIONCOMBO_FAIL = "GET_SUBSCRIPTIONCOMBO_FAIL"
export const GET_SUBSCRIPTIONCOMBO_SUCCESS = "GET_SUBSCRIPTIONCOMBO_SUCCESS"