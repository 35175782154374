import {
  GET_SLIDES,
  GET_SLIDES_FAIL,
  GET_SLIDES_SUCCESS,
  GET_SLIDESCOMBO,
  GET_SLIDESCOMBO_FAIL,
  GET_SLIDESCOMBO_SUCCESS,
  ADD_NEW_SLIDE,
  ADD_SLIDE_SUCCESS,
  ADD_SLIDE_FAIL,
  UPDATE_SLIDE,
  UPDATE_SLIDE_SUCCESS,
  UPDATE_SLIDE_FAIL,
  DELETE_SLIDE,
  DELETE_SLIDE_SUCCESS,
  DELETE_SLIDE_FAIL,
} from "./actionTypes"

export const getSlides = () => ({
  type: GET_SLIDES,
})

export const getSlidesSuccess = slide => ({
  type: GET_SLIDES_SUCCESS,
  payload: slide,
})

export const addNewSlide = slide => ({
  type: ADD_NEW_SLIDE,
  payload: slide,
})

export const addSlideSuccess = slide => ({
  type: ADD_SLIDE_SUCCESS,
  payload: slide,
})

export const addSlideFail = error => ({
  type: ADD_SLIDE_FAIL,
  payload: error,
})

export const getSlidesFail = error => ({
  type: GET_SLIDES_FAIL,
  payload: error,
})

export const updateSlide = slide => ({
  type: UPDATE_SLIDE,
  payload: slide,
})

export const updateSlideSuccess = slide => ({
  type: UPDATE_SLIDE_SUCCESS,
  payload: slide,
})

export const updateSlideFail = error => ({
  type: UPDATE_SLIDE_FAIL,
  payload: error,
})

export const deleteSlide = slide => ({
  type: DELETE_SLIDE,
  payload: slide,
})

export const deleteSlideSuccess = slide => ({
  type: DELETE_SLIDE_SUCCESS,
  payload: slide,
})

export const deleteSlideFail = error => ({
  type: DELETE_SLIDE_FAIL,
  payload: error,
})


export const getSlideCombo = () => ({
  type: GET_SLIDESCOMBO,
})

export const getSlideComboSuccess = slide => ({
  type: GET_SLIDESCOMBO_SUCCESS,
  payload: slide,
})

export const getSlideComboFail = error => ({
  type: GET_SLIDESCOMBO_FAIL,
  payload: error,
})