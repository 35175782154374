import { call, put, takeEvery } from "redux-saga/effects"
import { ADD_NEW_TAG, DELETE_TAG, GET_TAGS, UPDATE_TAG, GET_TAGSCOMBO } from "./actionTypes"
import {
  getTagsSuccess,
  getTagsFail,
  addTagFail,
  addTagSuccess,
  updateTagSuccess,
  updateTagFail,
  deleteTagSuccess,
  deleteTagFail,
  getTagsComboSuccess,
  getTagsComboFail
} from "./actions"
import { getTag, addTag, updateTag, deleteTag,getTagCombo } from "../../helpers/tag_helper"
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchTag() {
  try {
    const response = yield call(getTag)
    yield put(getTagsSuccess(response.tags))
  } catch (error) {
    yield put(getTagsFail(error))
  }
}

function* fetchTagsCombo() {
  try {
    const response = yield call(getTagCombo)
    yield put(getTagsComboSuccess(response.tags))
  } catch (error) {
    yield put(getTagsComboFail(error))
  }
}

function* onUpdateTag({ payload: tag }) {
  try {
    const response = yield call(updateTag, tag)
    response.tag.id = response.tag._id;
    yield put(updateTagSuccess(response.tag))
    toast.success("Updated Successfully", { autoClose: 2000 });
  } catch (error) {
    console.log(error)
    yield put(updateTagFail(error))
    toast.error("Updated Failed", { autoClose: 2000 });
  }
}

function* onDeleteTag({ payload: tag }) {
  try {
    const response = yield call(deleteTag, tag)
    
    yield put(deleteTagSuccess(response.tag._id))
    toast.success("Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteTagFail(error))
    toast.error("Deleted Failed", { autoClose: 2000 });
  }
}

function* onAddTag({ payload: tag }) {
  try {
    const response = yield call(addTag, tag)
    response.tag.id = response.tag._id;
    yield put(addTagSuccess(response.tag))
    toast.success("Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addTagFail(error))
    toast.error("Added Failed", { autoClose: 2000 });
  }
}

function* tagSaga() {
  yield takeEvery(GET_TAGS, fetchTag)
  yield takeEvery(ADD_NEW_TAG, onAddTag)
  yield takeEvery(UPDATE_TAG, onUpdateTag)
  yield takeEvery(DELETE_TAG, onDeleteTag)
  yield takeEvery(DELETE_TAG, onDeleteTag)
  yield takeEvery(GET_TAGSCOMBO, fetchTagsCombo)
}

export default tagSaga;
