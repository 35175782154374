import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"

const SubscriptionModal = props => {
  const { isOpen, toggle, subscriber } = props
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Subscription Details</ModalHeader>
        {subscriber &&
          <ModalBody>

            <p className="mb-2">
              Product id: <span className="text-primary">{subscriber.orderId}</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">{subscriber.user?.name}</span>
            </p>

            <div className="table-responsive">
              <Table className="table align-middle table-nowrap">
                <thead>
                  <tr>
                    {/* <th scope="col">Product</th> */}
                    <th scope="col" colSpan="2">Subscription Plan</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/* <th scope="row">
                    <div>
                      <img src={img7} alt="" className="avatar-sm"/>
                    </div>
                  </th> */}
                    <td colSpan="2">
                      <div>
                        <h5 className="text-truncate font-size-14">{subscriber.subscription?.name}/{subscriber.subscription?.duration} Months Subscription</h5>
                        {/* <p className="text-muted mb-0">$ 225 x 1</p> */}
                      </div>
                    </td>
                    <td>{subscriber.price?.toLocaleString('en-IN', {
                        style: 'currency',
                        currency: 'INR',
                      })}</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-right">Discount:</h6>
                    </td>
                    <td>
                      {subscriber.discount?.toLocaleString('en-IN', {
                        style: 'currency',
                        currency: 'INR',
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-right">Sub Total:</h6>
                    </td>
                    <td>
                      {(subscriber.price - subscriber.discount)?.toLocaleString('en-IN', {
                        style: 'currency',
                        currency: 'INR',
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-right">Total:</h6>
                    </td>
                    <td>
                      {subscriber.total?.toLocaleString('en-IN', {
                        style: 'currency',
                        currency: 'INR',
                      })}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>

          </ModalBody>}
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

SubscriptionModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default SubscriptionModal
