import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  ADD_NEW_NOTIFICATION,
  ADD_NOTIFICATION_SUCCESS,
  ADD_NOTIFICATION_FAIL,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAIL,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAIL,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  notification: [],
  error: {},
  loading: false
}

const notifications = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_NOTIFICATIONS:
      return {
        ...state,
        loading: true
      }
      break
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notification: action.payload,
        loading: false
      }
      break
    case GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case ADD_NEW_NOTIFICATION:
      return {
        ...state,
        loading: true
      }
      break
    case ADD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: [...state.notification, action.payload],
        loading: false
      }
      break
    case ADD_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: action.payload,
      }
      break
    case UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: state.notification.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
      }
      break
    case UPDATE_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      break
    case DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: state.notification.filter(
          user => user.id.toString() !== action.payload.toString()
        ),
        loading: false
      }
      break
    case DELETE_NOTIFICATION: {
      return {
        ...state,
        loading: true
      }
    }
      break
    case DELETE_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case GET_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      break
    default:
      return state
  }
}

export default notifications
