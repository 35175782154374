import React, { useEffect, useState, useRef, useMemo } from "react";
import { getTagCombo } from "../../store/tag/actions";
import Select from 'react-select';
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { FormFeedback } from "reactstrap";

const TagSelect = ({ OnSelect, defaultValue, error, isSubmitted }) => {
    const dispatch = useDispatch();
    const [tag, setTag] = useState({});
    const [selectedTag, setSelectedTag] = useState([]);
    const [touchedCategory, setTouchedCategory] = useState(false);

    const selectContactsState = (state) => state.tags;
    const ContactsProperties = createSelector(
        selectContactsState,
        (Tag) => ({
            tags: Tag.tagCombo,
            loading: Tag.loading
        })
    );
    const { tags, loading } = useSelector(ContactsProperties);

    const handleSelect = (val) => {
        setSelectedTag(val)
        setTouchedCategory(true);
        OnSelect(val)
    }

    useEffect(() => {
        if (tags && !tags.length) {
            dispatch(getTagCombo());
        }
        setTouchedCategory(false);
    }, [dispatch, tags]);

    useEffect(() => {
        if (tags && tags.length && defaultValue.length != 0) {
            let tag = tags.filter(tag => defaultValue.some(value => value._id === tag.value));
            // console.log(tag)
            setSelectedTag(tag)
        }
        if (defaultValue.length == 0) {
            setSelectedTag([])
        }
    }, [tags, defaultValue]);

    return (
        <React.Fragment>
            <Select
                classNamePrefix="select2-selection"
                placeholder="Choose..."
                title="Duration"
                options={tags}
                value={selectedTag}
                onChange={handleSelect}
                isMulti
            />
            {(isSubmitted || touchedCategory) && error ?
                <FormFeedback type="invalid" style={{ display: 'block' }}>
                    {error}
                </FormFeedback> : null
            }
        </React.Fragment>
    )
};

export default TagSelect