import {
  GET_STORYS,
  GET_STORYS_FAIL,
  GET_STORYS_SUCCESS,
  GET_STORY,
  GET_STORY_FAIL,
  GET_STORY_SUCCESS,
  ADD_NEW_STORY,
  ADD_STORY_SUCCESS,
  ADD_STORY_FAIL,
  UPDATE_STORY,
  UPDATE_STORY_SUCCESS,
  UPDATE_STORY_FAIL,
  DELETE_STORY,
  DELETE_STORY_SUCCESS,
  DELETE_STORY_FAIL,
  DELETE_STORY_IMAGE,
  DELETE_STORY_IMAGE_FAIL,
  DELETE_STORY_IMAGE_SUCCESS
} from "./actionTypes"



export const getStory = (story) => ({
  type: GET_STORY,
  payload:story
})

export const getStorySuccess = story => ({
  type: GET_STORY_SUCCESS,
  payload: story,
})

export const getStoryFail = error => ({
  type: GET_STORY_FAIL,
  payload: error,
})

export const getStorys = () => ({
  type: GET_STORYS,
})

export const getStorysSuccess = story => ({
  type: GET_STORYS_SUCCESS,
  payload: story,
})

export const getStorysFail = error => ({
  type: GET_STORYS_FAIL,
  payload: error,
})

export const addNewStory = story => ({
  type: ADD_NEW_STORY,
  payload: story,
})

export const addStorySuccess = story => ({
  type: ADD_STORY_SUCCESS,
  payload: story,
})

export const addStoryFail = error => ({
  type: ADD_STORY_FAIL,
  payload: error,
})



export const updateStory = story => ({
  type: UPDATE_STORY,
  payload: story,
})

export const updateStorySuccess = story => ({
  type: UPDATE_STORY_SUCCESS,
  payload: story,
})

export const updateStoryFail = error => ({
  type: UPDATE_STORY_FAIL,
  payload: error,
})

export const deleteStory = story => ({
  type: DELETE_STORY,
  payload: story,
})

export const deleteStorySuccess = story => ({
  type: DELETE_STORY_SUCCESS,
  payload: story,
})

export const deleteStoryFail = error => ({
  type: DELETE_STORY_FAIL,
  payload: error,
})


export const deleteStoryImage = (story) => ({
  type: DELETE_STORY_IMAGE,
  payload:story
})

export const deleteStoryImageSuccess = story => ({
  type: DELETE_STORY_IMAGE_SUCCESS,
  payload: story,
})

export const deleteStoryImageFail = error => ({
  type: DELETE_STORY_IMAGE_FAIL,
  payload: error,
})