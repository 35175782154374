import {
  GET_SUBSCRIBER,
  GET_SUBSCRIBER_FAIL,
  GET_SUBSCRIBER_SUCCESS,
  ADD_NEW_SUBSCRIBER,
  ADD_SUBSCRIBER_SUCCESS,
  ADD_SUBSCRIBER_FAIL,
  UPDATE_SUBSCRIBER,
  UPDATE_SUBSCRIBER_SUCCESS,
  UPDATE_SUBSCRIBER_FAIL,
  DELETE_SUBSCRIBER,
  DELETE_SUBSCRIBER_SUCCESS,
  DELETE_SUBSCRIBER_FAIL,
} from "./actionTypes"

export const getSubscriber = () => ({
  type: GET_SUBSCRIBER,
})

export const getSubscriberSuccess = subscriber => ({
  type: GET_SUBSCRIBER_SUCCESS,
  payload: subscriber,
})

export const getSubscriberFail = error => ({
  type: GET_SUBSCRIBER_FAIL,
  payload: error,
})

export const addNewSubscriber = subscriber => ({
  type: ADD_NEW_SUBSCRIBER,
  payload: subscriber,
})

export const addSubscriberSuccess = subscriber => ({
  type: ADD_SUBSCRIBER_SUCCESS,
  payload: subscriber,
})

export const addSubscriberFail = error => ({
  type: ADD_SUBSCRIBER_FAIL,
  payload: error,
})

export const updateSubscriber = subscriber => ({
  type: UPDATE_SUBSCRIBER,
  payload: subscriber,
})

export const updateSubscriberSuccess = subscriber => ({
  type: UPDATE_SUBSCRIBER_SUCCESS,
  payload: subscriber,
})

export const updateSubscriberFail = error => ({
  type: UPDATE_SUBSCRIBER_FAIL,
  payload: error,
})

export const deleteSubscriber = subscriber => ({
  type: DELETE_SUBSCRIBER,
  payload: subscriber,
})

export const deleteSubscriberSuccess = subscriber => ({
  type: DELETE_SUBSCRIBER_SUCCESS,
  payload: subscriber,
})

export const deleteSubscriberFail = error => ({
  type: DELETE_SUBSCRIBER_FAIL,
  payload: error,
})