import React, { useEffect, useState, useRef, useMemo } from "react";
import { getTagCombo } from "../../store/tag/actions";
import Select from 'react-select';
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Card, CardBody, CardHeader, Col, FormGroup, Input, Label } from "reactstrap";
import {
    addNewHome as onAddNewHome,
} from "../../store/home/actions";

const TagsCheckBox = ({ home }) => {
    const dispatch = useDispatch();
    const [tag, setTag] = useState({});
    const [selectedOption, setSelectedOption] = useState(null);
    let all = {
        label:"All",
        value:"All"
    }
    const selectContactsState = (state) => state.tags;
    const ContactsProperties = createSelector(
        selectContactsState,
        (Tag) => ({
            tags: Tag.tagCombo,
            loading: Tag.loading
        })
    );
    const { tags, loading } = useSelector(ContactsProperties);


    const handleSelect = (val) => {
        setSelectedTag(val)
    }

    useEffect(() => {
        if (tags && !tags.length) {
            dispatch(getTagCombo());
        }
    }, [dispatch, tags]);

    useEffect(() => {
        if (home && tags && tags.length) {
            if(home.StoryTypes == "All"){
                setSelectedOption(all)
            }else{
                let tag = tags.find(tag => tag.value == home.StoryTypes);
                setSelectedOption(tag)
            }
            
        }
    }, [tags, home]);



    const handleRadioChange = (option) => {
        setSelectedOption(option);
        const formData = new FormData();
        formData.append('StoryTypes', option.value);
        dispatch(onAddNewHome({ data: formData, url: "/updateStoryType" }));
    };


    return (
        <React.Fragment>
            <Card>
                <CardHeader className="h5 text-center">Select Story Tag For Home Data</CardHeader>
                <CardBody>
                    <Col sm={{ size: 6, offset: 3 }}>
                        
                        <FormGroup check>
                            <Label className="h5">
                                <Input
                                    type="radio"
                                    name="radioGroup"
                                    checked={selectedOption?.value == all?.value}
                                    onChange={() => handleRadioChange(all)}
                                />
                                All
                            </Label>
                        </FormGroup>
                        {tags.map((option, index) => (
                            <FormGroup check key={index}>
                                <Label className="h5">
                                    <Input
                                        type="radio"
                                        name="radioGroup"
                                        checked={selectedOption === option}
                                        onChange={() => handleRadioChange(option)}
                                    />
                                    {option.label}
                                </Label>
                            </FormGroup>
                        ))}
                    </Col>
                </CardBody>
            </Card>
        </React.Fragment>

    )
};

export default TagsCheckBox