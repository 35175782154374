import {
  GET_CHAPTERS_SUCCESS,
  GET_CHAPTERS_FAIL,
  ADD_NEW_CHAPTER,
  ADD_CHAPTER_SUCCESS,
  ADD_CHAPTER_FAIL,
  UPDATE_CHAPTER_SUCCESS,
  UPDATE_CHAPTER_FAIL,
  DELETE_CHAPTER,
  DELETE_CHAPTER_SUCCESS,
  DELETE_CHAPTER_FAIL,
  GET_CHAPTER_SUCCESS,
  GET_CHAPTER_FAIL,
  GET_CHAPTER_STORY,
  GET_CHAPTER_STORY_FAIL,
  GET_CHAPTER_STORY_SUCCESS,
  UPDATE_CHAPTER
} from "./actionTypes"

const INIT_STATE = {
  chapter: [],
  error: {},
  loading: true
}

const chapters = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_CHAPTERS_SUCCESS:
      return {
        ...state,
        chapter: action.payload,
        loading: false
      }
      break;

    case GET_CHAPTERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break;

    case ADD_NEW_CHAPTER:
      return {
        ...state,
        loading: true,
      }
      break;
    case ADD_CHAPTER_SUCCESS:
      return {
        ...state,
        chapter: [...state.chapter, action.payload],
        loading: false,
      }
      break;
    case ADD_CHAPTER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
      break;
    case GET_CHAPTER_SUCCESS:
      return {
        ...state,
        chapter: action.payload,
      }
      break;
    case UPDATE_CHAPTER:
      return {
        ...state,
        loading: true,
      }
      break;
    case UPDATE_CHAPTER_SUCCESS:
      return {
        ...state,
        chapter: state.chapter.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
        loading: false
      }
      break;
    case UPDATE_CHAPTER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break;
    case DELETE_CHAPTER:
      return {
        ...state,
        loading: true,
      }
      break;
    case DELETE_CHAPTER_SUCCESS:
      return {
        ...state,
        chapter: state.chapter.filter(
          user => user.id.toString() !== action.payload.toString()
        ),
        loading: false
      }
      break;
    case DELETE_CHAPTER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break;
    case GET_CHAPTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      break;
    case GET_CHAPTERS_SUCCESS:
      return {
        ...state,
        chapter: action.payload,
        loading: false
      }
      break;
    case GET_CHAPTER_STORY:
      return {
        ...state,
        loading: true,
      }
      break;
    case GET_CHAPTER_STORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break;
    case GET_CHAPTER_STORY_SUCCESS:
      return {
        ...state,
        chapter: action.payload,
        loading: false
      }

    default:
      return state
  }
}

export default chapters
