import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import withRouter from "../../components/Common/withRouter";


import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import SubscriptionModel from "./SubscriptionModel";
import SubscriptionList from "./components/SubscriptionList";

const Subscription = props => {
  document.title = "KIDS STORY TELLING (KST) - Subscriptions";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem="Subscriptions" />
          <SubscriptionList/>

        </Container>
      </div>
    </React.Fragment>
  );
};

Subscription.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(Subscription);
