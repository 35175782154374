/* USERS */
export const GET_COUPANS = "GET_COUPANS"
export const GET_COUPANS_SUCCESS = "GET_COUPANS_SUCCESS"
export const GET_COUPANS_FAIL = "GET_COUPANS_FAIL"

/* CATEGORS PROFILE */
export const GET_COUPAN = "GET_COUPAN"
export const GET_COUPAN_SUCCESS = "GET_COUPAN_SUCCESS"
export const GET_COUPAN_FAIL = "GET_COUPAN_FAIL"

/**
 * add user
 */
export const ADD_NEW_COUPAN = "ADD_NEW_COUPAN"
export const ADD_COUPAN_SUCCESS = "ADD_COUPAN_SUCCESS"
export const ADD_COUPAN_FAIL = "ADD_COUPAN_FAIL"

/**
 * Edit user
 */
export const UPDATE_COUPAN = "UPDATE_COUPAN"
export const UPDATE_COUPAN_SUCCESS = "UPDATE_COUPAN_SUCCESS"
export const UPDATE_COUPAN_FAIL = "UPDATE_COUPAN_FAIL"

/**
 * Delete user
 */
export const DELETE_COUPAN = "DELETE_COUPAN"
export const DELETE_COUPAN_SUCCESS = "DELETE_COUPAN_SUCCESS"
export const DELETE_COUPAN_FAIL = "DELETE_COUPAN_FAIL"
