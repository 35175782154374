import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
import {
  postFakeLogin,
} from "../../../helpers/fakebackend_helper";
import axiosApi from "helpers/api_helper";
import authHeader from "helpers/jwt-token-access/auth-token-header";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postFakeLogin, {
      username: user.username,
      password: user.password,
    });
    localStorage.setItem("authUser", JSON.stringify(response));
    axiosApi.defaults.headers.common["Authorization"] = (authHeader().Authorization) ? "Bearer " + authHeader().Authorization : "";
    yield put(loginSuccess(response));
    history('/dashboard');
  } catch (error) {
    yield put(apiError(error.response.data.message));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    history('/login');
  } catch (error) {
    yield put(apiError(error.response.data.message));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
