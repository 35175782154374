/* USERS */
export const GET_FOOD_ITEMS = "GET_FOOD_ITEMS"
export const GET_FOOD_ITEMS_SUCCESS = "GET_FOOD_ITEMS_SUCCESS"
export const GET_FOOD_ITEMS_FAIL = "GET_FOOD_ITEMS_FAIL"

/* CATEGORS PROFILE */
export const GET_FOOD_ITEM = "GET_FOOD_ITEM"
export const GET_FOOD_ITEM_SUCCESS = "GET_FOOD_ITEM_SUCCESS"
export const GET_FOOD_ITEM_FAIL = "GET_FOOD_ITEM_FAIL"

/**
 * add user
 */
export const ADD_NEW_FOOD_ITEM = "ADD_NEW_FOOD_ITEM"
export const ADD_FOOD_ITEM_SUCCESS = "ADD_FOOD_ITEM_SUCCESS"
export const ADD_FOOD_ITEM_FAIL = "ADD_FOOD_ITEM_FAIL"

/**
 * Edit user
 */
export const UPDATE_FOOD_ITEM = "UPDATE_FOOD_ITEM"
export const UPDATE_FOOD_ITEM_SUCCESS = "UPDATE_FOOD_ITEM_SUCCESS"
export const UPDATE_FOOD_ITEM_FAIL = "UPDATE_FOOD_ITEM_FAIL"

/**
 * Delete user
 */
export const DELETE_FOOD_ITEM = "DELETE_FOOD_ITEM"
export const DELETE_FOOD_ITEM_SUCCESS = "DELETE_FOOD_ITEM_SUCCESS"
export const DELETE_FOOD_ITEM_FAIL = "DELETE_FOOD_ITEM_FAIL"

export const GET_FOOD_ITEMSCOMBO = "GET_FOOD_ITEMSCOMBO"
export const GET_FOOD_ITEMSCOMBO_FAIL = "GET_FOOD_ITEMSCOMBO_FAIL"
export const GET_FOOD_ITEMSCOMBO_SUCCESS = "GET_FOOD_ITEMSCOMBO_SUCCESS"

export const UPDATE_STATUS_FOOD_ITEM = "UPDATE_STATUS_FOOD_ITEM"
export const UPDATE_STATUS_FOOD_ITEM_SUCCESS = "UPDATE_STATUS_FOOD_ITEM_SUCCESS"
export const UPDATE_STATUS_FOOD_ITEM_FAIL = "UPDATE_STATUS_FOOD_ITEM_FAIL"

export const GET_CHART_DATA = "GET_CHART_DATA"
export const GET_CHART_DATA_SUCCESS = "GET_CHART_DATA_SUCCESS"
export const GET_CHART_DATA_FAIL = "GET_CHART_DATA_FAIL"
