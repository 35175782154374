import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  ADD_NEW_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_BY_ID,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAIL
} from "./actionTypes"

const INIT_STATE = {
  users: [],
  user:{},
  error: {},
  loading: false
}

const users = (state = INIT_STATE, action) => {  
  switch (action.type) {
    
    case GET_USER:
      return {
        ...state,
        loading: true
      }
      break
    case GET_USER_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: false
      }
      break
    case GET_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case GET_USER_BY_ID:
      return {
        ...state,
        loading: true
      }
      break
    case GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false
      }
      break
    case GET_USER_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case ADD_NEW_USER:
      return {
        ...state,
        loading: true
      }
      break
    case ADD_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload],
        loading: false
      }
      break
    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
      }
      break
    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      break
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter(
          user => user.id.toString() !== action.payload.toString()
        ),
        loading: false
      }
      break
    case DELETE_USER: {
      return {
        ...state,
        loading: true
      }
    }
      break
    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break

    default:
      return state
  }
}

export default users
