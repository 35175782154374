import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import ReactAudioPlayer from 'react-audio-player';
import audio from "../../../assets/sample.mp3";
import { getVideo } from "helpers/common_helper";

const ChapterModal = props => {
  const { isOpen, toggle, chapters } = props
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      size="lg"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Chapters</ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              <thead>
                <tr>
                  {/* <th scope="col">Product</th> */}
                  <th scope="col" >Chapter</th>
                  <th scope="col">Plan</th>
                  <th scope="col">#</th>
                </tr>
              </thead>
              <tbody>
                {chapters && chapters.length !== 0 && chapters.map((item, index) => (
                  <tr key={index}>
                    <td >
                      <div>
                        <h5 className="text-truncate font-size-14">{item.name}</h5>
                      </div>
                    </td>
                    <td >
                      {item.subscriptions.length != 0 && item.subscriptions.map((plan, ind) => {
                        return(<div key={ind} className="font-size-14 p-2 badge badge-soft-primary">{plan.name}</div>)
                      })}
                    </td>
                    <td><ReactAudioPlayer src={ getVideo(item._id)} controls controlsList='play' /></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

ChapterModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default ChapterModal
