import { call, put, takeEvery } from "redux-saga/effects"
import { GET_ORDERS, GET_ORDERS_SUCCESS, GET_ORDERS_FAIL, GET_ORDER_UPDATE, GET_SETTLED_ORDER_UPDATE, GET_ORDER_ITEMS_UPDATE, GET_CANCELLED_ORDER_ITMES } from "./actionTypes"
import { getCancelledOrderItemsFail, getCancelledOrderItemsSuccess, getOrders, getOrdersFail, getOrdersSuccess, getSettledOrderFail, getSettledOrderSuccess, updateOrderFail, updateOrderItemsFail, updateOrderItemsSuccess, updateOrderSuccess } from "./action"

import { getCancelledOrderItems, getOrdersList,getsettleOrder,updateOrderItems,updateStatuOrders } from "../../helpers/orders_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


function* fetchOrders({ payload: filterdata }) {
    try {
        const response = yield call(getOrdersList, filterdata)
        yield put(getOrdersSuccess(response.orders))
    } catch (error) {
        yield put(getOrdersFail(error))
    }
}

function* fetchCancelledOrderItems({ payload: filterdata }) {
    try {
        const response = yield call(getCancelledOrderItems, filterdata)
        yield put(getCancelledOrderItemsSuccess(response.orders))
    } catch (error) {
        yield put(getCancelledOrderItemsFail(error))
    }
}

function* fetchSettledOrders({ payload: filterdata }) {
    try {
        const response = yield call(getsettleOrder, filterdata)
        yield put(getSettledOrderSuccess(response.orders))
    } catch (error) {
        yield put(getSettledOrderFail(error))
    }
}

function* onUpdateOrder({ payload: order }) {
    try {
        const response = yield call(updateStatuOrders, order)
        yield put(updateOrderSuccess(order))
        toast.success("Updated Successfully", { autoClose: 2000 });
    } catch (error) {
        yield put(updateOrderFail(error))
        toast.error("Updated Failed", { autoClose: 2000 });
    }
}
function* onUpdateOrderItems({ payload: order }) {
    try {
        const response = yield call(updateOrderItems, order)
        yield put(updateOrderItemsSuccess(order))
        toast.success("Updated Successfully", { autoClose: 2000 });
    } catch (error) {
        yield put(updateOrderItemsFail(error))
        toast.error("Updated Failed", { autoClose: 2000 });
    }
}
function* ordersSaga() {
    yield takeEvery(GET_ORDERS, fetchOrders)
    yield takeEvery(GET_CANCELLED_ORDER_ITMES, fetchCancelledOrderItems)
    yield takeEvery(GET_ORDER_UPDATE, onUpdateOrder)
    yield takeEvery(GET_ORDER_ITEMS_UPDATE, onUpdateOrderItems)
    yield takeEvery(GET_SETTLED_ORDER_UPDATE, fetchSettledOrders)
}

export default ordersSaga;