import React, { useEffect, useState, useRef, useMemo } from "react";
import withRouter from "../../components/Common/withRouter";
import { Col, Container, Row } from "reactstrap";
import VideoUpload from "./VideoUpload";
import Breadcrumb from "components/Common/Breadcrumb";
import {
    getHome as onGetHome,
} from "../../store/home/actions";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import ImageUpload from "./ImageUpload";
import Spinners from "components/Common/Spinner";
import VideoImageUpload from "./VideoImageUpload";
import TagsCheckBox from "pages/Home/TagsCheckBox";

const Home = props => {
    const dispatch = useDispatch();
    const [home, setHome] = useState();
    const [selectedTags, setselectedTags] = useState([])
    const selectContactsState = (state) => state.homes;
    const ContactsProperties = createSelector(
        selectContactsState,
        (_home) => ({
            homes: _home.home,
            loading: _home.loading
        })
    );

    const {
        homes, loading
    } = useSelector(ContactsProperties);
    useEffect(() => {
        if (homes && !homes.length) {
            dispatch(onGetHome());
        }
    }, [dispatch]);

    useEffect(() => {
        setHome(homes);
    }, [homes]);

    const [isLoading, setLoading] = useState(loading)

    return (
        loading ? <Spinners setLoading={setLoading} />
            :
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumb breadcrumbItem="Home" />
                    <Row>
                        <Col sm={4}>
                            <VideoUpload home={home} />
                        </Col>
                        <Col sm={4}>
                            <VideoImageUpload home={home} />
                        </Col>
                        <Col sm={4}>
                            <ImageUpload home={home} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <TagsCheckBox home={home} />
                        </Col>
                    </Row>
                </Container>
            </div>
    )
}

export default withRouter(Home);