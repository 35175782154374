/* USERS */
export const GET_CATEGORYS = "GET_CATEGORYS"
export const GET_CATEGORYS_SUCCESS = "GET_CATEGORYS_SUCCESS"
export const GET_CATEGORYS_FAIL = "GET_CATEGORYS_FAIL"

/* CATEGORS PROFILE */
export const GET_CATEGORY = "GET_CATEGORY"
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS"
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL"

/**
 * add user
 */
export const ADD_NEW_CATEGORY = "ADD_NEW_CATEGORY"
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS"
export const ADD_CATEGORY_FAIL = "ADD_CATEGORY_FAIL"

/**
 * Edit user
 */
export const UPDATE_CATEGORY = "UPDATE_CATEGORY"
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS"
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL"

/**
 * Delete user
 */
export const DELETE_CATEGORY = "DELETE_CATEGORY"
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS"
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL"

export const GET_CATEGORYSCOMBO = "GET_CATEGORYSCOMBO"
export const GET_CATEGORYSCOMBO_FAIL = "GET_CATEGORYSCOMBO_FAIL"
export const GET_CATEGORYSCOMBO_SUCCESS = "GET_CATEGORYSCOMBO_SUCCESS"

export const UPDATE_STATUS_CATEGORY = "UPDATE_STATUS_CATEGORY"
export const UPDATE_STATUS_CATEGORY_SUCCESS = "UPDATE_STATUS_CATEGORY_SUCCESS"
export const UPDATE_STATUS_CATEGORY_FAIL = "UPDATE_STATUS_CATEGORY_FAIL"