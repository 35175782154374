import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { ADD_NEW_HOME, DELETE_HOME, GET_HOME, UPDATE_HOME  } from "./actionTypes"

import {
  getHomeSuccess,
  getHomeFail,
  addHomeFail,
  addHomeSuccess,
  updateHomeSuccess,
  updateHomeFail,
  deleteHomeSuccess,
  deleteHomeFail,
  getHomeComboSuccess,
  getHomeComboFail
} from "./actions"

//Include Both Helper File with needed methods
import { getHome, addHome, updateHome, deleteHome, getHomeCombo } from "../../helpers/home_helper"

// toast
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';



function* fetchHome() {
  try {
    const response = yield call(getHome)
    yield put(getHomeSuccess(response.home))
  } catch (error) {
    yield put(getHomeFail(error))
  }
}


function* onUpdateHome({ payload: home }) {
  try {
    const response = yield call(updateHome, home)
    yield put(updateHomeSuccess(response.home))
    toast.success("Updated Successfully", { autoClose: 2000 });
  } catch (error) {
    console.log(error)
    yield put(updateHomeFail(error))
    toast.error("Updated Failed", { autoClose: 2000 });
  }
}

function* onDeleteHome({ payload: home }) {
  try {
    const response = yield call(deleteHome, home)
    
    yield put(deleteHomeSuccess(response.home._id))
    toast.success("Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteHomeFail(error))
    toast.error("Deleted Failed", { autoClose: 2000 });
  }
}

function* onAddHome({ payload: home }) {
  try {
    const response = yield call(addHome, home)
    yield put(addHomeSuccess(response.home))
    toast.success("Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addHomeFail(error))
    toast.error("Added Failed", { autoClose: 2000 });
  }
}

function* homeSaga() {
  yield takeEvery(GET_HOME, fetchHome)
  yield takeEvery(ADD_NEW_HOME, onAddHome)
  yield takeEvery(UPDATE_HOME, onUpdateHome)
  yield takeEvery(DELETE_HOME, onDeleteHome)
}

export default homeSaga;
