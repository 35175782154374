import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import TableContainer from "../../components/Common/TableContainer";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    UncontrolledTooltip,
    Input,
    Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";

import {
    getStorys as onGetStory,
    addNewStory as onAddNewStory,
    updateStory as onUpdateStory,
    deleteStory as onDeleteStory,
} from "../../store/story/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { CustomBadge } from "pages/Subscription/SubscriptionCol";
import ChapterModal from "./components/ChaptersModal";
import moment from 'moment';

const AlbumList = props => {

    //meta title
    document.title = "KIDS STORY TELLING (KST) - Stories";
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [story, setStory] = useState();
    const [modal1, setModal1] = useState(false);
    const [selectedChapters, setSelectedChapters] = useState([]);

    const toggleViewModal = (story) => {
        setModal1(!modal1);
        setSelectedChapters(story.chapters)
    }
    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: (story && story.name) || "",
            designation: (story && story.designation) || "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
            designation: Yup.string(),
        }),
        onSubmit: values => {
            if (isEdit) {
                const updateStory = {
                    id: story.id,
                    name: values.name,
                    designation: values.designation,
                };
                // update user
                dispatch(onUpdateStory(updateStory));
                setIsEdit(false);
                validation.resetForm();
            } else {
                const newStory = {
                    id: Math.floor(Math.random() * (30 - 20)) + 20,
                    name: values["name"],
                    designation: values["designation"],
                };
                // save new user
                dispatch(onAddNewStory(newStory));
                validation.resetForm();
            }
            toggle();
        },
    });

    const selectStorysState = (state) => state.storys;
    const StorysProperties = createSelector(
        selectStorysState,
        (Storys) => ({
            stories: Storys.stories,
            loading: Storys.loading
        })
    );

    const {
        stories, loading
    } = useSelector(StorysProperties);

    const [userList, setStoryList] = useState([]);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setLoading] = useState(loading)

    const columns = useMemo(
        () => [
            {
                Header: "#",
                // accessor: "name",
                disableFilters: true,
                filterable: true,
                accessor: (cellProps) => (
                    <div className="avatar-xs">
                        <span className="avatar-title rounded-circle">
                            {cellProps.name.charAt(0)}
                        </span>
                    </div>
                ),
            },
            {
                Header: "Story Name",
                accessor: "name",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.name}</Link>
                            </h5>
                            <p className="text-muted mb-0">{cellProps.row.original.designation}</p>
                        </>
                    )
                },
            },
            {
                Header: "Story",
                accessor: "category",
                filterable: true,
                Cell: cellProps => {

                    return <CustomBadge color="info" {...cellProps} />;
                },
            },
            {
                Header: "Chapters",
                accessor: "chapters",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-primary text-decoration-underline"
                                onClick={() => { toggleViewModal(cellProps.row.original) }}
                            >
                                Click to View
                            </Link>
                        </div>
                    )
                }
            },
            {
                Header: "Age",
                accessor: "age",
                filterable: true,
            },
            // {
            //     Header: "Status",
            //     accessor: "status",
            //     filterable: true,
            //     Cell: cellProps => {
            //         return <CustomBadge color="warning" {...cellProps} />;
            //     },

            // },

            {
                Header: "Created By",
                accessor: "createdBy",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.createdBy?.name}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Created Date",
                accessor: "createdAt",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{moment(cellProps.row.original.createdAt).format('MMM D, YYYY')}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Action",
                Cell: cellProps => {
                    return (
                        <div className="d-flex gap-3">

                            <Link
                                to={"/create-story/" + cellProps.row.original._id}
                                className="text-success"
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const userData = cellProps.row.original;
                                    onClickDelete(userData);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                },
            },
        ],
        []
    );

    useEffect(() => {
        // if (stories && !stories.length) {
            dispatch(onGetStory());
            setIsEdit(false);
        // }
    }, [dispatch]);

    useEffect(() => {
        setStory(stories);
        setIsEdit(false);
    }, [stories]);

    useEffect(() => {
        if (!isEmpty(stories) && !!isEdit) {
            setStory(stories);
            setIsEdit(false);
        }
    }, [stories]);

    const toggle = () => {
        setModal(!modal);
    };

    const handleStoryClick = arg => {
        const user = arg;
        navigate('/create-story/' + user.id)
        // setStory({
        //     id: user.id,
        //     name: user.name,
        //     designation: user.designation,
        // });
        // setIsEdit(true);

        // toggle();
    };

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    //delete customer
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = users => {
        setStory(users);
        setDeleteModal(true);
    };

    const handleDeleteStory = () => {
        if (story && story._id) {
            dispatch(onDeleteStory(story._id));
        }
        onPaginationPageChange(1);
        setDeleteModal(false);
    };

    const handleStoryClicks = () => {
        setStoryList("");
        setIsEdit(false);
        toggle();
    };

    const keyField = "id";

    return (
        loading ? <Spinners setLoading={setLoading} />
            :
            <React.Fragment>
                <ChapterModal isOpen={modal1} chapters={selectedChapters} toggle={toggleViewModal} />
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteStory}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Album" breadcrumbItem="Stories" />
                        <Row>

                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <TableContainer
                                            isPagination={true}
                                            columns={columns}
                                            data={stories}
                                            isGlobalFilter={true}
                                            isShowingPageLength={true}
                                            iscustomPageSizeOptions={true}
                                            handleUserClick={handleStoryClicks}
                                            customPageSize={10}
                                            tableClass="table align-middle table-nowrap table-hover"
                                            theadClass="table-light"
                                            paginationDiv="col-sm-12 col-md-7"
                                            pagination="pagination pagination-rounded justify-content-end mt-4"
                                        />
                                    </CardBody>
                                </Card>
                            </Col>


                            <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? "Edit Tag" : "Add Tag"}
                                </ModalHeader>
                                <ModalBody>
                                    <Form
                                        onSubmit={e => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row>
                                            <Col xs={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Name</Label>
                                                    <Input
                                                        name="name"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.name || ""}
                                                        invalid={
                                                            validation.touched.name &&
                                                                validation.errors.name
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.name &&
                                                        validation.errors.name ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.name}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="text-end">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-success save-user"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </ModalBody>
                            </Modal>
                        </Row>
                    </Container>
                </div>
                <ToastContainer />
            </React.Fragment>
    );
};

export default withRouter(AlbumList);
