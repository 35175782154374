import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Form,
    Input,
    Label,
    Row,
    CardTitle,
    Button,
    FormFeedback
} from "reactstrap"

import Select from "react-select";
import CategorySelect from "components/Common/CategorySelect";
import TagSelect from "components/Common/TagSelect";
import * as Yup from "yup";
import { useFormik } from "formik";
import ImageBox from "components/Common/ImageBox";
import withRouter from "../../../components/Common/withRouter";
import {
    addNewStory as onAddNewStory,
    updateStory as onUpdateStory,
    deleteStoryImage
} from "../../../store/story/actions";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import Spinners from "components/Common/Spinner";
import { useNavigate } from 'react-router-dom';

const CreateStory = ({ story, loading, isEdit }) => {
    const [selectedFiles, setselectedFiles] = useState([])
    const [selectedCategory, setselectedCategory] = useState(null)
    const [selectedTags, setselectedTags] = useState([])
    const [selectedAge, setselectedAge] = useState(null)
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [selectedCategories, setselectedCategories] = useState(null)
    const [selectedTagss, setselectedTagss] = useState([])
    const [selectedAges, setselectedAges] = useState(null)
    const [isLoading, setLoading] = useState(loading)

    // const [isEdit, setIsEdit] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ages = [
        { value: "1-3", label: "1-3" },
        { value: "4-6", label: "4-6" },
        { value: "7-10", label: "7-10" },
        { value: "11-13", label: "11-13" },
        { value: "14-16", label: "14-16" },
    ]

    const changeTag = (val) => {

        validation.setFieldValue("tags", val)
        setselectedTags(val)
    }

    const changeCategory = (val) => {
        validation.setFieldValue("category", val.value)
        setselectedCategory(val.value)
    }

    const handleAge = (val) => {
        validation.setFieldValue("age", val.value)
        setselectedAges(val)
    }



    const isBlob = (obj) => {
        return obj instanceof Blob;
    }
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: (story && story.name) || "",
            description: (story && story.description) || "",
            price: (story && story.price) || "",
            image: (story && story.image) || "",
            category: (story && story.category?._id) || "",
            age: (story && story.age) || "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
            price: Yup.string().required("Please Enter Price"),
            image: Yup.string().required("Story Image required"),
            category: Yup.string().required("Please select category"),
            age: Yup.string().required("Please select age"),
            tags: Yup.array().min(1, "Please select tag")
        }),
        onSubmit: values => {
            if (isEdit) {
                console.log(selectedAges)
                const formData = new FormData();
                formData.append('name', validation.values.name);
                formData.append('description', validation.values.description);
                formData.append('age', selectedAges.value);
                formData.append('price', validation.values.price);
                formData.append('category', selectedCategory);

                selectedTags.forEach((value, index) => {
                    formData.append(`tags[${index}]`, value.value);
                });
                if (selectedFiles.length != 0) {
                    if (isBlob(selectedFiles[0]))
                        formData.append('image', selectedFiles[0], `image.png`);
                    else
                        formData.append('image', 'No Change');
                }
                else
                    formData.append('image', '');
                let _story = {
                    formData: formData,
                    id: story.id
                }
                dispatch(onUpdateStory({ data: _story, navigate: navigate }))

            } else {
                const formData = new FormData();
                formData.append('name', validation.values.name);
                formData.append('description', validation.values.description);
                formData.append('age', selectedAges.value);
                formData.append('price', validation.values.price);
                formData.append('category', selectedCategory);
                selectedTags.forEach((value, index) => {
                    formData.append(`tags[${index}]`, value.value);
                });

                if (selectedFiles.length != 0)
                    formData.append('image', selectedFiles[0], `image.png`);
                dispatch(onAddNewStory({ data: formData, navigate: navigate }));
                setselectedFiles([])
                setselectedTags([])
                setselectedCategory(null)
                setselectedAge(null)
                validation.resetForm();
            }
        },
    });

    useEffect(() => {
        if (Object.keys(story).length !== 0) {
            // setIsEdit(true)

            setselectedCategories(story.category._id)
            setselectedCategory(story.category._id)
            setselectedTagss(story.tags)
            let tags = story.tags.map((x) => { return { label: x.name, value: x._id } })
            setselectedTags(tags)
            const age = ages.find((option) => option.value === story.age);
            setselectedAges(age)
            setselectedAge(age)

            if (story.image)
                setselectedFiles([story.image])

            validation.initialValues = {
                name: (story && story.name) || "",
                description: (story && story.description) || "",
                price: (story && story.price) || "",
                image: (story && story.image) || "",
                category: (story && story.category._id) || "",
                age: (story && story.age) || "",
            }

        } else {
            setselectedFiles([])
            setselectedAges([])
            setselectedAge([])
            setselectedTagss([])
            setselectedTags([])
            setselectedCategory(null)
            setselectedCategories(null)
            validation.resetForm();
        }
    }, [story]);
    const onRemoveChange = (val) => {
        validation.setFieldValue("image", "")
    }
    const onFileChange = (val) => {
        validation.setFieldValue("image", val)
    }

    return (
        loading ? <Spinners setLoading={setLoading} /> :
            <React.Fragment>


                <Card>
                    <CardBody>
                        <CardTitle>Story Details</CardTitle>
                        <p className="card-title-desc mb-4">
                            Fill all information below
                        </p>
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            setIsSubmitted(true)
                            validation.handleSubmit();
                            return false;
                        }}>
                            <Row>
                                <Col sm="6">
                                    <div className="mb-3">
                                        <Label htmlFor="name">Story Name</Label>
                                        <Input
                                            id="name"
                                            name="name"
                                            type="text"
                                            className="form-control"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.name || ""}
                                            invalid={
                                                validation.touched.name &&
                                                    validation.errors.name
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.name &&
                                            validation.errors.name ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.name}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                    <div className="mb-3">
                                        <Label htmlFor="description">
                                            Description
                                        </Label>
                                        <Input
                                            className="form-control mb-3"
                                            id="description"
                                            rows="5"
                                            type="textarea"
                                            placeholder="Description"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.description || ""}
                                            invalid={
                                                validation.touched.description &&
                                                    validation.errors.description
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.description &&
                                            validation.errors.description ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.description}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                    <div className="mb-3">
                                        <Label className="control-label">Age</Label>
                                        <Select
                                            classNamePrefix="select2-selection"
                                            placeholder="Choose..."
                                            title="Country"
                                            options={ages}
                                            value={selectedAges}
                                            onChange={handleAge}
                                        />
                                        {
                                            validation.errors.age ?
                                                <FormFeedback type="invalid" style={{ display: 'block' }}>
                                                    {validation.errors.age}
                                                </FormFeedback> : null
                                        }
                                    </div>
                                </Col>

                                <Col sm="6">
                                    <div className="mb-3">
                                        <Label>Select Category</Label>
                                        <CategorySelect isSubmitted={isSubmitted} error={validation.errors.category} defaultValue={selectedCategories} OnSelect={changeCategory}></CategorySelect>
                                    </div>
                                    <div className="mb-3">
                                        <Label className="control-label">Tags</Label>

                                        <TagSelect  isSubmitted={isSubmitted} error={validation.errors.tags} defaultValue={selectedTagss} OnSelect={changeTag}></TagSelect>
                                    </div>
                                    <div className="mb-3">
                                        <Label htmlFor="price">Price</Label>
                                        <Input
                                            id="price"
                                            name="price"
                                            type="text"
                                            className="form-control"
                                            placeholder="Price"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.price || ""}
                                            invalid={
                                                validation.touched.price &&
                                                    validation.errors.price
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.price &&
                                            validation.errors.price ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.price}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={{ size: 4, offset: 4 }} className="mt-3">
                                    <ImageBox isSubmitted={isSubmitted} onRemoveChange={onRemoveChange} Header={"Upload Story Image"} error={validation.errors.image} onFileChange={onFileChange} selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} allowImages={1} />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} className="text-center gap-2">
                                    <Button type="submit" color="primary" className="btn mx-2">
                                        Save Changes
                                    </Button>
                                </Col>
                            </Row>

                        </Form>
                    </CardBody>
                </Card>
                <ToastContainer />

            </React.Fragment>
    );
}

export default withRouter(CreateStory);