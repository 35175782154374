/* USERS */
export const GET_CHAPTERS = "GET_CHAPTERS"
export const GET_CHAPTERS_SUCCESS = "GET_CHAPTERS_SUCCESS"
export const GET_CHAPTERS_FAIL = "GET_CHAPTERS_FAIL"

/* CATEGORS PROFILE */
export const GET_CHAPTER = "GET_CHAPTER"
export const GET_CHAPTER_SUCCESS = "GET_CHAPTER_SUCCESS"
export const GET_CHAPTER_FAIL = "GET_CHAPTER_FAIL"

/**
 * add user
 */
export const ADD_NEW_CHAPTER = "ADD_NEW_CHAPTER"
export const ADD_CHAPTER_SUCCESS = "ADD_CHAPTER_SUCCESS"
export const ADD_CHAPTER_FAIL = "ADD_CHAPTER_FAIL"

/**
 * Edit user
 */
export const UPDATE_CHAPTER = "UPDATE_CHAPTER"
export const UPDATE_CHAPTER_SUCCESS = "UPDATE_CHAPTER_SUCCESS"
export const UPDATE_CHAPTER_FAIL = "UPDATE_CHAPTER_FAIL"

/**
 * Delete user
 */
export const DELETE_CHAPTER = "DELETE_CHAPTER"
export const DELETE_CHAPTER_SUCCESS = "DELETE_CHAPTER_SUCCESS"
export const DELETE_CHAPTER_FAIL = "DELETE_CHAPTER_FAIL"

export const GET_CHAPTER_STORY = "GET_CHAPTER_STORY"
export const GET_CHAPTER_STORY_FAIL = "GET_CHAPTER_STORY_FAIL"
export const GET_CHAPTER_STORY_SUCCESS = "GET_CHAPTER_STORY_SUCCESS"
