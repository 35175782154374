import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from "moment";
import { Badge } from 'reactstrap';
import { size, map } from "lodash";
import ReactAudioPlayer from 'react-audio-player';
import audio from "../../assets/sample.mp3";

const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};
const toLowerCase1 = str => {
    return (
      str === "" || str === undefined ? "" : str.toLowerCase()
    );
  };

const CheckBox = (cell) => {
    return cell.value ? cell.value : '';
};

const OrderId = (cell) => {
    return (
        <Link to="#" className="text-body fw-bold">{cell.value ? cell.value : ''}</Link>
    );
};

const BillingName = (cell) => {
    return cell.value ? cell.value : '';
};

const Date = (cell) => {
    return cell.value ? moment(cell.value).format('MMM D, YYYY') : '';
};

const Total = (cell) => {
    return cell.value ? cell.value : '';
};

const PaymentStatus = (cell) => {
    return (
        <Badge
          className={"font-size-11 badge-soft-" + 
          (cell.value === "Paid" ? "success" : "danger" && cell.value === "Refund" ? "warning" : "danger")}          
        >
          {cell.value}
        </Badge>
    )
};

const PlansBadge = (cell) => {
    return (
        <Badge
          className={"font-size-12 badge-soft-success"}
        >
          {cell.value} Months
        </Badge>
    )
};

const CustomBadge = (cell) => {
    return (
        <Badge
          className={"font-size-13 badge-soft-"+cell.color}
        >
          {cell.value.name}
        </Badge>
    )
};

const AlbumsBadge = (cell) => {
    return (
        <>
            {map(
                cell.value,
                (tag, index) =>
                    index < 2 && (
                        <Link
                            to="#"
                            className="badge badge-soft-primary font-size-11 m-1"
                            key={"_skill_" + cell.value + index}
                        >
                            {tag.name}
                        </Link>

                    )
            )}
            {size(cell.value) > 2 && (
                <Link
                    to="#"
                    className="badge badge-soft-primary font-size-11 m-1"
                    key={"_skill_" + cell.value}
                >
                    {size(cell.value) - 1} + more
                </Link>
            )}
        </>
    );
};
const TagsBadge = (cell) => {
    return (
        <>
            {map(
                cell.value,
                (tag, index) =>
                    index < 2 && (
                        <Link
                            to="#"
                            className="badge badge-soft-danger font-size-11 m-1"
                            key={"_skill_" + cell.value + index}
                        >
                            {tag}
                        </Link>

                    )
            )}
            {size(cell.value) > 2 && (
                <Link
                    to="#"
                    className="badge badge-soft-danger font-size-11 m-1"
                    key={"_skill_" + cell.value}
                >
                    {size(cell.value) - 1} + more
                </Link>
            )}
        </>
    );
};
const AudioFile = (cell) => {
    return (
        <ReactAudioPlayer src={cell.value} controls controlsList='play'/>
    )
};
const PaymentMethod = (cell) => {
    return (
        <span>
        <i
        className={
          (cell.value === "Paypal" ? "fab fa-cc-paypal me-1" : "" || 
          cell.value === "COD" ? "fab fas fa-money-bill-alt me-1" : "" ||
          cell.value === "Mastercard" ? "fab fa-cc-mastercard me-1" : "" ||
          cell.value === "Visa" ? "fab fa-cc-visa me-1" : ""
          )}
          />{" "}
            {cell.value}
        </span>
    )
};
export {
    CheckBox,
    OrderId,
    BillingName,
    Date,
    Total,
    PaymentStatus,
    PaymentMethod,
    PlansBadge,
    AlbumsBadge,
    TagsBadge,
    AudioFile,
    CustomBadge
};