import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import TableContainer from "../../components/Common/TableContainer";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    UncontrolledTooltip,
    Input,
    Form,
    Button,
    FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";

import {
    getOrders, updateOrder as modifyOrder, updateOrderItems
} from "../../store/order/action";
import { isEmpty, keys } from "lodash";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import cashLogo from '../../assets/images/cash.png';
import phonepe from '../../assets/images/phonepe.png';
import googlepay from '../../assets/images/googlepay.png';
import upi from '../../assets/images/upi.png';
import creditcard from '../../assets/images/creditcard.jpg';
import debit from '../../assets/images/debit.png';
import { posPrint } from "services/printerservice";
import ReactSelect from "react-select";
import DatePicker from "react-datepicker";
import axios from "axios";
import axiosApi from "helpers/api_helper";
import { validateCoupanCode } from "helpers/orders_helper";
import CoupanPopup from "./components/CoupanPopup";

const TablePayment = props => {

    //meta title
    document.title = "Mythreya - Table Payments";

    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [order, setOrder] = useState();
    const [selectedItem, setSelectedItem] = useState(null);
    const [coupanCode, setCoupanCode] = useState("");
    const [setIsCoupan, isCoupan] = useState(false);

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            tip: (order && order.tip) || 0,
            settled_amount: (order && order.settled_amount) || 0,
            paid_amount: (order && order.paid_amount) || 0,
            cash: (order && order.cash) || 0,
            upi: (order && order.upi) || 0,
            card: (order && order.card) || 0,
        },
        validationSchema: Yup.object({
            tip: Yup.string(),
            settled_amount: Yup.string(),
            cash: Yup.string(),
            upi: Yup.string(),
            card: Yup.string(),
            paid_amount: Yup.string().required("Please Enter Name"),
        }),
        onSubmit: values => {
            let paid = parseInt(values.cash) + parseInt(values.card) + parseInt(values.upi);
            let settled = paid - parseInt(values.tip);

            if (settled == order.payable_amount) {

                const updatedOrder = {
                    id: order.id,
                    tip: values.tip,
                    settled_amount: settled,
                    paid_amount: paid,
                    cash: values.cash,
                    upi: values.upi,
                    card: values.card,
                    payment_mode: ((values.cash != 0) ? "CASH " : "") + ((values.upi != 0) ? "UPI " : "") + ((values.card != 0) ? "CARD " : "")
                };
                dispatch(modifyOrder(updatedOrder));
                setIsEdit(false);
                validation.resetForm();

                toggle();
            } else {
                toast.success("Invalid Paid Amount or Settled Amount", { autoClose: 2000 });
            }

        },
    });

    const selectStoreCategoryState = (state) => state.orders;
    const StoreCategoryProperties = createSelector(
        selectStoreCategoryState,
        (Order) => ({
            orders: Order.orders,
            loading: Order.loading
        })
    );

    const {
        orders, loading
    } = useSelector(StoreCategoryProperties);

    const [modal, setModal] = useState(false);
    const [modalUpdate, setModalUpdate] = useState(false);
    const [modalPrint, setModalPrint] = useState(false);
    const [openCoupan, setOpenCoupan] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setLoading] = useState(loading)
    const [discount, setDiscount] = useState(0)
    const [title, setTitle] = useState("DINEIN")
    const [orderList, setStoreCategoryList] = useState([]);
    const [paymentMode, setPaymentMode] = useState("CASH");
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [errorText, setErrorText] = useState("");

    const columns = useMemo(
        () => [

            {
                Header: "Order Date",
                accessor: "order_date",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{moment(cellProps.row.original.order_date).format('MMM D, YYYY hh:mm A')}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Order ID",
                accessor: "order_id",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.order_id}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Outlet",
                accessor: "tag",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.tag + " / " + ((cellProps.row.original.tableNo != "") ? ("Table -" + cellProps.row.original.tableNo) : "")}</Link>
                            </h5>
                        </>
                    )
                },
            },
            // {
            //     Header: "Payment Mode",
            //     accessor: "payment_mode",
            //     filterable: true,
            //     Cell: cellProps => {
            //         return (
            //             <>
            //                 <h5 className="font-size-14 mb-1">
            //                     <Link className="text-dark" to="#">{cellProps.row.original.payment_mode}</Link>
            //                 </h5>
            //             </>
            //         )
            //     },
            // },
            {
                Header: "Amount",
                accessor: "total_amount",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.total_amount.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Discount",
                accessor: "discount",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.total_amount.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Payable Amount",
                accessor: "payable_amount",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.total_amount.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Action",
                Cell: cellProps => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-success btn btn-primary text-white"
                                onClick={() => {
                                    const item = cellProps.row.original;
                                    handleStoreCategoryClick(item);
                                }}
                            >
                                Confirm
                            </Link>
                            <Link
                                to="#"
                                className="text-success btn btn-success text-white"
                                onClick={() => {
                                    const item = cellProps.row.original;
                                    updateOrder(item);
                                }}
                            >
                                Update Item
                            </Link>
                            <Link
                                to="#"
                                className="text-success btn btn-warning text-white"
                                onClick={() => {
                                    setSelectedItem(cellProps.row.original)
                                    printPopup()
                                    // printOrder(item, "RECEIPT");
                                }}
                            >
                                Print Bill
                            </Link>
                            {/* <Link
                                to="#"
                                className="text-success btn btn-danger text-white"
                                onClick={() => {
                                    // const item = cellProps.row.original;
                                    setSelectedItem(cellProps.row.original)
                                    printPopup()
                                    // printOrder(item, "CUSTOMER COPY");
                                }}
                            >
                                Customer Copy
                            </Link> */}
                            <Link
                                to="#"
                                className="text-success btn btn-info text-white"
                                onClick={() => {
                                    const item = cellProps.row.original;
                                    getCoupan(item);

                                }}
                            >
                                Get Coupan
                            </Link>

                        </div>
                    );
                },
            },
        ],
        []
    );

    useEffect(() => {
        if (orders && !orders.length) {
            dispatch(getOrders({ counter: "DINEIN", settlement_status: "PENDING" }));
            setIsEdit(false);
        }
    }, [dispatch]);

    useEffect(() => {
        setOrder(orders);
        setIsEdit(false);
    }, [orders]);

    useEffect(() => {
        if (!isEmpty(orders) && !!isEdit) {
            setOrder(orders);
            setIsEdit(false);
        }
    }, [orders]);

    const toggle = () => {
        setModal(!modal);
    };
    const toggleUpdate = () => {
        setModalUpdate(!modalUpdate);
    };

    const handleStoreCategoryClick = arg => {
        setOrder(arg);
        setIsEdit(true);

        toggle();
    };

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    //delete customer
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = orders => {
        setOrder(orders);
        setDeleteModal(true);
    };

    const handleDeleteStoreCategory = () => {
        if (order && order.id) {
            dispatch(onDeleteStoreCategory(order.id));
        }
        onPaginationPageChange(1);
        setDeleteModal(false);
    };

    const handleStoreCategoryClicks = () => {
        // setStoreCategoryList("");
        // setIsEdit(false);
        // toggle();
        dispatch(getOrders({ selectedDate, counter: title, settlement_status: "PENDING" }));
    };

    const handleDateChange = (date) => {
        setSelectedDate(date)
        dispatch(getOrders({ selectedDate: date, counter: title, settlement_status: "PENDING" }));
    }

    const keyField = "id";

    const changeTab = (type) => {
        setTitle(type)
        dispatch(getOrders({ selectedDate, counter: type, settlement_status: "PENDING" }));
    }

    const changePayment = (payment) => {
        setPaymentMode(payment)
    }

    const updateOrder = (item) => {
        // posPrint(item.pos, item.text, "R")
        setOrder(item);
        setIsEdit(true);

        toggleUpdate();
    }

    const printOrder = (item, copy) => {
        setModalPrint(false);
        "[C]<font size='big'>Mythreya</font>\n[C]<font size='big'>Multicuisine Restuarent</font>\n[C]Mallaya Doddi,Opp. HP Petrol Bunk, Ravulapadu (V)\n[C] Ravulapalem - 533238. Ph : +91 6300585325 \n[C] GSTIN No.: 37ABFFP0410M1ZA \n[C] FASSAI Code : 10124017000070 \n"
        let text = item.text.replace("[C]<img>https://api.mythreyarestaurant.com/print/printlogo.bmp</img>\n[C]<font size='big'>Mythreya</font>\n[C]<font size='big'>Multicuisine Restuarent</font>\n[C]Mallaya Doddi,Opp. HP Petrol Bunk, Ravulapadu (V)\n[C] Ravulapalem - 533238. Ph : +91 6300585325 \n", "");
        axios.post("http://localhost:8080/printer-service/print", { data: text, copy: copy, ip: item.ip }).then((response) => {
            console.log(response)
        }).catch(error => {
            console.log(error)
        })
    }

    const togglePrint = () => {
        setModalPrint(!modalPrint);
    };
    const printPopup = () => {
        // setSelectedItem(item);
        setModalPrint(true);
    }

    const remove = (item) => {
        const order_ = order.items.map((x) => {
            if (x._id == item._id) {
                x.qty -= 1;
                x.netprice = x.price * x.qty;
            }
            return x;
        })
        setOrder({ ...order, items: order_, total_amount: order_.reduce((acc, order) => (order.basic == "REGULAR") ? acc + order.netprice : 0, 0) })
    }

    const add = (item) => {
        const order_ = order.items.map((x) => {
            if (x._id == item._id) {
                x.qty += 1;
                x.netprice = x.price * x.qty;
            }
            return x;
        })
        setOrder({ ...order, items: order_, total_amount: order_.reduce((acc, order) => (order.basic == "REGULAR") ? acc + order.netprice : 0, 0) })
    }

    const onSelectOption = (item, value) => {
        const order_ = order.items.map((x) => {
            if (x._id == item._id) {
                x.basic = value
            }
            return x;
        })

        setOrder({ ...order, items: order_, total_amount: order_.reduce((acc, order) => (order.basic == "REGULAR") ? acc + order.netprice : 0, 0) })
    }

    const updateItems = () => {
        dispatch(updateOrderItems(order));
        toggleUpdate();
    }

    const getCoupan = async (order) => {
        setSelectedOrder(order)
        setOpenCoupan(true)
    }
    const removeCoupan = async () => {
        setOrder({ ...order, discount: "", code: "", payable_amount: order.total_amount })
    }
    const validateCoupan = async() => {
        setLoading(true)
        await validateCoupanCode(coupanCode, "Food").then((data) => {
            setLoading(false)
            setErrorText("")
            console.log(data)
            if (data.error == "") {
                if (order.total_amount >= data.minimum) {
                    setOrder({ ...order, discount: data.discount, code: coupanCode, payable_amount: Math.round(order.total_amount - ((order.total_amount * data.discount) / 100)) })
                } else {
                    setErrorText("Coupan minimum order amount Rs." + data.minimum.toLocaleString('en-IN', { minimumFractionDigits: 2 }))
                }

            } else {
                setErrorText(data.error)
            }
        }).catch((error) => {
            setLoading(false)
        })
    }

    return (
        loading ? <Spinners setLoading={setLoading} />
            :
            <React.Fragment>
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteStoreCategory}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}

                        <Row>
                            {
                                isLoading ? <Spinners setLoading={setLoading} />
                                    :

                                    <Col lg="12">
                                        <Card>
                                            <CardBody className="d-flex flex-row justify-content-between align-items-center p-0">
                                                <div>
                                                    <Label>
                                                        Select Date
                                                    </Label>
                                                    <DatePicker
                                                        selected={selectedDate}
                                                        onChange={handleDateChange}
                                                        dateFormat="dd/MM/yyyy"
                                                        def
                                                        placeholderText="Select a date"
                                                    />
                                                </div>
                                                <div className="d-flex flex-row">
                                                    <Button size="sm" onClick={() => changeTab('DINEIN')} type="button" color="btn btn-primary mx-2">INDINE</Button>
                                                    <Button size="sm" onClick={() => changeTab('DINEOUT')} type="button" color="btn btn-warning mx-2">DINEOUT</Button>
                                                    <Button size="sm" onClick={() => changeTab('TAKEWAY')} type="button" color="btn btn-success mx-2">TAKEAWAY</Button>
                                                </div>
                                            </CardBody>
                                        </Card>
                                        <Card>
                                            <CardBody>
                                                <Col lg="12" className="h3 text-center justify-content-center">
                                                    {title + " ORDERS"}
                                                    <h3 className="py-2">
                                                        Total Amount : {orders.length && (orders.reduce((acc, order) => acc + order.total_amount, 0)).toLocaleString('en-IN', { minimumFractionDigits: 2 })}
                                                    </h3>
                                                </Col>
                                                <TableContainer
                                                    isPagination={true}
                                                    columns={columns}
                                                    data={orders}
                                                    isGlobalFilter={true}
                                                    isShowingPageLength={false}
                                                    buttonText={"Refresh Orders"}
                                                    isAddUserList={true}
                                                    iscustomPageSizeOptions={false}
                                                    handleUserClick={handleStoreCategoryClicks}
                                                    customPageSize={100}
                                                    tableClass="table align-middle table-nowrap table-hover"
                                                    theadClass="table-light"
                                                    paginationDiv="col-sm-12 col-md-7"
                                                    pagination="pagination pagination-rounded justify-content-end mt-4"
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                            }

                            <Modal size="xl" isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? "Edit Order" : "Add Order"}
                                </ModalHeader>
                                <ModalBody>
                                    <Form
                                        onSubmit={e => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row>
                                            <Col sm={8}>
                                                <Row>
                                                    <Col className="fw-bold fs-5">ITEM</Col><Col className="fw-bold fs-5">QTY</Col><Col className="fw-bold fs-5">PRICE</Col><Col className="fw-bold fs-5">Net Amount</Col>
                                                </Row>

                                                {
                                                    order && order.items && order.items.map((item, index) => {

                                                        return (
                                                            <Row key={index} className={"my-2 align-items-center " + (item.basic != 'REGULAR' ? " border border-2 border-danger " : "")}>
                                                                <Col className="d-flex flex-column">
                                                                    {item.fooditem.name}
                                                                    {item.basic != 'REGULAR' && <span className="text-danger">{item.basic}</span>}
                                                                </Col><Col>{item.qty}</Col><Col>{item.price.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</Col><Col>{item.netprice.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</Col>
                                                            </Row>
                                                        )
                                                    })
                                                }
                                                <Row className="row border-top border-2 border-dark py-2">
                                                    <Col xs={{ offset: 5, size: 4 }} className="h4">
                                                        Total Amount
                                                    </Col>
                                                    <Col xs={{ size: 3 }} className="h4">{order?.total_amount?.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</Col>
                                                </Row>
                                                <Row className="row border-top border-2 border-dark py-2">
                                                    <Col xs={{ offset: 5, size: 4 }} className="h5">
                                                        Discount ({order?.discount + "%"} )
                                                    </Col>
                                                    <Col xs={{ size: 3 }} className="h5">{((order?.discount * order?.total_amount) / 100).toLocaleString('en-IN', { minimumFractionDigits: 2 })}</Col>
                                                </Row>
                                                <Row className="row border-top border-2 border-dark py-2">
                                                    <Col xs={{ offset: 5, size: 4 }} className="h4">
                                                        Payable Amount
                                                    </Col>
                                                    <Col xs={{ size: 3 }} className="h4">{order?.payable_amount?.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</Col>
                                                </Row>
                                            </Col>
                                            <Col sm={4}>
                                                <Row>
                                                    <Col xs={12}>
                                                        <div className="mb-3 d-flex flex-row justify-content-center align-items-center">
                                                            <Col xs={2} >
                                                                <img src={cashLogo} className="image" height={40} />
                                                            </Col>
                                                            <Col xs={8} className="d-flex flex-column">
                                                                <Label className="form-label">Cash Amount</Label>
                                                                <Input
                                                                    name="cash"
                                                                    type="text"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.cash}
                                                                    invalid={
                                                                        validation.touched.cash &&
                                                                            validation.errors.cash
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                {validation.touched.cash &&
                                                                    validation.errors.cash ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.cash}
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </Col>
                                                        </div>
                                                        <div className="mb-3 d-flex flex-row justify-content-center align-items-center ">
                                                            <Col xs={2} >
                                                                <img src={upi} className="image" height={25} />
                                                            </Col>
                                                            <Col xs={8} className="d-flex flex-column">
                                                                <Label className="form-label">UPI Amount</Label>
                                                                <Input
                                                                    name="upi"
                                                                    type="text"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.upi}
                                                                    invalid={
                                                                        validation.touched.upi &&
                                                                            validation.errors.upi
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                {validation.touched.upi &&
                                                                    validation.errors.upi ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.upi}
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </Col>
                                                        </div>
                                                        <div className="mb-3 d-flex flex-row justify-content-center align-items-center">
                                                            <Col xs={2} >
                                                                <img src={debit} height={40} />
                                                            </Col>
                                                            <Col xs={8} className="d-flex flex-column">
                                                                <Label className="form-label">Card Amount</Label>
                                                                <Input
                                                                    name="card"
                                                                    type="text"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.card}
                                                                    invalid={
                                                                        validation.touched.card &&
                                                                            validation.errors.card
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                {validation.touched.card &&
                                                                    validation.errors.card ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.card}
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </Col>
                                                        </div>
                                                    </Col>


                                                    <Col xs={{ offset: 3, size: 9 }} className="d-flex flex-column">
                                                        <Label className="form-label">Paid Amount</Label>
                                                        <Input
                                                            name="paid_amount"
                                                            type="text"
                                                            readOnly={true}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={(parseInt(validation.values.cash) + parseInt(validation.values.upi) + parseInt(validation.values.card))}
                                                            invalid={
                                                                validation.touched.paid_amount &&
                                                                    validation.errors.paid_amount
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.paid_amount &&
                                                            validation.errors.paid_amount ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.paid_amount}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </Col>
                                                    <Col xs={{ offset: 3, size: 9 }}>
                                                        <div className="mb-3 mt-3">
                                                            <Label className="form-label">Tip /Service Charge</Label>
                                                            <Input
                                                                name="tip"
                                                                type="text"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.tip || 0}
                                                                invalid={
                                                                    validation.touched.tip &&
                                                                        validation.errors.tip
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {validation.touched.tip &&
                                                                validation.errors.tip ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.tip}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col xs={{ offset: 3, size: 9 }}>
                                                        <div className="mb-3">
                                                            <Label className="form-label">Settled Amount</Label>
                                                            <Input
                                                                name="tip"
                                                                type="text"

                                                                readOnly={true}
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={((parseInt(validation.values.cash) + parseInt(validation.values.upi) + parseInt(validation.values.card)) - validation.values.tip)}
                                                                invalid={
                                                                    validation.touched.tip &&
                                                                        validation.errors.tip
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {validation.touched.tip &&
                                                                validation.errors.tip ? (
                                                                <FormFeedback type="invalid">
                                                                    {validation.errors.tip}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <div className="text-end">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-success save-user"
                                                            >
                                                                Settled
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>




                                    </Form>
                                </ModalBody>
                            </Modal>

                            <CoupanPopup loading={loading} setLoading={setLoading} setModal={setOpenCoupan} order={selectedOrder} modal={openCoupan} />
                            <Modal size="sm" isOpen={modalPrint} toggle={togglePrint}>
                                <ModalHeader toggle={togglePrint} tag="h4">
                                    Print Bill
                                </ModalHeader>
                                <ModalBody>
                                    <Row>
                                        <Col sm={12} className="d-flex flex-column justify-content-center">
                                            <button onClick={() => printOrder(selectedItem, "RECEIPT")} className="btn btn-primary mb-2 save-user">Reciept</button>
                                            <button onClick={() => printOrder(selectedItem, "CUSTOMER COPY")} className="btn btn-success save-user">Customer Copy</button>
                                        </Col>
                                    </Row>
                                </ModalBody>
                            </Modal>
                            <Modal size="lg" isOpen={modalUpdate} toggle={toggleUpdate}>
                                <ModalHeader toggle={toggleUpdate} tag="h4">
                                    {!!isEdit ? "Edit Order" : "Add Order"}
                                </ModalHeader>
                                <ModalBody>

                                    <Row>
                                        <Col sm={12}>
                                            <Row>
                                                <Col className="fw-bold fs-5 text-center">ITEM</Col><Col className="fw-bold fs-5 text-center">OPTION</Col><Col className="fw-bold fs-5 text-center">QTY</Col><Col className="fw-bold fs-5 text-center">PRICE</Col><Col className="fw-bold fs-5 text-center">Net Amount</Col>
                                            </Row>

                                            {
                                                order && order.items && order.items.map((item, index) => {

                                                    return (
                                                        <Row key={index} className={"my-2 align-items-center " + (item.basic != 'REGULAR' ? " border border-2 border-danger " : "")}>
                                                            <Col className="d-flex flex-column">
                                                                {item.fooditem.name}
                                                                {item.basic != 'REGULAR' && <span className="text-danger">{item.basic}</span>}
                                                            </Col>
                                                            <Col>
                                                                <ReactSelect className="p-0" onChange={(option) => onSelectOption(item, option.value)} defaultValue={{ label: item.basic, value: item.basic }} options={[{ label: "REGULAR", value: "REGULAR" }, { label: "CANCELLED", value: "CANCELLED" }, { label: "COMPLEMENT", value: "COMPLEMENT" }]} />
                                                            </Col>
                                                            <Col className="text-center"><button className="btn btn-primary btn-sm mx-2" disabled={item.qty == 1} onClick={() => remove(item)}>-</button>{item.qty}<button className="btn btn-primary btn-sm mx-2" onClick={() => add(item)}>+</button></Col>
                                                            <Col className="text-center">{item.price.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</Col>
                                                            <Col className="text-center">{item.netprice.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</Col>
                                                        </Row>
                                                    )
                                                })
                                            }
                                            <Row className="row border-top border-2 border-dark py-2">
                                                <Col xs={{ offset: 5, size: 4 }} className="h4">
                                                    Total Amount
                                                </Col>
                                                <Col xs={{ size: 3 }} className="h4">{order?.total_amount?.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</Col>
                                            </Row>
                                            <Row className="row border-top border-2 border-dark py-2">
                                                <Col xs={{ offset: 5, size: 3 }} className="h4">
                                                    Coupon Code
                                                </Col>
                                                {(order && (order?.code == "" || order["code"] === undefined)) ?
                                                    <Col xs={{ size: 4 }} className="h4 d-flex flex-column">
                                                        <div className="w-100 d-flex flex-row">
                                                            <Input
                                                                type="text"
                                                                onChange={(e) => {
                                                                    setCoupanCode(e.target.value);
                                                                }}
                                                            />
                                                            <button className="btn btn-sm btn-primary m-2" onClick={() => validateCoupan()}>Apply</button>
                                                        </div>
                                                        {errorText != "" && <p className="fs-6 text-danger m-0 py-2">{errorText}</p>}
                                                    </Col> :
                                                    <Col xs={{ size: 4 }} className="h4 d-flex flex-column">
                                                        <div className="d-flex flex-row justify-content-center align-items-center">
                                                            <p className="m-0 p-2 border border-1 border-success">{coupanCode}</p>
                                                            <button className="btn btn-sm btn-danger m-2" onClick={() => removeCoupan()}>Remove</button>
                                                        </div>
                                                    </Col>
                                                }
                                                {/* <Col xs={{ offset: 5, size: 4 }} className="h4">
                                                    Discount %
                                                </Col>
                                                <Col xs={{ size: 3 }} className="h4">
                                                    <Input
                                                        name="tip"
                                                        type="text"
                                                        defaultValue={order?.discount}
                                                        onChange={(e) => {
                                                            let discount = e.target.value;
                                                            console.log(discount)
                                                            if (discount == "") {
                                                                discount = 0;
                                                            }
                                                            setOrder({ ...order, discount: discount, payable_amount: Math.round(order.total_amount - ((order.total_amount * e.target.value) / 100)) })
                                                        }}
                                                    />
                                                </Col> */}
                                            </Row>
                                            {(order && (order?.code != "" && order["code"] !== undefined)) &&
                                                <Row className="row border-top border-2 border-dark py-2">
                                                    <Col xs={{ offset: 5, size: 4 }} className="h4">
                                                        Discount ({order?.discount}%)
                                                    </Col>
                                                    <Col xs={{ size: 3 }} className="h4">
                                                    {((order.total_amount * order.discount) / 100).toLocaleString('en-IN', { minimumFractionDigits: 2 })}
                                                    </Col>
                                                </Row>
                                            }
                                            <Row className="row border-top border-2 border-dark py-2">
                                                <Col xs={{ offset: 5, size: 4 }} className="h4">
                                                    Payable Amount
                                                </Col>
                                                <Col xs={{ size: 3 }} className="h4">{order?.payable_amount?.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="text-end">
                                                <button
                                                    onClick={() => updateItems()}
                                                    className="btn btn-success save-user"
                                                >
                                                    Update
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </ModalBody>
                            </Modal>
                        </Row>
                    </Container>
                </div>
                <ToastContainer />
            </React.Fragment>
    );
};

export default withRouter(TablePayment);
