import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { ADD_NEW_SUBSCRIBER, DELETE_SUBSCRIBER, GET_SUBSCRIBER, UPDATE_SUBSCRIBER, GET_SUBSCRIBERCOMBO  } from "./actionTypes"

import {
  getSubscriberSuccess,
  getSubscriberFail,
  addSubscriberFail,
  addSubscriberSuccess,
  updateSubscriberSuccess,
  updateSubscriberFail,
  deleteSubscriberSuccess,
  deleteSubscriberFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getSubscriber, addSubscriber, updateSubscriber, deleteSubscriber } from "../../helpers/subscriber_helper"

// toast
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';



function* fetchSubscriber() {
  try {
    const response = yield call(getSubscriber)
    yield put(getSubscriberSuccess(response.subscribers))
  } catch (error) {
    yield put(getSubscriberFail(error))
  }
}

function* onUpdateSubscriber({ payload: subscriber }) {
  try {
    const response = yield call(updateSubscriber, subscriber)
    response.subscriber.image = response.subscriber.image.path;
    response.subscriber.id = response.subscriber._id;
    yield put(updateSubscriberSuccess(response.subscriber))
    toast.success("Updated Successfully", { autoClose: 2000 });
  } catch (error) {
    console.log(error)
    yield put(updateSubscriberFail(error))
    toast.error("Updated Failed", { autoClose: 2000 });
  }
}

function* onDeleteSubscriber({ payload: subscriber }) {
  try {
    const response = yield call(deleteSubscriber, subscriber)
    
    yield put(deleteSubscriberSuccess(response.subscriber._id))
    toast.success("Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteSubscriberFail(error))
    toast.error("Deleted Failed", { autoClose: 2000 });
  }
}

function* onAddSubscriber({ payload: user }) {
  try {
    const response = yield call(addSubscriber, user)
    response.subscriber.image = response.subscriber.image.path;
    response.subscriber.id = response.subscriber._id;
    yield put(addSubscriberSuccess(response.subscriber))
    toast.success("Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addSubscriberFail(error))
    toast.error("Added Failed", { autoClose: 2000 });
  }
}

function* subscriberSaga() {
  yield takeEvery(GET_SUBSCRIBER, fetchSubscriber)
  yield takeEvery(ADD_NEW_SUBSCRIBER, onAddSubscriber)
  yield takeEvery(UPDATE_SUBSCRIBER, onUpdateSubscriber)
  yield takeEvery(DELETE_SUBSCRIBER, onDeleteSubscriber)
}

export default subscriberSaga;
