import { AudioFile } from "pages/Subscription/SubscriptionCol";
import React, { useState, useEffect } from "react";
import ReactAudioPlayer from "react-audio-player";
import { Card, CardBody, CardTitle, Col, FormFeedback, Input, Label, Row } from "reactstrap";

const AudioUpload = ({ selectedFile, setselectedFile, onFileChange, onRemoveChange, error, isSubmitted }) => {
    const [src, setSrc] = useState(selectedFile);
    const [touchedCategory, setTouchedCategory] = useState(false);
    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setselectedFile(e.target.files[0]);
            setSrc(URL.createObjectURL(e.target.files[0]))
            setTouchedCategory(true);
            if (onFileChange)
                onFileChange(e.target.files[0]);
        }
    }

    const DeleteFile = () => {
        setSrc(null)
        if (onRemoveChange)
            onRemoveChange();
    }
    return (
        <React.Fragment>
            <h3 className="text-center mt-3">Audio File</h3>
            {!src &&
                <div className="mb-3">
                    <Label htmlFor="formFile" className="form-label">Upload File</Label>
                    <input onChange={onSelectFile} accept="audio/*" className="form-control" type="file" id="formFile" />
                </div>
            }
            {src &&
                <div className="mb-3 d-flex flex-column align-items-center justify-content-center">
                    <ReactAudioPlayer src={src} controls controlsList='play' />

                    <button type="button" onClick={x => DeleteFile()} className="btn btn-sm btn-outline-primary">Remove</button>

                </div>
            }
            {(isSubmitted || touchedCategory) && error ?
                <FormFeedback type="invalid" style={{ display: 'block' }}>
                    {error}
                </FormFeedback> : null
            }
        </React.Fragment>
    )
}
export default AudioUpload;