import {
  GET_DASHBOARD,
  GET_DASHBOARD_FAIL,
  GET_DASHBOARD_SUCCESS,
} from "./actionTypes"

export const getDashboard = () => ({
  type: GET_DASHBOARD,
})

export const getDashboardSuccess = coupan => ({
  type: GET_DASHBOARD_SUCCESS,
  payload: coupan,
})

export const getDashboardFail = error => ({
  type: GET_DASHBOARD_FAIL,
  payload: error,
})