import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../components/Common/withRouter";
import TableContainer from "../components/Common/TableContainer";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    UncontrolledTooltip,
    Input,
    Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteModal from "../components/Common/DeleteModal";

import {
    getRoles as onGetRoles,
    addNewRole as onAddNewRole,
    updateRole as onUpdateRole,
    deleteRole as onDeleteRole,
} from "../store/role/actions";
import { isEmpty } from "lodash";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import moment from 'moment';
import CategorySelect from "components/Common/CategorySelect";
import { CustomBadge } from "components/Common/ColumnStyle";
import CounterSelect from "components/Common/CounterSelect";
import PagesSelect from "components/Common/PagesSelect";

const Role = props => {

    //meta title
    document.title = "Mythreya - Roles";

    const dispatch = useDispatch();
    const [role, setRole] = useState();
    const [selectedCounter, setSelectedCounter] = useState([])
    const [selectCounter, setSelectCounter] = useState([]);
    const [selectedPages, setSelectedPages] = useState([])
    const [selectPages, setSelectPages] = useState([]);
    const [isTable, setIsTable] = useState(false);

    const [isSubmitted, setIsSubmitted] = useState(false);

    const changeCounter = (val) => {
        validation.setFieldValue("counters", val)
        setSelectedCounter(val)
    }

    const changePage = (val) => {
        validation.setFieldValue("pages", val)
        setSelectedPages(val)
    }

    // validation
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: (role && role.name) || "",
            counters: (role && role.counters) || "",
            pages: (role && role.pages) || "",
            isTable: (role && role.isTable) || false,
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
            counters: Yup.array().required("Please select counter").min(1, "Please select counter"),
            pages: Yup.array().required("Please select counter").min(1, "Please select counter"),
            isTable: Yup.string()
        }),
        onSubmit: values => {
            if (isEdit) {
                let counters = []
                selectedCounter.forEach((value, index) => {
                    counters.push(value.value);
                });
                let pages = []
                selectedPages.forEach((value, index) => {
                    pages.push(value.value);
                });
                const updateRole = {
                    id: role.id,
                    name: values.name,
                    counters: counters,
                    pages : pages,
                    isTable: isTable
                };
                dispatch(onUpdateRole(updateRole));
                setIsEdit(false);
                setIsTable(false)
                validation.resetForm();
            } else {
                let counters = []
                selectedCounter.forEach((value, index) => {
                    counters.push(value.value);
                });
                let pages = []
                selectedPages.forEach((value, index) => {
                    pages.push(value.value);
                });
                const newRole = {
                    name: values["name"],
                    counters: counters,
                    pages : pages,
                    isTable: isTable
                };
                // save new user
                dispatch(onAddNewRole(newRole));
                setIsTable(false)
                validation.resetForm();
            }
            toggle();
        },
    });

    const selectRolesState = (state) => state.roles;
    const RolesProperties = createSelector(
        selectRolesState,
        (Role) => ({
            roles: Role.role,
            loading: Role.loading
        })
    );

    const {
        roles, loading
    } = useSelector(RolesProperties);

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setLoading] = useState(loading)
    
    const columns = useMemo(
        () => [
            {
                Header: "#",
                // accessor: "name",
                disableFilters: true,
                filterable: true,
                accessor: (cellProps) => (
                    <>
                        {!cellProps.img ? (
                            <div className="avatar-xs">
                                <span className="avatar-title rounded-circle">
                                    {cellProps.name.charAt(0)}
                                </span>
                            </div>
                        ) : (
                            <div>
                                <img
                                    className="rounded-circle avatar-xs"
                                    src={cellProps.img}
                                    alt=""
                                />
                            </div>
                        )}
                    </>
                ),
            },
            {
                Header: "Name",
                accessor: "name",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.name}</Link>
                            </h5>
                        </>
                    )
                },
            },
           
            {
                Header: "Counter",
                accessor: "counters",
                filterable: true,
                Cell: cellProps => {
                    return <CustomBadge color="info" {...cellProps} />;
                },
            },
            {
                Header: "Dine In",
                accessor: "isTable",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{(cellProps.row.original.isTable)?"Avail":"Not Avail"}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Created By",
                accessor: "createdBy",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.createdBy?.name}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Created Date",
                accessor: "createdAt",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{moment(cellProps.row.original.createdAt).format('MMM D, YYYY')}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Action",
                Cell: cellProps => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-success"
                                onClick={() => {
                                    const userData = cellProps.row.original;
                                    handleRoleClick(userData);
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const userData = cellProps.row.original;
                                    onClickDelete(userData);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                },
            },
        ],
        []
    );

    useEffect(() => {
        if (roles && !roles.length) {
            dispatch(onGetRoles());
            setIsEdit(false);
        }
    }, [dispatch, roles]);

    useEffect(() => {
        setRole(roles);
        setIsEdit(false);
    }, [roles]);

    useEffect(() => {
        if (!isEmpty(roles) && !!isEdit) {
            setRole(roles);
            setIsEdit(false);
        }
    }, [roles]);

    const toggle = () => {
        setModal(!modal);
    };

    const toggleReceipt = () => {
        setIsTable(prevReceipt => !prevReceipt)
    }

    const handleRoleClick = arg => {
        const role = arg;
        setSelectCounter(role.counters)
        setSelectPages(role.pages)
        let counters = role.counters.map((x) => { return { label: x.name, value: x._id } })
        setSelectedCounter(counters)
        let pages = role.pages.map((x) => { return { label: x.name, value: x._id } })
        setSelectedPages(pages)
        setIsTable(role.isTable)
        setRole({
            id: role.id,
            name: role.name,
            counters: counters,
            pages : pages,
            isTable: role.isTable,
        });

        validation.initialValues = {
            name: (role && role.name) || "",
            counters: (role && role.counters) || "",
            pages: (role && role.pages) || "",
            isTable: (role && role.isTable) || false,
        }
        setIsEdit(true);

        toggle();
    };

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    //delete customer
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = roles => {
        setRole(roles);
        setDeleteModal(true);
    };

    const handleDeleteRole = () => {
        if (role && role.id) {
            dispatch(onDeleteRole(role.id));
        }
        onPaginationPageChange(1);
        setDeleteModal(false);
    };

    const handleRoleClicks = () => {
        setIsEdit(false);
        setIsTable(false)
        setSelectCounter([])
        setSelectedCounter([])
        validation.resetForm();
        toggle();
    };

    const onClose = () => {
        setRole({})
        validation.resetForm();
        setIsEdit(false);
        setIsTable(false)
        setSelectCounter([])
        setSelectedCounter([])
    }

    const keyField = "id";

    return (
        loading ? <Spinners setLoading={setLoading} />
            :
            <React.Fragment>
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteRole}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs breadcrumbItem="Role" />
                        <Row>
                            {
                                isLoading ? <Spinners setLoading={setLoading} />
                                    :
                                    <Col lg="12">
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    isPagination={true}
                                                    columns={columns}
                                                    data={roles}
                                                    isGlobalFilter={true}
                                                    isShowingPageLength={true}
                                                    buttonText={"Create Role"}
                                                    isAddUserList={true}
                                                    iscustomPageSizeOptions={true}
                                                    handleUserClick={handleRoleClicks}
                                                    customPageSize={10}
                                                    tableClass="table align-middle table-nowrap table-hover"
                                                    theadClass="table-light"
                                                    paginationDiv="col-sm-12 col-md-7"
                                                    pagination="pagination pagination-rounded justify-content-end mt-4"
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                            }

                            <Modal isOpen={modal} onClosed={onClose} toggle={toggle}>
                                <ModalHeader toggle={toggle} role="h4">
                                    {!!isEdit ? "Edit Role" : "Add Role"}
                                </ModalHeader>
                                <ModalBody>
                                    <Form
                                        onSubmit={e => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row>
                                            <Col xs={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Name</Label>
                                                    <Input
                                                        name="name"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.name || ""}
                                                        invalid={
                                                            validation.touched.name &&
                                                                validation.errors.name
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.name &&
                                                        validation.errors.name ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.name}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label>Select Counters</Label>
                                                    <CounterSelect isMulti={true} isSubmitted={isSubmitted} error={validation.errors.counters} defaultValue={selectCounter} OnSelect={changeCounter}></CounterSelect>
                                                </div>
                                                <div className="mb-3">
                                                    <Label>Select Pages</Label>
                                                    <PagesSelect isMulti={true} isSubmitted={isSubmitted} error={validation.errors.pages} defaultValue={selectPages} OnSelect={changePage}></PagesSelect>
                                                </div>
                                                
                                            </Col>
                                            <Col xs={12}>
                                                <div className="mb-3 d-flex flex-row justify-content-start">
                                                    <Input
                                                        type="checkbox"
                                                        name="radioGroup"
                                                        className="me-2"
                                                        checked={isTable}
                                                        onChange={toggleReceipt}
                                                    />
                                                    <Label className="form-label h5">Indine Pattern</Label>




                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="text-end">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-success save-user"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </ModalBody>
                            </Modal>
                        </Row>
                    </Container>
                </div>
                <ToastContainer />
            </React.Fragment>
    );
};

export default withRouter(Role);
