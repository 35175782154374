import {
  GET_USER,
  GET_USER_FAIL,
  GET_USER_SUCCESS,
  GET_USER_BY_ID,
  GET_USER_BY_ID_FAIL,
  GET_USER_BY_ID_SUCCESS,
  ADD_NEW_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
} from "./actionTypes"

export const getUserById = (user) => ({
  type: GET_USER_BY_ID,
  payload:user
})

export const getUserByIdSuccess = user => ({
  type: GET_USER_BY_ID_SUCCESS,
  payload: user,
})

export const getUserByIdFail = error => ({
  type: GET_USER_BY_ID_FAIL,
  payload: error,
})

export const getUser = () => ({
  type: GET_USER,
})

export const getUserSuccess = user => ({
  type: GET_USER_SUCCESS,
  payload: user,
})

export const getUserFail = error => ({
  type: GET_USER_FAIL,
  payload: error,
})

export const addNewUser = user => ({
  type: ADD_NEW_USER,
  payload: user,
})

export const addUserSuccess = user => ({
  type: ADD_USER_SUCCESS,
  payload: user,
})

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
})

export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user,
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

export const deleteUser = user => ({
  type: DELETE_USER,
  payload: user,
})

export const deleteUserSuccess = user => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})