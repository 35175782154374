import {
  GET_STORE_METRICS,
  GET_STORE_METRICS_FAIL,
  GET_STORE_METRICS_SUCCESS,
  GET_STORE_METRICSCOMBO,
  GET_STORE_METRICSCOMBO_FAIL,
  GET_STORE_METRICSCOMBO_SUCCESS,
  NEW_STORE_METRIC,
  NEW_STORE_METRIC_SUCCESS,
  NEW_STORE_METRIC_FAIL,
  UPDATE_STORE_METRIC,
  UPDATE_STORE_METRIC_SUCCESS,
  UPDATE_STORE_METRIC_FAIL,
  DELETE_STORE_METRIC,
  DELETE_STORE_METRIC_SUCCESS,
  DELETE_STORE_METRIC_FAIL,
} from "./actionTypes"

export const getStoreMetrics = () => ({
  type: GET_STORE_METRICS,
})

export const getStoreMetricsSuccess = storeMetric => ({
  type: GET_STORE_METRICS_SUCCESS,
  payload: storeMetric,
})

export const addNewStoreMetric = storeMetric => ({
  type: NEW_STORE_METRIC,
  payload: storeMetric,
})

export const addStoreMetricSuccess = storeMetric => ({
  type: NEW_STORE_METRIC_SUCCESS,
  payload: storeMetric,
})

export const addStoreMetricFail = error => ({
  type: NEW_STORE_METRIC_FAIL,
  payload: error,
})

export const getStoreMetricsFail = error => ({
  type: GET_STORE_METRICS_FAIL,
  payload: error,
})

export const updateStoreMetric = storeMetric => ({
  type: UPDATE_STORE_METRIC,
  payload: storeMetric,
})

export const updateStoreMetricSuccess = storeMetric => ({
  type: UPDATE_STORE_METRIC_SUCCESS,
  payload: storeMetric,
})

export const updateStoreMetricFail = error => ({
  type: UPDATE_STORE_METRIC_FAIL,
  payload: error,
})

export const deleteStoreMetric = storeMetric => ({
  type: DELETE_STORE_METRIC,
  payload: storeMetric,
})

export const deleteStoreMetricSuccess = storeMetric => ({
  type: DELETE_STORE_METRIC_SUCCESS,
  payload: storeMetric,
})

export const deleteStoreMetricFail = error => ({
  type: DELETE_STORE_METRIC_FAIL,
  payload: error,
})
export const getStoreMetricCombo = () => ({
  type: GET_STORE_METRICSCOMBO,
})

export const getStoreMetricsComboSuccess = category => ({
  type: GET_STORE_METRICSCOMBO_SUCCESS,
  payload: category,
})

export const getStoreMetricsComboFail = error => ({
  type: GET_STORE_METRICSCOMBO_FAIL,
  payload: error,
})