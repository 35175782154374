import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { ADD_NEW_NOTIFICATION, DELETE_NOTIFICATION, GET_NOTIFICATIONS, UPDATE_NOTIFICATION, GET_NOTIFICATIONSCOMBO  } from "./actionTypes"

import {
  getNotificationsSuccess,
  getNotificationsFail,
  addNotificationFail,
  addNotificationSuccess,
  updateNotificationSuccess,
  updateNotificationFail,
  deleteNotificationSuccess,
  deleteNotificationFail
} from "./actions"

//Include Both Helper File with needed methods
import { getNotification, addNotification, updateNotification, deleteNotification} from "../../helpers/notification_helper"

// toast
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';



function* fetchNotification() {
  try {
    const response = yield call(getNotification)
    yield put(getNotificationsSuccess(response.notifications))
  } catch (error) {
    yield put(getNotificationsFail(error))
  }
}


function* onUpdateNotification({ payload: notification }) {
  try {
    const response = yield call(updateNotification, notification)
    response.notification.id = response.notification._id;
    yield put(updateNotificationSuccess(response.notification))
    toast.success("Updated Successfully", { autoClose: 2000 });
  } catch (error) {
    console.log(error)
    yield put(updateNotificationFail(error))
    toast.error("Updated Failed", { autoClose: 2000 });
  }
}

function* onDeleteNotification({ payload: notification }) {
  try {
    const response = yield call(deleteNotification, notification)
    
    yield put(deleteNotificationSuccess(response.notification._id))
    toast.success("Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteNotificationFail(error))
    toast.error("Deleted Failed", { autoClose: 2000 });
  }
}

function* onAddNotification({ payload: user }) {
  try {
    console.log(user)
    const response = yield call(addNotification, user)
    response.notification.id = response.notification._id;
    yield put(addNotificationSuccess(response.notification))
    toast.success("Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addNotificationFail(error))
    toast.error("Added Failed", { autoClose: 2000 });
  }
}

function* notificationSaga() {
  yield takeEvery(GET_NOTIFICATIONS, fetchNotification)
  yield takeEvery(ADD_NEW_NOTIFICATION, onAddNotification)
  yield takeEvery(UPDATE_NOTIFICATION, onUpdateNotification)
  yield takeEvery(DELETE_NOTIFICATION, onDeleteNotification)
}

export default notificationSaga;
