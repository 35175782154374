import React, { useEffect, useState, useRef, useMemo } from "react";
import { getCounterCombo } from "../../store/counter/actions";
import Select from 'react-select';
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { FormFeedback } from "reactstrap";

const CounterSelect = ({ OnSelect, defaultValue, error, isMulti = false,  isSubmitted, extra = [] }) => {
    const dispatch = useDispatch();
    const [counter, setCounter] = useState({});
    const [selectedCounter, setSelectedCounter] = useState([]);
    const [touchedCategory, setTouchedCategory] = useState(false);

    const selectContactsState = (state) => state.counters;
    const ContactsProperties = createSelector(
        selectContactsState,
        (Counter) => ({
            counters: Counter.counterCombo,
            loading: Counter.loading
        })
    );
    const { counters, loading } = useSelector(ContactsProperties);

    const handleSelect = (val) => {
        setSelectedCounter(val)
        setTouchedCategory(true);
        OnSelect(val)
    }

    useEffect(() => {
        if (counters && !counters.length) {
            dispatch(getCounterCombo());
        }
        setTouchedCategory(false);
    }, [dispatch, counters]);

    useEffect(() => {
        if (!isMulti) {
            if (counters && counters.length != 0 && defaultValue) {
                const counter = counters.find((option) => option.value === defaultValue);
                if (counter) {
                    setSelectedCounter(counter);
                }
            }
            if (!defaultValue) {
                setSelectedCounter(null);
            }
            
            if(extra.length != 0 && defaultValue == null){
                setSelectedCounter({label:'SAME COUNTER', value : null})
            }

        }else{
            if (counters && counters.length && defaultValue.length != 0) {
                let counter = counters.filter(counter => defaultValue.some(value => value._id === counter.value));
                // console.log(counter)
                setSelectedCounter(counter)
            }
            if (defaultValue.length == 0) {
                setSelectedCounter([])
            }
        }
        if (counters && counters.length != 0 && extra.length != 0){
            // counters = [...counters, extra]
        }
    }, [counters, defaultValue]);

    return (
        <React.Fragment>
            <Select
                classNamePrefix="select2-selection"
                placeholder="Choose..."
                title="Duration"
                options={[...extra,...counters]}
                value={selectedCounter}
                onChange={handleSelect}
                isMulti={isMulti}
            />
            {(isSubmitted || touchedCategory) && error ?
                <FormFeedback type="invalid" style={{ display: 'block' }}>
                    {error}
                </FormFeedback> : null
            }
        </React.Fragment>
    )
};

export default CounterSelect