import React, { useEffect, useState, useRef, useMemo } from "react";
import { getStoreMetricCombo } from "../../store/storemetric/actions";
import Select from 'react-select';
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { FormFeedback } from "reactstrap";

const MetricSelect = ({ OnSelect, defaultValue, error, isSubmitted, isMulti }) => {
    const dispatch = useDispatch();
    const [storeMetric, setStoreMetric] = useState({});
    const [selectedMetric, setSelectedMetric] = useState([]);
    const [touchedCategory, setTouchedCategory] = useState(false);

    const selectContactsState = (state) => state.storeMetrics;
    const ContactsProperties = createSelector(
        selectContactsState,
        (Metric) => ({
            storeMetrics: Metric.storeMetricCombo,
            loading: Metric.loading
        })
    );
    const { storeMetrics, loading } = useSelector(ContactsProperties);

    const handleSelect = (val) => {
        setSelectedMetric(val)
        setTouchedCategory(true);
        OnSelect(val)
    }

    useEffect(() => {
        
        if (storeMetrics && !storeMetrics.length) {
            dispatch(getStoreMetricCombo());
        }
        setTouchedCategory(false);
    }, [dispatch, storeMetrics]);

    useEffect(() => {
        if(!isMulti){
            if (storeMetrics && storeMetrics.length != 0 && defaultValue) {
                const defaultCategory = storeMetrics.find((option) => option.value === defaultValue);
    
                if (defaultCategory) {
                    setSelectedMetric(defaultCategory);
                }
            }
            if (!defaultValue) {
                setSelectedMetric(null);
            }
        }else{
            if (storeMetrics && storeMetrics.length && defaultValue.length != 0) {
                let storeMetric = storeMetrics.filter(storeMetric => defaultValue.some(value => value._id === storeMetric.value));
                // console.log(tag)
                setSelectedMetric(storeMetric)
            }
            if (defaultValue.length == 0) {
                setSelectedMetric([])
            }
        }
    }, [storeMetrics, defaultValue]);

    return (
        <React.Fragment>
            <Select
                classNamePrefix="select2-selection"
                placeholder="Choose..."
                title="Duration"
                options={storeMetrics}
                value={selectedMetric}
                onChange={handleSelect}
                isMulti={isMulti}
                isSearchable={(isMulti)?true:false}
            />
            {(isSubmitted || touchedCategory) && error ?
                <FormFeedback type="invalid" style={{ display: 'block' }}>
                    {error}
                </FormFeedback> : null
            }
        </React.Fragment>
    )
};

export default MetricSelect