import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";

//actions

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import getChartColorsArray from "../../components/Common/ChartsDynamicColor";

const Earning = ({ dataColors, data }) => {
  const apexearningChartColors = getChartColorsArray(dataColors);

  const options = {
    chart: {
      toolbar: "false",
      dropShadow: {
        enabled: !0,
        color: "#000",
        top: 18,
        left: 7,
        blur: 8,
        opacity: 0.2,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    colors: apexearningChartColors,
    stroke: {
      curve: "smooth",
      width: 3,
    },
  };

  const series = [
    {
      name: "Series 1",
      data: [],
    },
  ];

  /*
  call api action to receive data
  */

  const relDiff = (a, b) => {
      var val = Math.abs( (b - a)/ b *100);
      if(val == 'Infinity')
        return 100;
      else
        return val;
   }
  


  return (
    <React.Fragment>
      <Col xl="12">
        <Card>
          <CardBody>
            <div className="clearfix">
              <h4 className="card-title mb-4">Revenue</h4>
            </div>

            <Row>
              <Col lg="12">
                <div className="text-muted">
                  <div className="mb-4">
                    <p>This month</p>
                    <h4>{data.thisMonth?.toLocaleString('en-IN', {
                            style: 'currency',
                            currency: 'INR',
                          })}</h4>
                    <div>
                      <span className="badge badge-soft-success font-size-12 me-1">
                        {" "}
                        {((data.lastMonth > data.thisMonth)?'-':"") + relDiff(data.thisMonth, data.lastMonth).toFixed(2)}%{" "}
                      </span>{" "}
                      From previous period
                    </div>
                  </div>

                  {/* <div>
                    <Link to="#" className="btn btn-primary  btn-sm">
                      View Details{" "}
                      <i className="mdi mdi-chevron-right ms-1"></i>
                    </Link>
                  </div> */}

                  <div className="mt-4">
                    <p className="mb-2">Last month</p>
                    <h5>{data.lastMonth?.toLocaleString('en-IN', {
                            style: 'currency',
                            currency: 'INR',
                          })}</h5>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
}

export default Earning;
