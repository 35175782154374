import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import TableContainer from "../../components/Common/TableContainer";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    UncontrolledTooltip,
    Input,
    Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";

import {
    getSubscriptions as onGetSubscriptions,
    addNewSubscription as onAddNewSubscription,
    updateSubscription as onUpdateSubscription,
    deleteSubscription as onDeleteSubscription,
} from "../../store/subscription/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import { PlansBadge } from "./SubscriptionCol";
import moment from 'moment';
const Plans = props => {

    const options = [
        { value: 1, label: "1 Months" },
        { value: 6, label: "6 Months" },
        { value: 12, label: "12 Months" },

    ]
    //meta title
    document.title = "KIDS STORY TELLING (KST) - Subscription List";

    const dispatch = useDispatch();
    const [subscription, setSubscription] = useState();

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: (subscription && subscription.name) || "",
            price: (subscription && subscription.price) || "",
            duration : (subscription && subscription.duration) || ""
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
            duration: Yup.string().required("Please Enter Duration"),
            price: Yup.string().required("Please Enter Price"),
        }),
        onSubmit: values => {
            if (isEdit) {
                const updateSubscription = {
                    id: subscription.id,
                    name: values.name,
                    duration: duration.value,
                    price: values.price,
                };
                // update user
                dispatch(onUpdateSubscription(updateSubscription));
                setIsEdit(false);
                setDuration({})
                validation.resetForm();
            } else {
                const newSubscription = {
                    name: values["name"],
                    duration: duration.value,
                    price: values["price"],
                };
                // save new user
                dispatch(onAddNewSubscription(newSubscription));
                setDuration({})
                validation.resetForm();
            }
            toggle();
        },
    });

    const selectSubscriptionsState = (state) => state.subscriptions;
    const SubscriptionsProperties = createSelector(
        selectSubscriptionsState,
        (Subscriptions) => ({
            subscriptions: Subscriptions.subscription,
            loading: Subscriptions.loading
        })
    );

    const {
        subscriptions, loading
    } = useSelector(SubscriptionsProperties);

    

    
    const [userList, setSubscriptionList] = useState([]);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setLoading] = useState(loading);
    const [duration, setDuration] = useState({});

    const columns = useMemo(
        () => [
            {
                Header: "#",
                accessor: "name",
                disableFilters: true,
                filterable: true,
                accessor: (cellProps) => (
                    <>
                        {!cellProps.img ? (
                            <div className="avatar-xs">
                                <span className="avatar-title rounded-circle">
                                    {cellProps.name.charAt(0)}
                                </span>
                            </div>
                        ) : (
                            <div>
                                <img
                                    className="rounded-circle avatar-xs"
                                    src={cellProps.img}
                                    alt=""
                                />
                            </div>
                        )}
                    </>
                ),
            },
            {
                Header: "Plan",
                accessor: "name",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.name}</Link>
                            </h5>
                            <p className="text-muted mb-0">{cellProps.row.original.designation}</p>
                        </>
                    )
                },
            },
            {
                Header: "Duration",
                accessor: "duration",
                filterable: true,
                Cell: cellProps => {
                    return <PlansBadge {...cellProps} />;
                },

            },
            {
                Header: "Price",
                accessor: "price",
                filterable: true,
                
            },
            {
                Header: "Created By",
                accessor: "createdBy",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.createdBy?.name}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Created Date",
                accessor: "createdAt",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{moment(cellProps.row.original.createdAt).format('MMM D, YYYY')}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Action",
                Cell: cellProps => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-success"
                                onClick={() => {
                                    const userData = cellProps.row.original;
                                    handleSubscriptionClick(userData);
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const userData = cellProps.row.original;
                                    onClickDelete(userData);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                },
            },
        ],
        []
    );

    useEffect(() => {
        if (subscriptions && !subscriptions.length) {
            dispatch(onGetSubscriptions());
            setIsEdit(false);
        }
    }, [dispatch, subscriptions]);

    useEffect(() => {
        setSubscription(subscriptions);
        setIsEdit(false);
    }, [subscriptions]);

    useEffect(() => {
        if (!isEmpty(subscriptions) && !!isEdit) {
            setSubscription(subscriptions);
            setIsEdit(false);
        }
    }, [subscriptions]);

    const toggle = () => {
        setModal(!modal);
    };

    const handleSubscriptionClick = arg => {
        const subscription = arg;

        setSubscription({
            id: subscription.id,
            name: subscription.name,
            price: subscription.price,
            duration:subscription.duration
        });
        setDuration({label:subscription.duration + " Months",value : subscription.value})
        setIsEdit(true);

        toggle();
    };

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    //delete customer
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = users => {
        setSubscription(users);
        setDeleteModal(true);
    };

    const handleDeleteSubscription = () => {
        if (subscription && subscription.id) {
            dispatch(onDeleteSubscription(subscription.id));
        }
        onPaginationPageChange(1);
        setDeleteModal(false);
    };

    const handleSubscriptionClicks = () => {
        setSubscriptionList("");
        setIsEdit(false);
        toggle();
    };

    const keyField = "id";

    return (
        loading ? <Spinners setLoading={setLoading} />
            :
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteSubscription}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs breadcrumbItem="Subscription Plans" />
                    <Row>
                        {
                            isLoading ? <Spinners setLoading={setLoading} />
                                :
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            <TableContainer
                                                isPagination={true}
                                                columns={columns}
                                                data={subscriptions}
                                                isGlobalFilter={true}
                                                isShowingPageLength={true}
                                                isSubscriptionPlanList={true}
                                                iscustomPageSizeOptions={true}
                                                handleUserClick={handleSubscriptionClicks}
                                                customPageSize={10}
                                                tableClass="table align-middle table-nowrap table-hover"
                                                theadClass="table-light"
                                                paginationDiv="col-sm-12 col-md-7"
                                                pagination="pagination pagination-rounded justify-content-end mt-4"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                        }

                        <Modal isOpen={modal} toggle={toggle}>
                            <ModalHeader toggle={toggle} tag="h4">
                                {!!isEdit ? "Edit Plan" : "Add Plan"}
                            </ModalHeader>
                            <ModalBody>
                                <Form
                                    onSubmit={e => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}
                                >
                                    <Row>
                                        <Col xs={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">Name</Label>
                                                <Input
                                                    name="name"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.name || ""}
                                                    invalid={
                                                        validation.touched.name &&
                                                            validation.errors.name
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.touched.name &&
                                                    validation.errors.name ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.name}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Label>Plan Duration</Label>
                                                <Select
                                                    classNamePrefix="select2-selection"
                                                    placeholder="Choose..."
                                                    title="Duration"
                                                    isSearchable={false}
                                                    options={options}
                                                    defaultValue={duration}
                                                    onChange={(val)=>{
                                                        validation.setFieldValue("duration", val.value)
                                                        setDuration(val)
                                                    }}
                                                />
                                                {validation.touched.duration && validation.errors.duration ? (
                                                    <FormFeedback type="invalid" className="d-block">{validation.errors.duration}</FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Label className="form-label">Price</Label>
                                                <Input
                                                    name="price"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.price || ""}
                                                    invalid={
                                                        validation.touched.price &&
                                                            validation.errors.price
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.touched.price &&
                                                    validation.errors.price ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.price}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="text-end">
                                                <button
                                                    type="submit"
                                                    className="btn btn-success save-user"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </ModalBody>
                        </Modal>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default withRouter(Plans);
