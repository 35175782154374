import React, { useEffect, useState, useRef, useMemo } from "react";
import { getStoreCategoryCombo } from "../../store/storecategory/actions";
import Select from 'react-select';
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { FormFeedback } from "reactstrap";

const StoreCategorySelect = ({ OnSelect, defaultValue, error, isSubmitted, isMulti }) => {
    const dispatch = useDispatch();
    const [storeCategory, setStoreCategory] = useState({});
    const [selectedMetric, setSelectedMetric] = useState([]);
    const [touchedCategory, setTouchedCategory] = useState(false);

    const selectContactsState = (state) => state.storeCategorys;
    const ContactsProperties = createSelector(
        selectContactsState,
        (Metric) => ({
            storeCategorys: Metric.storeCategoryCombo,
            loading: Metric.loading
        })
    );
    const { storeCategorys, loading } = useSelector(ContactsProperties);

    const handleSelect = (val) => {
        setSelectedMetric(val)
        setTouchedCategory(true);
        OnSelect(val)
    }

    useEffect(() => {
        
        if (storeCategorys && !storeCategorys.length) {
            dispatch(getStoreCategoryCombo());
        }
        setTouchedCategory(false);
    }, [dispatch, storeCategorys]);

    useEffect(() => {
        if(!isMulti){
            if (storeCategorys && storeCategorys.length != 0 && defaultValue) {
                const defaultCategory = storeCategorys.find((option) => option.value === defaultValue);
                console.log(defaultCategory)
                if (defaultCategory) {
                    setSelectedMetric(defaultCategory);
                }
            }
            if (!defaultValue) {
                setSelectedMetric(null);
            }
        }else{
            if (storeCategorys && storeCategorys.length && defaultValue.length != 0) {
                let storeCategory = storeCategorys.filter(storeCategory => defaultValue.some(value => value._id === storeCategory.value));
                // console.log(tag)
                setSelectedMetric(storeCategory)
            }
            if (defaultValue.length == 0) {
                setSelectedMetric([])
            }
        }
    }, [storeCategorys, defaultValue]);

    return (
        <React.Fragment>
            <Select
                classNamePrefix="select2-selection"
                placeholder="Choose..."
                title="Duration"
                options={storeCategorys}
                value={selectedMetric}
                onChange={handleSelect}
                isMulti={isMulti}
                isSearchable={(isMulti)?true:false}
            />
            {(isSubmitted || touchedCategory) && error ?
                <FormFeedback type="invalid" style={{ display: 'block' }}>
                    {error}
                </FormFeedback> : null
            }
        </React.Fragment>
    )
};

export default StoreCategorySelect