import PropTypes from "prop-types";
import React, { useEffect } from "react";
import withRouter from "components/Common/withRouter";
import {
  changeSidebarType,
} from "../../store/actions";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

//redux
import { useDispatch } from "react-redux";

const Layout = props => {
  const dispatch = useDispatch();

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);


  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile));
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header toggleMenuCallback={toggleMenuCallback} />
        <Sidebar
          isMobile={isMobile}
        />
        <div className="main-content">{props.children}</div>
        <Footer />
      </div>
      
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  isPreloader: PropTypes.any,
  location: PropTypes.object,
};

export default withRouter(Layout);
