import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../components/Common/withRouter";
import TableContainer from "../components/Common/TableContainer";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    UncontrolledTooltip,
    Input,
    Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteModal from "../components/Common/DeleteModal";

import {
    getSlides as onGetUsers,
    addNewSlide as onAddNewUser,
    updateSlide as onUpdateUser,
    deleteSlide as onDeleteUser,
} from "../store/slides/actions";
import { isEmpty } from "lodash";
import moment from 'moment';

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";

import { getImage } from '../helpers/common_helper'
import ImageUploadWithCrop from "components/Common/ImageUploadWithCrop";
import ImageBox from "components/Common/ImageBox";

const Slides = props => {
    document.title = "KIDS STORY TELLING (KST) - Slides";

    const dispatch = useDispatch();
    const [slide, setSlide] = useState();
    const [selectedFiles, setselectedFiles] = useState([])
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: (slide && slide.title) || "",
            description: (slide && slide.description) || "",
            image: (slide && slide.image) || "",
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Please Enter Title"),
            description: Yup.string(),
            image: Yup.string().required("Slide Image required")
        }),
        onSubmit: values => {
            if (isEdit) {
                const formData = new FormData();
                formData.append('title', validation.values.title);
                formData.append('description', validation.values.description);
                if (selectedFiles.length != 0) {
                    if (isBlob(selectedFiles[0]))
                        formData.append('image', selectedFiles[0], `image.png`);
                }
                // if (croppedImageData != '')
                //     formData.append('image', croppedImageData, `image.png`);
                let _slide = {
                    formData: formData,
                    id: slide.id
                }
                dispatch(onUpdateUser(_slide));
                setselectedFiles([])
                validation.setFieldValue("image", "")
                setIsEdit(false);
                validation.resetForm();
            } else {
                const formData = new FormData();
                formData.append('title', validation.values.title);
                formData.append('description', validation.values.description);
                if (selectedFiles.length != 0) {
                    if (isBlob(selectedFiles[0]))
                        formData.append('image', selectedFiles[0], `image.png`);
                }
                dispatch(onAddNewUser(formData));
                setselectedFiles([])
                validation.setFieldValue("image", "")
                validation.resetForm();
            }
            dispatch(onGetUsers());
            toggle();
        },
    });


    const selectContactsState = (state) => state.slides;
    const ContactsProperties = createSelector(
        selectContactsState,
        (Slide) => ({
            slides: Slide.slide,
            loading: Slide.loading
        })
    );

    const isBlob = (obj) => {
        return obj instanceof Blob;
    }

    const {
        slides, loading
    } = useSelector(ContactsProperties);

    const [slideList, setSlideList] = useState([]);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setLoading] = useState(loading)

    const columns = useMemo(
        () => [
            {
                Header: "#",
                // accessor: "name",
                disableFilters: true,
                filterable: true,
                accessor: (cellProps) => (
                    <>
                        {!cellProps.image ? (
                            <div className="avatar-xs">
                                <span className="avatar-title rounded-circle">
                                    {cellProps.name.charAt(0)}
                                </span>
                            </div>
                        ) : (
                            <div>
                                <img
                                    className="rounded-circle avatar-xs"
                                    src={getImage(cellProps.image)}
                                    alt=""
                                />
                            </div>
                        )}
                    </>
                ),
            },
            {
                Header: "Name",
                accessor: "title",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.title}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Description",
                accessor: "description",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.description}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Created By",
                accessor: "createdBy",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.createdBy?.name}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Created Date",
                accessor: "createdAt",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{moment(cellProps.row.original.createdAt).format('MMM D, YYYY')}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Action",
                Cell: cellProps => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-success"
                                onClick={() => {
                                    const userData = cellProps.row.original;
                                    handleUserClick(userData);
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const userData = cellProps.row.original;
                                    onClickDelete(userData);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                },
            },
        ],
        []
    );

    useEffect(() => {
        if (slides && !slides.length) {
            dispatch(onGetUsers());
            setIsEdit(false);
        }
    }, [dispatch]);

    useEffect(() => {
        setSlide(slides);
        setIsEdit(false);
    }, [slides]);

    useEffect(() => {
        if (!isEmpty(slides) && !!isEdit) {
            setSlide(slides);
            setIsEdit(false);
        }
    }, [slides]);

    const toggle = () => {
        setModal(!modal);
    };

    const handleUserClick = arg => {
        const slide = arg;
        setSlide(slide)
        validation.setFieldValue("image", slide.image)
        setselectedFiles([getImage(slide.image)])
        
        setIsEdit(true);

        toggle();
    };

    const onRemoveChange = (val) => {
        validation.setFieldValue("image", "")
    }
    const onFileChange = (val) => {
        validation.setFieldValue("image", val)
    }

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    //delete customer
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = users => {
        setSlide(users);
        setDeleteModal(true);
    };

    const handleDeleteUser = () => {
        if (slide && slide.id) {
            dispatch(onDeleteUser(slide.id));
        }
        onPaginationPageChange(1);
        setDeleteModal(false);
    };

    const handleUserClicks = () => {
        setSlideList("");
        setSlide({})
        setIsEdit(false);
        toggle();
    };

    const keyField = "id";

    const [croppedImageData, setCroppedImageData] = useState('');

    const handleImageUpload = (croppedImage) => {
        setCroppedImageData(croppedImage);
    };

    return (
        loading ? <Spinners setLoading={setLoading} />
            :
            <React.Fragment>

                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteUser}
                    onCloseClick={() => setDeleteModal(false)}
                />

                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs breadcrumbItem="Slide" />
                        <Row>
                            {
                                isLoading ? <Spinners setLoading={setLoading} />
                                    :
                                    <Col lg="12">
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    isPagination={true}
                                                    columns={columns}
                                                    data={slides}
                                                    isGlobalFilter={true}
                                                    isShowingPageLength={true}
                                                    isAddUserList={true}
                                                    buttonText={"Create Slide"}
                                                    iscustomPageSizeOptions={true}
                                                    handleUserClick={handleUserClicks}
                                                    customPageSize={10}
                                                    tableClass="table align-middle table-nowrap table-hover"
                                                    theadClass="table-light"
                                                    paginationDiv="col-sm-12 col-md-7"
                                                    pagination="pagination pagination-rounded justify-content-end mt-4"
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                            }

                            <Modal isOpen={modal} size="md" toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? "Edit Slide" : "Add Slide"}
                                </ModalHeader>
                                <ModalBody>
                                    <Form
                                        onSubmit={e => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row>
                                            <Col xs={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Title</Label>
                                                    <Input
                                                        name="title"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.title || ""}
                                                        invalid={
                                                            validation.touched.title &&
                                                                validation.errors.title
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.title &&
                                                        validation.errors.title ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.title}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label">Description</Label>
                                                    <Input
                                                        name="description"
                                                        type="textarea"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.description || ""}
                                                        invalid={
                                                            validation.touched.description &&
                                                                validation.errors.description
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.description &&
                                                        validation.errors.description ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.description}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                                
                                            </Col>
                                            <Col xs={{ size: 8, offset: 2 }} className="mt-3">
                                                <ImageBox onRemoveChange={onRemoveChange} error={validation.errors.image} onFileChange={onFileChange} Header={"UPLOAD CATEGORY IMAGE"} allowImages={1} setselectedFiles={setselectedFiles} selectedFiles={selectedFiles} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="text-end">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-success save-user"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </ModalBody>
                            </Modal>
                        </Row>
                    </Container>
                </div>
                <ToastContainer />
            </React.Fragment>
    );
};

export default withRouter(Slides);
