import React, { useEffect, useState, useRef, useMemo } from "react";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import ImageUploadWithCrop from "./ImageUploadWithCrop";

const ImageUpload = ({ captureImage, modal, onCancle, size }) => {
    const [croppedImageData, setCroppedImageData] = useState('');
    const handleImageUpload = (croppedImage) => {
        setCroppedImageData(croppedImage);
    };
    
    const Cancle = ()=>{
        onCancle()
    }
    const SendImage = ()=>{
        captureImage(croppedImageData)
        onCancle();
    }

    return (
        <Modal isOpen={modal} size="lg" toggle={Cancle}>
            <ModalHeader tag="h4" toggle={Cancle}>
                Add Image
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs={12}>
                        <div className="mb-3">
                            <Label htmlFor="formFile" className="form-label">Upload Image</Label>
                            <ImageUploadWithCrop size={size} onImageUpload={handleImageUpload} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-4 offset-4 d-flex justify-content-center">
                        <div className="text-end mx-4">
                            <button onClick={SendImage} className="btn btn-success save-user">OK</button>
                        </div>
                        <div className="text-end">
                            <button onClick={Cancle} className="btn btn-danger save-user">Cancle</button>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
}

export default ImageUpload;