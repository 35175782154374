export const GET_ORDERS = "GET_ORDERS"
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS"
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL"

export const GET_ORDER_UPDATE = "GET_ORDER_UPDATE"
export const GET_ORDER_UPDATE_SUCCESS = "GET_ORDER_UPDATE_SUCCESS"
export const GET_ORDER_UPDATE_FAIL = "GET_ORDER_UPDATE_FAIL"

export const GET_SETTLED_ORDER_UPDATE = "GET_SETTLED_ORDER_UPDATE"
export const GET_SETTLED_ORDER_UPDATE_SUCCESS = "GET_SETTLED_ORDER_UPDATE_SUCCESS"
export const GET_SETTLED_ORDER_UPDATE_FAIL = "GET_SETTLED_ORDER_UPDATE_FAIL"


export const GET_ORDER_ITEMS_UPDATE = "GET_ORDER_ITEMS_UPDATE"
export const GET_ORDER_UPDATE_ITEMS_SUCCESS = "GET_ORDER_UPDATE_ITEMS_SUCCESS"
export const GET_ORDER_UPDATE_ITEMS_FAIL = "GET_ORDER_UPDATE_ITEMS_FAIL"


export const GET_CANCELLED_ORDER_ITMES = "GET_CANCELLED_ORDER_ITMES"
export const GET_CANCELLED_ORDER_ITMES_SUCCESS = "GET_CANCELLED_ORDER_ITMES_SUCCESS"
export const GET_CANCELLED_ORDER_ITMES_FAIL = "GET_CANCELLED_ORDER_ITMES_FAIL"

