/* USERS */
export const GET_STORE_CATEGORYS = "GET_STORE_CATEGORYS"
export const GET_STORE_CATEGORYS_SUCCESS = "GET_STORE_CATEGORYS_SUCCESS"
export const GET_STORE_CATEGORYS_FAIL = "GET_STORE_CATEGORYS_FAIL"

/* CATEGORS PROFILE */
export const GET_STORE_CATEGORY = "GET_STORE_CATEGORY"
export const GET_STORE_CATEGORY_SUCCESS = "GET_STORE_CATEGORY_SUCCESS"
export const GET_STORE_CATEGORY_FAIL = "GET_STORE_CATEGORY_FAIL"

/**
 * add user
 */
export const NEW_STORE_CATEGORY = "NEW_STORE_CATEGORY"
export const NEW_STORE_CATEGORY_SUCCESS = "NEW_STORE_CATEGORY_SUCCESS"
export const NEW_STORE_CATEGORY_FAIL = "NEW_STORE_CATEGORY_FAIL"

/**
 * Edit user
 */
export const UPDATE_STORE_CATEGORY = "UPDATE_STORE_CATEGORY"
export const UPDATE_STORE_CATEGORY_SUCCESS = "UPDATE_STORE_CATEGORY_SUCCESS"
export const UPDATE_STORE_CATEGORY_FAIL = "UPDATE_STORE_CATEGORY_FAIL"

/**
 * Delete user
 */
export const DELETE_STORE_CATEGORY = "DELETE_STORE_CATEGORY"
export const DELETE_STORE_CATEGORY_SUCCESS = "DELETE_STORE_CATEGORY_SUCCESS"
export const DELETE_STORE_CATEGORY_FAIL = "DELETE_STORE_CATEGORY_FAIL"

export const GET_STORE_CATEGORYSCOMBO = "GET_STORE_CATEGORYSCOMBO"
export const GET_STORE_CATEGORYSCOMBO_FAIL = "GET_STORE_CATEGORYSCOMBO_FAIL"
export const GET_STORE_CATEGORYSCOMBO_SUCCESS = "GET_STORE_CATEGORYSCOMBO_SUCCESS"
