/* USERS */
export const GET_TAGS = "GET_TAGS"
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS"
export const GET_TAGS_FAIL = "GET_TAGS_FAIL"

/* CATEGORS PROFILE */
export const GET_TAG = "GET_TAG"
export const GET_TAG_SUCCESS = "GET_TAG_SUCCESS"
export const GET_TAG_FAIL = "GET_TAG_FAIL"

/**
 * add user
 */
export const ADD_NEW_TAG = "ADD_NEW_TAG"
export const ADD_TAG_SUCCESS = "ADD_TAG_SUCCESS"
export const ADD_TAG_FAIL = "ADD_TAG_FAIL"

/**
 * Edit user
 */
export const UPDATE_TAG = "UPDATE_TAG"
export const UPDATE_TAG_SUCCESS = "UPDATE_TAG_SUCCESS"
export const UPDATE_TAG_FAIL = "UPDATE_TAG_FAIL"

/**
 * Delete user
 */
export const DELETE_TAG = "DELETE_TAG"
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS"
export const DELETE_TAG_FAIL = "DELETE_TAG_FAIL"

export const GET_TAGSCOMBO = "GET_TAGSCOMBO"
export const GET_TAGSCOMBO_FAIL = "GET_TAGSCOMBO_FAIL"
export const GET_TAGSCOMBO_SUCCESS = "GET_TAGSCOMBO_SUCCESS"
