import axios from "axios";
import { del, get, post, put, postWithMulti, putWithMulti } from "./api_helper";
import * as url from "./url_helper";

export const getFoodItem = () => get(url.GET_FOOD_ITEM);
export const addFoodItem = food_item => postWithMulti(url.GET_FOOD_ITEM, food_item);
export const updateFoodItem = food_item => putWithMulti(url.GET_FOOD_ITEM + "/" + food_item.id, food_item.formData);
export const deleteFoodItem = food_item => del(url.GET_FOOD_ITEM + "/" + food_item);
export const getFoodItemCombo = food_item => get(url.GET_FOOD_ITEM + "/getComboValues");
export const updateStatusFoodItem = food_item => post(url.GET_FOOD_ITEM + "/updatestatus/" + food_item.id, food_item.formData);
export const fetchChartData = (data) => post(url.GET_ORDERS_ITEMS + "/getFooditemData", data);
