import { call, put, takeEvery } from "redux-saga/effects"
import { ADD_NEW_FOOD_ITEM, DELETE_FOOD_ITEM, GET_FOOD_ITEMS, UPDATE_FOOD_ITEM, GET_FOOD_ITEMSCOMBO, UPDATE_STATUS_FOOD_ITEM, GET_CHART_DATA } from "./actionTypes"
import {
  getFoodItemsSuccess,
  getFoodItemsFail,
  addFoodItemFail,
  addFoodItemSuccess,
  updateFoodItemSuccess,
  updateFoodItemFail,
  deleteFoodItemSuccess,
  deleteFoodItemFail,
  getFoodItemsComboSuccess,
  getFoodItemsComboFail,
  updateStatusFoodItemSuccess,
  updateStatusFoodItemFail,
  getChartDataSuccess,
  getChartDataFail
} from "./actions"
import { getFoodItem, addFoodItem, updateFoodItem, deleteFoodItem,getFoodItemCombo, updateStatusFoodItem, fetchChartData } from "../../helpers/food_item_helper"
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchFoodItem() {
  try {
    const response = yield call(getFoodItem)
    yield put(getFoodItemsSuccess(response.FoodItems))
  } catch (error) {
    yield put(getFoodItemsFail(error))
  }
}

function* fetchFoodItemsCombo() {
  try {
    const response = yield call(getFoodItemCombo)
    yield put(getFoodItemsComboSuccess(response.foodItems))
  } catch (error) {
    yield put(getFoodItemsComboFail(error))
  }
}

function* onUpdateFoodItem({ payload: FoodItem }) {
  try {
    const response = yield call(updateFoodItem, FoodItem)
    yield put(updateFoodItemSuccess(response.FoodItem))
    toast.success("Updated Successfully", { autoClose: 2000 });
  } catch (error) {
    console.log(error)
    yield put(updateFoodItemFail(error))
    toast.error("Updated Failed", { autoClose: 2000 });
  }
}

function* onDeleteFoodItem({ payload: FoodItem }) {
  try {
    const response = yield call(deleteFoodItem, FoodItem)
    yield put(deleteFoodItemSuccess(response.FoodItem.id))
    toast.success("Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteFoodItemFail(error))
    toast.error("Deleted Failed", { autoClose: 2000 });
  }
}

function* onAddFoodItem({ payload: FoodItem }) {
  try {
    const response = yield call(addFoodItem, FoodItem)
    yield put(addFoodItemSuccess(response.FoodItem))
    toast.success("Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addFoodItemFail(error))
    toast.error("Added Failed", { autoClose: 2000 });
  }
}

function* onUpdateStatusFoodItem({ payload: FoodItem }) {
  try {
    const response = yield call(updateStatusFoodItem, FoodItem)
    console.log(response)
    yield put(updateStatusFoodItemSuccess(response.FoodItem))
    toast.success("Updated Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(updateStatusFoodItemFail(error))
    toast.error("Updated Failed", { autoClose: 2000 });
  }
}

function* getChartData({ payload: FoodItem }) {
  try {
    const response = yield call(fetchChartData,FoodItem)
    yield put(getChartDataSuccess(response.foodItems))
  } catch (error) {
    yield put(getChartDataFail(error))
  }
}

function* FoodItemSaga() {
  yield takeEvery(GET_FOOD_ITEMS, fetchFoodItem)
  yield takeEvery(ADD_NEW_FOOD_ITEM, onAddFoodItem)
  yield takeEvery(UPDATE_FOOD_ITEM, onUpdateFoodItem)
  yield takeEvery(DELETE_FOOD_ITEM, onDeleteFoodItem)
  yield takeEvery(GET_FOOD_ITEMSCOMBO, fetchFoodItemsCombo),
  yield takeEvery(UPDATE_STATUS_FOOD_ITEM, onUpdateStatusFoodItem)
  yield takeEvery(GET_CHART_DATA, getChartData)
}

export default FoodItemSaga;
