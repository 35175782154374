import React, { useEffect, useState, useRef, useMemo } from "react";
import withRouter from "../../components/Common/withRouter";
import { Col, Row } from "reactstrap";
import ImageBox from "components/Common/ImageBox";
import {
    addNewHome as onAddNewHome,
} from "../../store/home/actions";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

const VideoImageUpload = ({home}) => {
    
    const [selectedFiles, setselectedFiles] = useState([])
    const dispatch = useDispatch();
    useEffect(() => {
        if(home)
            setselectedFiles((home?.videoImage !== undefined)?[home.videoImage]:[]);
    }, [home]);
    const onFilChange = (imageFile) =>{
        const formData = new FormData();
        formData.append('isEnable', true);
        if (imageFile) {
            formData.append('image', imageFile);
        }
        dispatch(onAddNewHome({data:formData, url:"/uploadVideoImage"}));
    }
    const onRemoveChange = () =>{
        const formData = new FormData();
        formData.append('isEnable', true);
        formData.append('image', '');
        dispatch(onAddNewHome({data:formData, url:"/uploadVideoImage"}));
    }
    return(
        <Row>
            <Col sm={12} className="mb-1">
                <h3 className="text-center">Upload Video Image</h3>
            </Col>
            <Col sm={12}>
                <ImageBox  size={16/9} onFileChange={onFilChange} onRemoveChange={onRemoveChange} selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} allowImages={1}/>
            </Col>
        </Row>
    )
}

export default withRouter(VideoImageUpload);