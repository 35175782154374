import React, { useEffect, useState, useRef, useMemo } from "react";
import Select from 'react-select';
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { FormFeedback } from "reactstrap";
import { getRoleCombo } from "store/role/actions";

const RoleSelect = ({ OnSelect, defaultValue, error, isSubmitted, isMulti = false, excludeCategory }) => {
    const dispatch = useDispatch();
    const [selectedRole, setSelectedRole] = useState(null);
    const [touchedRole, setTouchedRole] = useState(false);
    const [category, setCategory] = useState([]);

    const selectContactsState = (state) => state.roles;
    const ContactsProperties = createSelector(
        selectContactsState,
        (Role) => ({
            roles: Role.roleCombo,
            loading: Role.loading
        })
    );
    const { roles, loading } = useSelector(ContactsProperties);

    const handleSelect = (val) => {
        setSelectedRole(val)
        setTouchedRole(true);
        OnSelect(val)
    }

    useEffect(() => {
        if (roles && !roles.length) {
            dispatch(getRoleCombo());
        }
        setTouchedRole(false);
    }, [dispatch, roles]);

    useEffect(() => {
        if (!isMulti) {
            if (roles && roles.length != 0 && defaultValue) {
                const defaultRole = roles.find((option) => option.value === defaultValue);
                if (defaultRole) {
                    setSelectedRole(defaultRole);
                }

            }
            if (!defaultValue) {
                setSelectedRole(null);
            }
            setCategory(roles)
        } else {
            if (roles && roles.length && defaultValue.length != 0) {
                let category = roles.filter(category => defaultValue.some(value => value._id === category.value));

                setSelectedRole(category)
            }
            if (defaultValue.length == 0) {
                setSelectedRole([])
            }
        }

    }, [defaultValue, roles]);

    return (
        <React.Fragment>
            <Select
                classNamePrefix="select2-selection"
                placeholder="Choose..."
                title="Duration"
                options={roles}
                isMulti={isMulti}
                isSearchable={(isMulti) ? true : false}
                value={selectedRole}
                onChange={(val) => handleSelect(val)}
            />
            {(isSubmitted || touchedRole) && error ?
                <FormFeedback type="invalid" style={{ display: 'block' }}>
                    {error}
                </FormFeedback> : null
            }
        </React.Fragment>
    )
};

export default RoleSelect