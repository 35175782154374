import {
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAIL,
  ADD_NEW_SUBSCRIPTION,
  ADD_SUBSCRIPTION_SUCCESS,
  ADD_SUBSCRIPTION_FAIL,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAIL,
  DELETE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_FAIL,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_FAIL,
  GET_SUBSCRIPTIONCOMBO_SUCCESS,
  GET_SUBSCRIPTIONCOMBO_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  subscription: [],
  subscriptionCombo:[],
  error: {},
  loading: false
}

const subscriptions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTIONCOMBO_SUCCESS:
      return {
        ...state,
        subscriptionCombo: action.payload,
        loading: true
      }
      break;

    case GET_SUBSCRIPTIONCOMBO_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      break;
      case GET_SUBSCRIPTIONS:
      return {
        ...state,
        loading: true
      }
      break;
    case GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscription: action.payload,
        loading: false
      }
      break;
    case GET_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break;
      case ADD_NEW_SUBSCRIPTION:
      return {
        ...state,
        loading: true
      }
      break;
    case ADD_SUBSCRIPTION_SUCCESS:

      return {
        ...state,
        subscription: [...state.subscription, action.payload],
        loading: false
      }
      break;
    case ADD_SUBSCRIPTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break;
    case GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: action.payload,
      }
      break;
      case UPDATE_SUBSCRIPTION:
      return {
        ...state,
        loading: true
      }
      break;
    case UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: state.subscription.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
        loading: false
      }
      break;
    case UPDATE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break;
      case DELETE_SUBSCRIPTION:
      return {
        ...state,
        loading: true
      }
      break;
    case DELETE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: state.subscription.filter(
          user => user.id.toString() !== action.payload.toString()
        ),
        loading: false
      }
      break;
    case DELETE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break;
    case GET_SUBSCRIPTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      break;
    default:
      return state
  }
}

export default subscriptions
