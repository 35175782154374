import PropTypes from "prop-types";
import React from "react";
import {
  Container
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

const Appearance = props => {
  //meta title
  document.title = "Appearance";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Appearance")}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

Appearance.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Appearance);
