import { call, put, takeEvery } from "redux-saga/effects"
import { ADD_NEW_SUBSCRIPTION, DELETE_SUBSCRIPTION, GET_SUBSCRIPTIONS, UPDATE_SUBSCRIPTION, GET_SUBSCRIPTIONCOMBO  } from "./actionTypes"
import {
  getSubscriptionsSuccess,
  getSubscriptionsFail,
  addSubscriptionFail,
  addSubscriptionSuccess,
  updateSubscriptionSuccess,
  updateSubscriptionFail,
  deleteSubscriptionSuccess,
  deleteSubscriptionFail,
  getSubscriptionComboSuccess,
  getSubscriptionComboFail
} from "./actions"
import { getSubscription, addSubscription, updateSubscription, deleteSubscription, getSubscriptionCombo } from "../../helpers/subscription_helper"
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchSubscription() {
  try {
    const response = yield call(getSubscription)
    yield put(getSubscriptionsSuccess(response.subscriptions))
  } catch (error) {
    yield put(getSubscriptionsFail(error))
  }
}

function* fetchSubscriptionCombo() {
  try {
    const response = yield call(getSubscriptionCombo)
    yield put(getSubscriptionComboSuccess(response.subscriptions))
  } catch (error) {
    yield put(getSubscriptionComboFail(error))
  }
}

function* onUpdateSubscription({ payload: subscription }) {
  try {
    const response = yield call(updateSubscription, subscription)
    response.subscription.id = response.subscription._id;
    yield put(updateSubscriptionSuccess(response.subscription))
    toast.success("Updated Successfully", { autoClose: 2000 });
  } catch (error) {
    console.log(error)
    yield put(updateSubscriptionFail(error))
    toast.error("Updated Failed", { autoClose: 2000 });
  }
}

function* onDeleteSubscription({ payload: subscription }) {
  try {
    const response = yield call(deleteSubscription, subscription)
    
    yield put(deleteSubscriptionSuccess(response.subscription._id))
    toast.success("Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteSubscriptionFail(error))
    toast.error("Deleted Failed", { autoClose: 2000 });
  }
}

function* onAddSubscription({ payload: subscription }) {
  try {
    const response = yield call(addSubscription, subscription)
    response.subscription.id = response.subscription._id;
    yield put(addSubscriptionSuccess(response.subscription))
    toast.success("Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addSubscriptionFail(error))
    toast.error("Added Failed", { autoClose: 2000 });
  }
}

function* subscriptionSaga() {
  yield takeEvery(GET_SUBSCRIPTIONS, fetchSubscription)
  yield takeEvery(ADD_NEW_SUBSCRIPTION, onAddSubscription)
  yield takeEvery(UPDATE_SUBSCRIPTION, onUpdateSubscription)
  yield takeEvery(DELETE_SUBSCRIPTION, onDeleteSubscription)
  yield takeEvery(GET_SUBSCRIPTIONCOMBO, fetchSubscriptionCombo)
}

export default subscriptionSaga;
