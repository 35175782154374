import React, { useEffect, useState, useRef, useMemo } from "react";
import { getSubscriptionCombo } from "../../store/subscription/actions";
import Select from 'react-select';
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

const SubscriptionSelect = ({ OnSelect, defaultValue }) => {
    const dispatch = useDispatch();
    const [subscription, setTAGS] = useState({});
    const [selectedSubscription, setSelectedSubscription] = useState([]);

    const selectContactsState = (state) => state.subscriptions;
    const ContactsProperties = createSelector(
        selectContactsState,
        (Subscription) => ({
            subscriptions: Subscription.subscriptionCombo,
            loading: Subscription.loading
        })
    );
    const { subscriptions, loading } = useSelector(ContactsProperties);

    
    const handleSelect = (val) => {
        setSelectedSubscription(val)
        OnSelect(val)
    }

    useEffect(() => {
        if (subscriptions && !subscriptions.length) {
            dispatch(getSubscriptionCombo());
        }
    }, [dispatch, subscriptions]);

    useEffect(() => {
        if (subscriptions && subscriptions.length && defaultValue.length != 0) {
            let subscription = subscriptions.filter(subscription => defaultValue.some(value => value._id === subscription.value));
            setSelectedSubscription(subscription)
        }
    }, [subscriptions, defaultValue]);

    return (
        <Select
            classNamePrefix="select2-selection"
            placeholder="Choose..."
            title="Duration"
            options={subscriptions}
            value={selectedSubscription}
            onChange={handleSelect}
            isMulti
        />
    )
};

export default SubscriptionSelect