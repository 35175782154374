/* USERS */
export const GET_STORE_ITEMS = "GET_STORE_ITEMS"
export const GET_STORE_ITEMS_SUCCESS = "GET_STORE_ITEMS_SUCCESS"
export const GET_STORE_ITEMS_FAIL = "GET_STORE_ITEMS_FAIL"

/* CATEGORS PROFILE */
export const GET_STORE_ITEM = "GET_STORE_ITEM"
export const GET_STORE_ITEM_SUCCESS = "GET_STORE_ITEM_SUCCESS"
export const GET_STORE_ITEM_FAIL = "GET_STORE_ITEM_FAIL"

/**
 * add user
 */
export const ADD_NEW_STORE_ITEM = "ADD_NEW_STORE_ITEM"
export const ADD_STORE_ITEM_SUCCESS = "ADD_STORE_ITEM_SUCCESS"
export const ADD_STORE_ITEM_FAIL = "ADD_STORE_ITEM_FAIL"

/**
 * Edit user
 */
export const UPDATE_STORE_ITEM = "UPDATE_STORE_ITEM"
export const UPDATE_STORE_ITEM_SUCCESS = "UPDATE_STORE_ITEM_SUCCESS"
export const UPDATE_STORE_ITEM_FAIL = "UPDATE_STORE_ITEM_FAIL"

/**
 * Delete user
 */
export const DELETE_STORE_ITEM = "DELETE_STORE_ITEM"
export const DELETE_STORE_ITEM_SUCCESS = "DELETE_STORE_ITEM_SUCCESS"
export const DELETE_STORE_ITEM_FAIL = "DELETE_STORE_ITEM_FAIL"

export const GET_STORE_ITEMSCOMBO = "GET_STORE_ITEMSCOMBO"
export const GET_STORE_ITEMSCOMBO_FAIL = "GET_STORE_ITEMSCOMBO_FAIL"
export const GET_STORE_ITEMSCOMBO_SUCCESS = "GET_STORE_ITEMSCOMBO_SUCCESS"
