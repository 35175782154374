import axios from "axios";
import { del, get, post, put, postWithMulti, putWithMulti } from "./api_helper";
import * as url from "./url_helper";

export const getOrdersList = (filterData) => post(url.GET_ORDERS+"/getOrderByTag", filterData);
export const updateStatuOrders = order => post(url.GET_ORDERS+ "/settleOrder", order);
export const getsettleOrder = filterData => post(url.GET_ORDERS+ "/getsettleOrder", filterData);
export const updateOrderItems = order => post(url.GET_ORDERS+ "/updateorderitems", order);
export const getCancelledOrderItems = (filterData) => post(url.GET_ORDERS_ITEMS + "/getcancelledorderitems",filterData)
export const createCoupanCode = (data) => post(url.GET_COUPAN,data)
export const getCoupanCode = (id) => get(url.GET_COUPAN+"/"+id)
export const validateCoupanCode = (code,type) => post(url.GET_COUPAN+"/validate",{code:code, type:type})
