import {
  GET_STORYS,
  GET_STORYS_SUCCESS,
  GET_STORYS_FAIL,
  ADD_NEW_STORY,
  ADD_STORY_SUCCESS,
  ADD_STORY_FAIL,
  UPDATE_STORY,
  UPDATE_STORY_SUCCESS,
  UPDATE_STORY_FAIL,
  DELETE_STORY_SUCCESS,
  DELETE_STORY_FAIL,
  GET_STORY,
  GET_STORY_SUCCESS,
  GET_STORY_FAIL,
  DELETE_STORY_IMAGE,
  DELETE_STORY_IMAGE_SUCCESS,
  DELETE_STORY_IMAGE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  stories: [],
  story: {},
  error: {},
  loading: false
}

const storys = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STORYS:
      return {
        ...state,
        loading: true,
      }
      break;
    case GET_STORYS_SUCCESS:
      return {
        ...state,
        stories: action.payload,
        loading: false
      }
      break;
    case GET_STORYS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break;
    case ADD_NEW_STORY:
      return {
        ...state,
        loading: true,
      }
      break;
    case ADD_STORY_SUCCESS:
      return {
        ...state,
        story: action.payload,
        loading: false
      }
      break;
    case ADD_STORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break;
    case UPDATE_STORY:
      return {
        ...state,
        loading: true,
      }
      break;

    case UPDATE_STORY_SUCCESS:
      return {
        ...state,
        story: action.payload,
        loading: false
      }
      break;
    case UPDATE_STORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break;
    case DELETE_STORY_SUCCESS:
      return {
        ...state,
        stories: state.stories.filter(
          user => user._id.toString() !== action.payload.toString()
        ),
      }
      break;
    case DELETE_STORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      break;
    case GET_STORY:
      return {
        ...state,
        loading: true,
      }
      break;
    case GET_STORY_SUCCESS:
      return {
        ...state,
        story: action.payload,
        loading: false
      }
      break;
    case GET_STORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break;
    case DELETE_STORY_IMAGE:
      return {
        ...state,
        loading: true,
      }
      break;
    case DELETE_STORY_IMAGE_SUCCESS:
      return {
        ...state,
        story: action.payload,
        loading: false
      }
      break;
    case DELETE_STORY_IMAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break;
    default:
      return state
  }
}

export default storys
