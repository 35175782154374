import {
  GET_COUNTERS,
  GET_COUNTERS_SUCCESS,
  GET_COUNTERS_FAIL,
  ADD_NEW_COUNTER,
  ADD_COUNTER_SUCCESS,
  ADD_COUNTER_FAIL,
  UPDATE_COUNTER,
  UPDATE_COUNTER_SUCCESS,
  UPDATE_COUNTER_FAIL,
  DELETE_COUNTER,
  DELETE_COUNTER_SUCCESS,
  DELETE_COUNTER_FAIL,
  GET_COUNTER,
  GET_COUNTER_SUCCESS,
  GET_COUNTER_FAIL,
  GET_COUNTERSCOMBO_SUCCESS,
  GET_COUNTERSCOMBO_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  counter: [],
  counterCombo: [],
  error: {},
  loading: false
}

const counters = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COUNTERSCOMBO_SUCCESS:
      return {
        ...state,
        counterCombo: action.payload,
        loading: false
      }
      break
    case GET_COUNTERSCOMBO_FAIL:
      return {
        ...state,
        error: action.payload,
        
      }
      break
    case GET_COUNTERS:
      return {
        ...state,
        loading: true
      }
      break
    case GET_COUNTERS_SUCCESS:
      return {
        ...state,
        counter: action.payload,
        loading: false
      }
      break
    case GET_COUNTERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case ADD_NEW_COUNTER:
      return {
        ...state,
        loading: true
      }
      break
    case ADD_COUNTER_SUCCESS:

      return {
        ...state,
        counter: [...state.counter, action.payload],
        loading: false
      }
      break
    case ADD_COUNTER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case UPDATE_COUNTER:
      return {
        ...state,
        loading: true
      }
      break
    case GET_COUNTER_SUCCESS:
      return {
        ...state,
        counter: action.payload,
        loading: false
      }
      break
    case UPDATE_COUNTER_SUCCESS:
      return {
        ...state,
        counter: state.counter.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
        loading: false
      }
      break
    case UPDATE_COUNTER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case DELETE_COUNTER:
      return {
        ...state,
        loading: true
      }
      break
    case DELETE_COUNTER_SUCCESS:
      return {
        ...state,
        counter: state.counter.filter(
          user => user.id.toString() !== action.payload.toString()
        ),
        loading: false
      }
      break
    case DELETE_COUNTER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    case GET_COUNTER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      break
    default:
      return state
  }
}

export default counters
