/* USERS */
export const GET_SUBSCRIBER = "GET_SUBSCRIBER"
export const GET_SUBSCRIBER_SUCCESS = "GET_SUBSCRIBER_SUCCESS"
export const GET_SUBSCRIBER_FAIL = "GET_SUBSCRIBER_FAIL"

/**
 * add user
 */
export const ADD_NEW_SUBSCRIBER = "ADD_NEW_SUBSCRIBER"
export const ADD_SUBSCRIBER_SUCCESS = "ADD_SUBSCRIBER_SUCCESS"
export const ADD_SUBSCRIBER_FAIL = "ADD_SUBSCRIBER_FAIL"

/**
 * Edit user
 */
export const UPDATE_SUBSCRIBER = "UPDATE_SUBSCRIBER"
export const UPDATE_SUBSCRIBER_SUCCESS = "UPDATE_SUBSCRIBER_SUCCESS"
export const UPDATE_SUBSCRIBER_FAIL = "UPDATE_SUBSCRIBER_FAIL"

/**
 * Delete user
 */
export const DELETE_SUBSCRIBER = "DELETE_SUBSCRIBER"
export const DELETE_SUBSCRIBER_SUCCESS = "DELETE_SUBSCRIBER_SUCCESS"
export const DELETE_SUBSCRIBER_FAIL = "DELETE_SUBSCRIBER_FAIL"

export const GET_SUBSCRIBERCOMBO = "GET_SUBSCRIBERCOMBO"
export const GET_SUBSCRIBERCOMBO_FAIL = "GET_SUBSCRIBERCOMBO_FAIL"
export const GET_SUBSCRIBERCOMBO_SUCCESS = "GET_SUBSCRIBERCOMBO_SUCCESS"
