import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../../components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    UncontrolledTooltip,
    Input,
    Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import DeleteModal from "../../../components/Common/DeleteModal";


import {
    getChapterByStory as onGetChapters,
    addNewChapter as onAddNewChapter,
    updateChapter as onUpdateChapter,
    deleteChapter as onDeleteChapter,
} from "../../../store/chapter/actions";

import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { AlbumsBadge, AudioFile, TagsBadge } from "../../Subscription/SubscriptionCol";
import SubscriptionSelect from "components/Common/SubscriptionSelect";
import ImageBox from "components/Common/ImageBox";
import AudioUpload from "components/Common/AudioUpload";
import moment from 'moment';

const CreateChapters = ({ story }) => {
    const dispatch = useDispatch();
    const [chapter, setChapter] = useState();
    const [selectedSubscriptions, setSelectedSubscriptions] = useState([])
    const [selectedSubscriptionss, setSelectedSubscriptionss] = useState([])
    const [selectedFiles, setselectedFiles] = useState([])
    const [audioFile, setaudioFile] = useState(null)
    const [isSubmitted, setIsSubmitted] = useState(false);

    const selectContactsState = (state) => state.chapters;
    const ContactsProperties = createSelector(
        selectContactsState,
        (Chapter) => ({
            chapters: Chapter.chapter,
            loading: Chapter.loading
        })
    );

    const { chapters, loading } = useSelector(ContactsProperties);

    const [isLoading, setLoading] = useState(loading)

    useEffect(() => {
        if (chapters && story.id) {
            dispatch(onGetChapters(story.id));
            setIsEdit(false);
        }
    }, [dispatch, story.id]);


    const changeSubscription = (val) => {
        setSelectedSubscriptions(val)
    }

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            name: (chapter && chapter.name) || "",
            description: (chapter && chapter.description) || "",
            image: (chapter && chapter.image) || "",
            audioFile: (chapter && chapter.audioFile) || "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
            description: Yup.string(),
            image: Yup.string().required("Chapter Image required"),
            audioFile:Yup.string().required("Chapter Audio File required")
        }),
        onSubmit: values => {
            if (isEdit) {
                const formData = new FormData();
                formData.append('name', validation.values.name);
                formData.append('description', validation.values.description);
                formData.append('story', story.id);
                selectedSubscriptions.forEach((value, index) => {
                    formData.append(`subscriptions[${index}]`, value.value);
                });
                if (selectedFiles.length != 0) {
                    if (isBlob(selectedFiles[0]))
                        formData.append('image', selectedFiles[0], `image.png`);
                    else
                        formData.append('image', 'No Change');
                }
                else
                    formData.append('image', 'Remove');

                if (audioFile) {
                    if (isBlob(audioFile))
                        formData.append('audiofile', audioFile);
                    else
                        formData.append('audiofile', 'No Change');
                }
                else
                    formData.append('audiofile', 'Remove');

                let _chapter = {
                    formData: formData,
                    id: chapter.id
                }
                // update user
                dispatch(onUpdateChapter(_chapter));
                setIsEdit(false);
                setselectedFiles([])
                setaudioFile(null)
                setSelectedSubscriptions([])
                setIsSubmitted(false)
                validation.resetForm();
            } else {
                const formData = new FormData();
                formData.append('name', validation.values.name);
                formData.append('description', validation.values.description);
                formData.append('story', story.id);
                selectedSubscriptions.forEach((value, index) => {
                    formData.append(`subscriptions[${index}]`, value.value);
                });
                if (selectedFiles.length != 0)
                    formData.append('image', selectedFiles[0], `image.png`);
                if (audioFile)
                    formData.append('audiofile', audioFile);
                // save new user
                dispatch(onAddNewChapter(formData));
                setselectedFiles([])
                setaudioFile(null)
                setSelectedSubscriptions([])
                setIsSubmitted(false)
                validation.resetForm();
            }
            toggle();
        },
    });
    
    const [userList, setUserList] = useState([]);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);


    const columns = useMemo(
        () => [
            {
                Header: "#",
                accessor: "name",
                disableFilters: true,
                filterable: true,
                accessor: (cellProps) => (
                    <>
                        {!cellProps.img ? (
                            <div className="avatar-xs">
                                <span className="avatar-title rounded-circle">
                                    {cellProps.name.charAt(0)}
                                </span>
                            </div>
                        ) : (
                            <div>
                                <img
                                    className="rounded-circle avatar-xs"
                                    src={cellProps.img}
                                    alt=""
                                />
                            </div>
                        )}
                    </>
                ),
            },
            {
                Header: "Chapter Name",
                accessor: "name",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.name}</Link>
                            </h5>
                            <p className="text-muted mb-0">{cellProps.row.original.designation}</p>
                        </>
                    )
                },
            },
            // {
            //     Header: "Tags",
            //     accessor: "subscription",
            //     filterable: true,
            //     Cell: cellProps => {
            //         return <TagsBadge {...cellProps} />;
            //     },
            // },
            {
                Header: "Plans",
                accessor: "subscriptions",
                filterable: true,
                Cell: cellProps => {
                    return <AlbumsBadge {...cellProps} />;
                },

            },
            {
                Header: "File",
                accessor: "audioFile",
                filterable: true,
                Cell: cellProps => {
                    return <AudioFile {...cellProps} />;
                },

            },
            {
                Header: "Created By",
                accessor: "createdBy",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.createdBy?.name}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Created Date",
                accessor: "createdAt",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{moment(cellProps.row.original.createdAt).format('MMM D, YYYY')}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Action",
                Cell: cellProps => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-success"
                                onClick={() => {
                                    const userData = cellProps.row.original;
                                    handleUserClick(userData);
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const userData = cellProps.row.original;
                                    onClickDelete(userData);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                },
            },
        ],
        []
    );

    useEffect(() => {
        setChapter(chapters);
        setIsEdit(false);
    }, [chapters]);

    useEffect(() => {
        if (!isEmpty(chapters) && !!isEdit) {
            setChapter(chapters);
            setIsEdit(false);
        }
    }, [chapters]);

    const toggle = () => {
        setModal(!modal);
    };

    const handleUserClick = arg => {
        const chapter = arg;
        setChapter({
            id: chapter.id,
            name: chapter.name,
            description: chapter.description,
            image:chapter.image,
            audioFile:chapter.audioFile
        });
        setSelectedSubscriptionss(chapter.subscriptions)
        let subscriptions = chapter.subscriptions.map((x) => { return { label: x.name, value: x._id } })
        setSelectedSubscriptions(subscriptions)
        if(Object.keys(chapter.image).length != 0)
            setselectedFiles([chapter.image])
        setaudioFile(chapter.audioFile)
        setIsEdit(true);
        // validation.setFieldValue("image", chapter.image)
        // validation.setFieldValue("audioFile", chapter.audioFile)
        toggle();
    };

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    //delete customer
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = users => {
        setChapter(users);
        setDeleteModal(true);
    };

    const handleDeleteUser = () => {
        if (chapter && chapter.id) {
            dispatch(onDeleteChapter(chapter.id));
        }
        onPaginationPageChange(1);
        setDeleteModal(false);
    };

    const handleUserClicks = () => {
        setChapter({})
        setUserList("");
        setselectedFiles([])
        setaudioFile(null)
        setSelectedSubscriptionss([])
        setSelectedSubscriptions([])
        validation.resetForm();
        setIsEdit(false);
        toggle();
    };
    const isBlob = (obj) => {
        return obj instanceof Blob;
      }

      const onRemoveChange = (val) => {
        validation.setFieldValue("image", "")
    }
    const onFileChange = (val) => {
        validation.setFieldValue("image", val)
    }

    const onVideoRemoveChange = () => {
        validation.setFieldValue("audioFile", "")
    }
    const onVideoChange = (val) => {
        validation.setFieldValue("audioFile", val)
    }
    const keyField = "id";

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteUser}
                onCloseClick={() => setDeleteModal(false)}
            />

            <Row>
                {
                    loading ? <Spinners setLoading={setLoading} />
                        :
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <TableContainer
                                        isPagination={true}
                                        columns={columns}
                                        data={chapters}
                                        isGlobalFilter={true}
                                        isShowingPageLength={true}
                                        isChapterList={true}
                                        iscustomPageSizeOptions={true}
                                        handleUserClick={handleUserClicks}
                                        customPageSize={10}
                                        tableClass="table align-middle table-nowrap table-hover"
                                        theadClass="table-light"
                                        paginationDiv="col-sm-12 col-md-7"
                                        pagination="pagination pagination-rounded justify-content-end mt-4"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                }

                <Modal isOpen={modal} toggle={toggle} size="xl">
                    <ModalHeader toggle={toggle} tag="h4">
                        {!!isEdit ? "Edit Chapter" : "Add Chapter"}
                    </ModalHeader>
                    <ModalBody>
                        <Card>
                            <CardBody className="p-0">
                                <Form
                                    onSubmit={e => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        setIsSubmitted(true)
                                        return false;
                                    }}
                                >
                                    <Row>
                                        <Col xs={4}>
                                            <div className="mb-3">
                                                <Label className="form-label">Chapter Name</Label>
                                                <Input
                                                    name="name"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.name || ""}
                                                    invalid={
                                                        validation.touched.name &&
                                                            validation.errors.name
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.touched.name &&
                                                    validation.errors.name ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.name}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>




                                        </Col>
                                        <Col xs={4}>
                                            <div className="mb-3">
                                                <Label className="form-label">Description</Label>
                                                <Input
                                                    name="description"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.description || ""}
                                                    invalid={
                                                        validation.touched.description &&
                                                            validation.errors.description
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.touched.description &&
                                                    validation.errors.description ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.description}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col xs={4}>
                                            <div className="mb-3">
                                                <Label>Select Plans</Label>
                                                <SubscriptionSelect defaultValue={selectedSubscriptionss} OnSelect={changeSubscription} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6}>
                                            <AudioUpload isSubmitted={isSubmitted} onFileChange={onVideoChange} onRemoveChange={onVideoRemoveChange} error={validation.errors.audioFile} selectedFile={audioFile} setselectedFile={setaudioFile} />
                                        </Col>
                                        <Col xs={4}>
                                            <ImageBox isSubmitted={isSubmitted} onFileChange={onFileChange} onRemoveChange={onRemoveChange} error={validation.errors.image} Header={"Upload Image"} selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} allowImages={1} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="text-end">
                                                <button
                                                    type="submit"
                                                    className="btn btn-success save-user"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </ModalBody>
                </Modal>
            </Row>
            <ToastContainer />
        </React.Fragment>
    );
}

export default CreateChapters;