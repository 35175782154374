import {
  GET_HOME,
  GET_HOME_FAIL,
  GET_HOME_SUCCESS,
  ADD_NEW_HOME,
  ADD_HOME_SUCCESS,
  ADD_HOME_FAIL,
  UPDATE_HOME,
  UPDATE_HOME_SUCCESS,
  UPDATE_HOME_FAIL,
  DELETE_HOME,
  DELETE_HOME_SUCCESS,
  DELETE_HOME_FAIL,
} from "./actionTypes"

export const getHome = () => ({
  type: GET_HOME,
})

export const getHomeSuccess = home => ({
  type: GET_HOME_SUCCESS,
  payload: home,
})
export const getHomeFail = error => ({
  type: GET_HOME_FAIL,
  payload: error,
})

export const addNewHome = home => ({
  type: ADD_NEW_HOME,
  payload: home,
})

export const addHomeSuccess = home => ({
  type: ADD_HOME_SUCCESS,
  payload: home,
})

export const addHomeFail = error => ({
  type: ADD_HOME_FAIL,
  payload: error,
})

export const updateHome = home => ({
  type: UPDATE_HOME,
  payload: home,
})

export const updateHomeSuccess = home => ({
  type: UPDATE_HOME_SUCCESS,
  payload: home,
})

export const updateHomeFail = error => ({
  type: UPDATE_HOME_FAIL,
  payload: error,
})

export const deleteHome = home => ({
  type: DELETE_HOME,
  payload: home,
})

export const deleteHomeSuccess = home => ({
  type: DELETE_HOME_SUCCESS,
  payload: home,
})

export const deleteHomeFail = error => ({
  type: DELETE_HOME_FAIL,
  payload: error,
})
