import {
  GET_STORE_ITEMS,
  GET_STORE_ITEMS_FAIL,
  GET_STORE_ITEMS_SUCCESS,
  GET_STORE_ITEMSCOMBO,
  GET_STORE_ITEMSCOMBO_FAIL,
  GET_STORE_ITEMSCOMBO_SUCCESS,
  ADD_NEW_STORE_ITEM,
  ADD_STORE_ITEM_SUCCESS,
  ADD_STORE_ITEM_FAIL,
  UPDATE_STORE_ITEM,
  UPDATE_STORE_ITEM_SUCCESS,
  UPDATE_STORE_ITEM_FAIL,
  DELETE_STORE_ITEM,
  DELETE_STORE_ITEM_SUCCESS,
  DELETE_STORE_ITEM_FAIL,
} from "./actionTypes"

export const getStoreItems = () => ({
  type: GET_STORE_ITEMS,
})

export const getStoreItemsSuccess = storeitem => ({
  type: GET_STORE_ITEMS_SUCCESS,
  payload: storeitem,
})

export const addNewStoreItem = storeitem => ({
  type: ADD_NEW_STORE_ITEM,
  payload: storeitem,
})

export const addStoreItemSuccess = storeitem => ({
  type: ADD_STORE_ITEM_SUCCESS,
  payload: storeitem,
})

export const addStoreItemFail = error => ({
  type: ADD_STORE_ITEM_FAIL,
  payload: error,
})

export const getStoreItemsFail = error => ({
  type: GET_STORE_ITEMS_FAIL,
  payload: error,
})

export const updateStoreItem = storeitem => ({
  type: UPDATE_STORE_ITEM,
  payload: storeitem,
})

export const updateStoreItemSuccess = storeitem => ({
  type: UPDATE_STORE_ITEM_SUCCESS,
  payload: storeitem,
})

export const updateStoreItemFail = error => ({
  type: UPDATE_STORE_ITEM_FAIL,
  payload: error,
})

export const deleteStoreItem = storeitem => ({
  type: DELETE_STORE_ITEM,
  payload: storeitem,
})

export const deleteStoreItemSuccess = storeitem => ({
  type: DELETE_STORE_ITEM_SUCCESS,
  payload: storeitem,
})

export const deleteStoreItemFail = error => ({
  type: DELETE_STORE_ITEM_FAIL,
  payload: error,
})
export const getStoreItemCombo = () => ({
  type: GET_STORE_ITEMSCOMBO,
})

export const getStoreItemsComboSuccess = storeitem => ({
  type: GET_STORE_ITEMSCOMBO_SUCCESS,
  payload: storeitem,
})

export const getStoreItemsComboFail = error => ({
  type: GET_STORE_ITEMSCOMBO_FAIL,
  payload: error,
})