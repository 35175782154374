/* USERS */
export const GET_STORYS = "GET_STORYS"
export const GET_STORYS_SUCCESS = "GET_STORYS_SUCCESS"
export const GET_STORYS_FAIL = "GET_STORYS_FAIL"

/* CATEGORS PROFILE */
export const GET_STORY = "GET_STORY"
export const GET_STORY_SUCCESS = "GET_STORY_SUCCESS"
export const GET_STORY_FAIL = "GET_STORY_FAIL"

/**
 * add user
 */
export const ADD_NEW_STORY = "ADD_NEW_STORY"
export const ADD_STORY_SUCCESS = "ADD_STORY_SUCCESS"
export const ADD_STORY_FAIL = "ADD_STORY_FAIL"

/**
 * Edit user
 */
export const UPDATE_STORY = "UPDATE_STORY"
export const UPDATE_STORY_SUCCESS = "UPDATE_STORY_SUCCESS"
export const UPDATE_STORY_FAIL = "UPDATE_STORY_FAIL"

/**
 * Delete user
 */
export const DELETE_STORY = "DELETE_STORY"
export const DELETE_STORY_SUCCESS = "DELETE_STORY_SUCCESS"
export const DELETE_STORY_FAIL = "DELETE_STORY_FAIL"

export const DELETE_STORY_IMAGE = "DELETE_STORY_IMAGE"
export const DELETE_STORY_IMAGE_SUCCESS = "DELETE_STORY_IMAGE_SUCCESS"
export const DELETE_STORY_IMAGE_FAIL = "DELETE_STORY_IMAGE_FAIL"


