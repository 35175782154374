//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/admin/login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

//CATEGORY
export const GET_CATEGORY = "/category";
export const GET_TAG = "/tag";
export const GET_COUPAN = "/coupan";
export const GET_SUBSCRIPTION = "/subscription";
export const GET_STORY = "/story";
export const GET_CHAPTER = "/chapter";
export const GET_SLIDE = "/slides";
export const GET_HOME = "/home";
export const GET_NOTIFICATION = "/notification";
export const GET_SUBSCRIBER = "/subscriber"
export const GET_USER = "/admin"
export const GET_USER_DETAILS = "/admin/user-details"
export const GET_DASHBOARD = "/admin/dashboard"
export const GET_COUNTER = "/counter"
export const GET_STORE_METRIC = "/storemetric"
export const GET_STORE_CATEGORY = "/storecategory"
export const GET_STORE_ITEM = "/storeitem"
export const GET_FOOD_ITEM = "/fooditem"
export const GET_ROLE = "/role"
export const GET_ORDERS = "/order"
export const GET_ORDERS_ITEMS = "/orderitem"