import React, { useEffect, useState, useRef, useMemo } from "react";
import { getCategoryCombo } from "../../store/category/actions";
import Select from 'react-select';
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { FormFeedback } from "reactstrap";

const PagesSelect = ({ OnSelect, defaultValue, error, isSubmitted, isMulti = false, excludeCategory  }) => {
    const dispatch = useDispatch();
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [touchedCategory, setTouchedCategory] = useState(false);
    const [category, setCategory] = useState([]);
    const [categories, setCategories] = useState([{label:'Pos', value : 'Pos'},{label:'Orders', value : 'Orders'},{label:'Settlements', value : 'Settlements'},{label:'Kitchen', value : 'Kitchen'},{label:'Stock', value : 'Stock'}]);

    const handleSelect = (val) => {
        setSelectedCategory(val)
        setTouchedCategory(true);
        OnSelect(val)
    }

    useEffect(() => {
        if(!isMulti){
            if (categories && categories.length != 0 && defaultValue) {
                const defaultCategory = categories.find((option) => option.value === defaultValue);
    
                if (defaultCategory) {
                    setSelectedCategory(defaultCategory);
                }
                
            }
            if (!defaultValue) {
                setSelectedCategory(null);
            }
            setCategory(categories)
        }else{
            if (categories && categories.length && defaultValue.length != 0) {
                let category = categories.filter(category => defaultValue.some(value => value === category.value));
                
                setSelectedCategory(category)
            }
            if (defaultValue.length == 0) {
                
                
                setSelectedCategory([])
            }
        }
        
    }, [defaultValue, categories]);

    return (
        <React.Fragment>
            <Select
                classNamePrefix="select2-selection"
                placeholder="Choose..."
                title="Duration"
                options={categories}
                isMulti={isMulti}
                isSearchable={(isMulti)?true:false}
                value={selectedCategory}
                onChange={(val) => handleSelect(val)}
            />
            {(isSubmitted || touchedCategory) && error ?
                <FormFeedback type="invalid" style={{ display: 'block' }}>
                    {error}
                </FormFeedback> : null
            }
        </React.Fragment>
    )
};

export default PagesSelect