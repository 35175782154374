import { call, put, takeEvery } from "redux-saga/effects"
import { NEW_STORE_CATEGORY, DELETE_STORE_CATEGORY, GET_STORE_CATEGORYS, UPDATE_STORE_CATEGORY, GET_STORE_CATEGORYSCOMBO } from "./actionTypes"
import {
  getStoreCategorySuccess,
  getStoreCategoryFail,
  addStoreCategoryFail,
  addStoreCategorySuccess,
  updateStoreCategorySuccess,
  updateStoreCategoryFail,
  deleteStoreCategorySuccess,
  deleteStoreCategoryFail,
  getStoreCategoryComboSuccess,
  getStoreCategoryComboFail
} from "./actions"
import { getStoreCategory, addStoreCategory, updateStoreCategory, deleteStoreCategory,getStoreCategoryCombo } from "../../helpers/store_category_helper"
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchStoreCategory() {
  try {
    const response = yield call(getStoreCategory)
    yield put(getStoreCategorySuccess(response.StoreCategorys))
  } catch (error) {
    yield put(getStoreCategoryFail(error))
  }
}

function* fetchStoreCategoryCombo() {
  try {
    const response = yield call(getStoreCategoryCombo)
    yield put(getStoreCategoryComboSuccess(response.storeCategories))
  } catch (error) {
    yield put(getStoreCategoryComboFail(error))
  }
}

function* onUpdateStoreCategory({ payload: store_category }) {
  try {
    const response = yield call(updateStoreCategory, store_category)
    yield put(updateStoreCategorySuccess(response.StoreCategory))
    toast.success("Updated Successfully", { autoClose: 2000 });
  } catch (error) {
    console.log(error)
    yield put(updateStoreCategoryFail(error))
    toast.error("Updated Failed", { autoClose: 2000 });
  }
}

function* onDeleteStoreCategory({ payload: store_category }) {
  try {
    const response = yield call(deleteStoreCategory, store_category)
    yield put(deleteStoreCategorySuccess(response.StoreCategory.id))
    toast.success("Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteStoreCategoryFail(error))
    toast.error("Deleted Failed", { autoClose: 2000 });
  }
}

function* onAddStoreCategory({ payload: store_category }) {
  try {
    const response = yield call(addStoreCategory, store_category)
    yield put(addStoreCategorySuccess(response.StoreCategory))
    toast.success("Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addStoreCategoryFail(error))
    toast.error("Added Failed", { autoClose: 2000 });
  }
}

function* store_categorySaga() {
  yield takeEvery(GET_STORE_CATEGORYS, fetchStoreCategory)
  yield takeEvery(NEW_STORE_CATEGORY, onAddStoreCategory)
  yield takeEvery(UPDATE_STORE_CATEGORY, onUpdateStoreCategory)
  yield takeEvery(DELETE_STORE_CATEGORY, onDeleteStoreCategory)
  yield takeEvery(GET_STORE_CATEGORYSCOMBO, fetchStoreCategoryCombo)
}

export default store_categorySaga;
