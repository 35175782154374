import React, { useState, useEffect } from "react";
import ImageUpload from "components/Common/ImageUpload";
import { Card, CardBody, CardTitle, Col, FormFeedback, Row } from "reactstrap";

const ImageBox = ({ allowImages, selectedFiles, setselectedFiles, Header, SubHeader, onFileChange, onRemoveChange, size, error, isSubmitted }) => {

    const [modal, setModal] = useState(false)
    const [touchedCategory, setTouchedCategory] = useState(false);
    
    const uploadImage = () => {
        setModal(true)
    }

    const onCancle = () => {
        setModal(false)
    }
    const captureImage = (val) => {
        setselectedFiles([...selectedFiles, val])
        setTouchedCategory(true);
        if (onFileChange)
            onFileChange(val);

    }


    const DeleteImage = (index) => {
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        setselectedFiles(updatedFiles);
        if (onRemoveChange)
            onRemoveChange();
    }
    const isBlob = (obj) => {
        return obj instanceof Blob;
    }
    return (
        <React.Fragment>
            <ImageUpload onCancle={onCancle} captureImage={captureImage} size={size} modal={modal} />
            <Card>
                <CardBody className="pt-0 text-center">
                    {Header && <CardTitle>{Header}</CardTitle>}
                    {SubHeader && <p className="card-title-desc mb-4">
                        {SubHeader}
                    </p>}


                    <Row>

                        {
                            selectedFiles.map((x, i) => {
                                return (
                                    <Col key={i} className="d-flex justify-content-center align-items-center flex-column">
                                        {isBlob(x) ?
                                            <img className="img-thumbnail" src={URL.createObjectURL(x)} data-holder-rendered="true" loading="lazy" /> :
                                            <img className="img-thumbnail" src={x} data-holder-rendered="true" loading="lazy" />
                                        }
                                        <button type="button" onClick={x => DeleteImage(i)} className="btn btn-sm btn-outline-danger mt-2">Remove</button>
                                    </Col>
                                )
                            })
                        }
                        {selectedFiles.length < allowImages &&
                            <Col className="text-center d-flex justify-content-center" >
                                <button type="button" onClick={uploadImage} className="btn btn-sm btn-outline-primary mt-2">Add Image</button>
                            </Col>
                        }
                    </Row>
                    
                    {(isSubmitted || touchedCategory) && error ?
                        <FormFeedback type="invalid" style={{ display: 'block' }}>
                            {error}
                        </FormFeedback> : null
                    }
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default ImageBox;