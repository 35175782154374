import {
  GET_CHAPTERS,
  GET_CHAPTERS_FAIL,
  GET_CHAPTERS_SUCCESS,
  ADD_NEW_CHAPTER,
  ADD_CHAPTER_SUCCESS,
  ADD_CHAPTER_FAIL,
  UPDATE_CHAPTER,
  UPDATE_CHAPTER_SUCCESS,
  UPDATE_CHAPTER_FAIL,
  DELETE_CHAPTER,
  DELETE_CHAPTER_SUCCESS,
  DELETE_CHAPTER_FAIL,
  GET_CHAPTER_STORY,
  GET_CHAPTER_STORY_FAIL,
  GET_CHAPTER_STORY_SUCCESS,
} from "./actionTypes"

export const getChapters = () => ({
  type: GET_CHAPTERS,
})

export const getChaptersSuccess = chapter => ({
  type: GET_CHAPTERS_SUCCESS,
  payload: chapter,
})

export const addNewChapter = chapter => ({
  type: ADD_NEW_CHAPTER,
  payload: chapter,
})

export const addChapterSuccess = chapter => ({
  type: ADD_CHAPTER_SUCCESS,
  payload: chapter,
})

export const addChapterFail = error => ({
  type: ADD_CHAPTER_FAIL,
  payload: error,
})

export const getChaptersFail = error => ({
  type: GET_CHAPTERS_FAIL,
  payload: error,
})

export const updateChapter = chapter => ({
  type: UPDATE_CHAPTER,
  payload: chapter,
})

export const updateChapterSuccess = chapter => ({
  type: UPDATE_CHAPTER_SUCCESS,
  payload: chapter,
})

export const updateChapterFail = error => ({
  type: UPDATE_CHAPTER_FAIL,
  payload: error,
})

export const deleteChapter = chapter => ({
  type: DELETE_CHAPTER,
  payload: chapter,
})

export const deleteChapterSuccess = chapter => ({
  type: DELETE_CHAPTER_SUCCESS,
  payload: chapter,
})

export const deleteChapterFail = error => ({
  type: DELETE_CHAPTER_FAIL,
  payload: error,
})


export const getChapterByStory = story => ({
  type: GET_CHAPTER_STORY,
  payload: story,
})

export const getChapterByStorySuccess = chapter => ({
  type: GET_CHAPTER_STORY_SUCCESS,
  payload: chapter,
})

export const getChapterByStoryFail = error => ({
  type: GET_CHAPTER_STORY_FAIL,
  payload: error,
})