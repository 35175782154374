import { call, put, takeEvery } from "redux-saga/effects"
import { ADD_NEW_COUNTER, DELETE_COUNTER, GET_COUNTERS, UPDATE_COUNTER, GET_COUNTERSCOMBO } from "./actionTypes"
import {
  getCountersSuccess,
  getCountersFail,
  addCounterFail,
  addCounterSuccess,
  updateCounterSuccess,
  updateCounterFail,
  deleteCounterSuccess,
  deleteCounterFail,
  getCountersComboSuccess,
  getCountersComboFail
} from "./actions"
import { getCounter, addCounter, updateCounter, deleteCounter,getCounterCombo } from "../../helpers/counter_helper"
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchCounter() {
  try {
    const response = yield call(getCounter)
    yield put(getCountersSuccess(response.counters))
  } catch (error) {
    yield put(getCountersFail(error))
  }
}

function* fetchCountersCombo() {
  try {
    const response = yield call(getCounterCombo)
    yield put(getCountersComboSuccess(response.counters))
  } catch (error) {
    yield put(getCountersComboFail(error))
  }
}

function* onUpdateCounter({ payload: counter }) {
  try {
    const response = yield call(updateCounter, counter)
    response.counter.id = response.counter._id;
    yield put(updateCounterSuccess(response.counter))
    toast.success("Updated Successfully", { autoClose: 2000 });
  } catch (error) {
    console.log(error)
    yield put(updateCounterFail(error))
    toast.error("Updated Failed", { autoClose: 2000 });
  }
}

function* onDeleteCounter({ payload: counter }) {
  try {
    const response = yield call(deleteCounter, counter)
    
    yield put(deleteCounterSuccess(response.counter.id))
    toast.success("Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteCounterFail(error))
    toast.error("Deleted Failed", { autoClose: 2000 });
  }
}

function* onAddCounter({ payload: counter }) {
  try {
    const response = yield call(addCounter, counter)
    response.counter.id = response.counter._id;
    yield put(addCounterSuccess(response.counter))
    toast.success("Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addCounterFail(error))
    toast.error("Added Failed", { autoClose: 2000 });
  }
}

function* counterSaga() {
  yield takeEvery(GET_COUNTERS, fetchCounter)
  yield takeEvery(ADD_NEW_COUNTER, onAddCounter)
  yield takeEvery(UPDATE_COUNTER, onUpdateCounter)
  yield takeEvery(DELETE_COUNTER, onDeleteCounter)
  yield takeEvery(GET_COUNTERSCOMBO, fetchCountersCombo)
}

export default counterSaga;
