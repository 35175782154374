import { call, put, takeEvery } from "redux-saga/effects"
import { ADD_NEW_CHAPTER, DELETE_CHAPTER, GET_CHAPTERS, GET_CHAPTER_STORY, UPDATE_CHAPTER,  } from "./actionTypes"
import {
  getChaptersSuccess,
  getChaptersFail,
  addChapterFail,
  addChapterSuccess,
  updateChapterSuccess,
  updateChapterFail,
  deleteChapterSuccess,
  deleteChapterFail,
  getChapterByStorySuccess,
  getChapterByStoryFail,
  deleteChapter as DeleteChapter
} from "./actions"
import { getChapter, addChapter, updateChapter, deleteChapter, getChapterByStory } from "../../helpers/chapter_helper"
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchChapter() {
  try {
    const response = yield call(getChapter)
    yield put(getChaptersSuccess(response.chapter))
  } catch (error) {
    yield put(getChaptersFail(error))
  }
}

function* fetchChapterByStory({ payload: story }) {
  try {
    const response = yield call(getChapterByStory, story)
    yield put(getChapterByStorySuccess(response.chapters))
  } catch (error) {
    yield put(getChapterByStoryFail(error))
  }
}

function* onUpdateChapter({ payload: chapter }) {
  try {
    
    const response = yield call(updateChapter, chapter)
    
    yield put(updateChapterSuccess(response.chapters))
    toast.success("Updated Successfully", { autoClose: 2000 });
  } catch (error) {
    console.log(error)
    yield put(updateChapterFail(error))
    toast.error("Updated Failed", { autoClose: 2000 });
  }
}

function* onDeleteChapter({ payload: chapter }) {
  try {
    // yield put(DeleteChapter)
    const response = yield call(deleteChapter, chapter)
    yield put(deleteChapterSuccess(response.chapters._id))
    toast.success("Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteChapterFail(error))
    toast.error("Deleted Failed", { autoClose: 2000 });
  }
}

function* onAddChapter({ payload: chapter }) {
  try {
    const response = yield call(addChapter, chapter)
    response.chapters.id = response.chapters.id;
    yield put(addChapterSuccess(response.chapters))
    toast.success("Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addChapterFail(error))
    toast.error("Added Failed", { autoClose: 2000 });
  }
}

function* chapterSaga() {
  yield takeEvery(GET_CHAPTERS, fetchChapter)
  yield takeEvery(ADD_NEW_CHAPTER, onAddChapter)
  yield takeEvery(UPDATE_CHAPTER, onUpdateChapter)
  yield takeEvery(DELETE_CHAPTER, onDeleteChapter)
  yield takeEvery(GET_CHAPTER_STORY, fetchChapterByStory)
}

export default chapterSaga;
